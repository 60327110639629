<div class="wrapper-modal">
  <form [formGroup]="createOrEditPriceListCategoryForm" (ngSubmit)="submitCreateOrEditPriceListCategoryForm()" class="wrapper-content-modal">
    <div class="header-modal">
      <span class="title-modal">{{priceListCategoryForEdit ? 'Izmeni kategoriju cenovnika' : 'Kreiraj novu kategoriju cenovnika'}}</span>
    </div>
    <div class="xs-mb-20">
      <div class="label-banner">
        <span>Ime kategorije</span>
      </div>
      <div class="wrapper-input">
        <div class="zc-input">
          <input formControlName="name" type="text" placeholder="Ime kategorije">
        </div>
        <span class="error-message">{{createOrEditPriceListCategoryForm.controls.name | formControlErrorMessages: null : formTriedToSubmit}}</span>
      </div>
    </div>
    <div class="footer-modal">
      <button type="button" (click)="closeModal.emit()" class="zc-btn zc-btn--gray"><span>Izađi</span></button>
      <button type="submit" class="zc-btn zc-btn--primary"><span>{{priceListCategoryForEdit ? 'Izmeni' : 'Dodaj'}}</span></button>
    </div>
  </form>
</div>
