import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BannersHttpService} from '../../../../services/http-services/banners/banners-http.service';
import {GrBanner} from '../../../../interfaces/banners-interfaces';
import {ToastrService} from 'ngx-toastr';
import {UrlRegex} from '../../../../constants/general-variables';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {InputDebounceTime} from '../../../../enums/general-enums';
import {Subject} from 'rxjs';
import {BannerLinkTypeEnums} from '../../../../enums/banner-enums';

@Component({
  selector: 'gr-create-or-edit-banner-modal',
  templateUrl: './create-or-edit-banner-modal.component.html',
})
export class CreateOrEditBannerModalComponent implements OnInit {
  createOrEditBannerForm!: FormGroup;
  formTriedToSubmit = false;
  imageUrl = '';
  searchTerm = '';
  autocompleteSubject = new Subject<string>();
  autocompleteLinkSuggestions: any[] = [];
  isStopAutosuggestionActive = false;
  @Input() bannerForEdit: GrBanner | null = null;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmModal: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  constructor(private formBuilder: FormBuilder,
              private toastrService: ToastrService,
              private bannersHttpService: BannersHttpService) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.createOrEditBannerForm.controls.link_type.valueChanges.subscribe(value => {
      this.createOrEditBannerForm.controls.link.patchValue('');
      this.searchTerm = '';
      this.autocompleteLinkSuggestions = [];
      if (this.createOrEditBannerForm.controls.link_type.value === BannerLinkTypeEnums.company || this.createOrEditBannerForm.controls.link_type.value === BannerLinkTypeEnums.category) {
        this.getBannerLinks();
        this.createOrEditBannerForm.controls.link.setValidators([Validators.required]);
      } else {
        this.createOrEditBannerForm.controls.link.setValidators([Validators.required, Validators.pattern(UrlRegex)]);
      }
      this.createOrEditBannerForm.controls.link.updateValueAndValidity();
    });
    this.debounceLinkInput();
  }

  buildForm(): void {
    this.createOrEditBannerForm = this.formBuilder.group({
      active: [true],
      image: [null, Validators.required],
      link_type: ['company'],
      link: ['', Validators.required],
      position: [1]
    });
    if (this.bannerForEdit) {
      this.createOrEditBannerForm.patchValue(this.bannerForEdit);
      this.createOrEditBannerForm.controls.active.patchValue(!!this.bannerForEdit.active);
      this.createOrEditBannerForm.controls.image.patchValue('not changed');
      this.searchTerm = this.bannerForEdit.link;
      this.imageUrl = this.bannerForEdit?.image.full_path;
    }
  }

  getBannerLinks(): void {
    this.bannersHttpService.getAutocompleteLink({linkType: this.createOrEditBannerForm.controls.link_type.value, searchTerm: this.searchTerm}).then(res => {
      this.autocompleteLinkSuggestions = res.data;
    });
  }

  debounceLinkInput(): void {
    this.autocompleteSubject.pipe(
      debounceTime(InputDebounceTime.time),
      distinctUntilChanged())
      .subscribe(value => {
        this.searchTerm = value;
        (!this.isStopAutosuggestionActive && (this.createOrEditBannerForm.controls.link_type.value === BannerLinkTypeEnums.company || this.createOrEditBannerForm.controls.link_type.value === BannerLinkTypeEnums.category))
          ? this.getBannerLinks()
          : this.createOrEditBannerForm.controls.link.patchValue(this.searchTerm);
      });
  }

  onSelectSuggestionLink(suggestion: any): void {
    (this.createOrEditBannerForm.controls.link_type.value === BannerLinkTypeEnums.company)
      ? this.createOrEditBannerForm.controls.link.patchValue(`category/${suggestion.business_category_id}/item/${suggestion.id}`)
      : this.createOrEditBannerForm.controls.link.patchValue(`category/${suggestion.id}`);
    this.isStopAutosuggestionActive = true;
    setTimeout(() => {
      this.isStopAutosuggestionActive = false;
    }, 1000);
    this.searchTerm = this.createOrEditBannerForm.controls.link.value;
    this.autocompleteLinkSuggestions = [];
  }

  onFileChange(event: any): void {
    const file = event.target.files[0];
    if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/svg') {
      this.toastrService.warning('Pogrešan tip dokumenta. Samo JPG, JPEG, SVG i PNG formati su dozvoljeni.');
      return;
    } else if (file?.size > 2097152) {
      this.toastrService.warning(`Slika ${file.name} je veća od 2MB.`);
      return;
    } else {
      this.createOrEditBannerForm.controls.image.patchValue(event.target.files[0]);
      this.imageUrl = URL.createObjectURL(file);
      this.fileInput.nativeElement.value = '';
    }
  }

  removeFile(): void {
    this.createOrEditBannerForm.controls.image.patchValue(null);
    this.imageUrl = '';
  }


  submitCreateOrEditBannerForm(): void {
    this.formTriedToSubmit = true;
    if (this.createOrEditBannerForm.valid) {
      const formData = new FormData();
      for (const key in this.createOrEditBannerForm.value) {
        if (this.createOrEditBannerForm.value[key] !== 'not changed') {
          formData.append(key, this.createOrEditBannerForm.value[key]);
        }
      }
      this.bannerForEdit
        ? this.bannersHttpService.putBanner({bannerId: this.bannerForEdit?.id}, formData).then(res => {
            this.toastrService.success('Uspešno ste izmenili baner.');
            this.confirmModal.emit();
          }).catch(({error}: {error:{ errors: { [key: string]: string } }}) => {
            Object.values(error.errors).forEach(error => this.toastrService.error(error));
          })
        : this.bannersHttpService.postBanner(formData).then(res => {
            this.toastrService.success('Uspešno ste kreirali baner.');
            this.confirmModal.emit();
          }).catch(({error}: {error:{ errors: { [key: string]: string } }}) => {
            Object.values(error.errors).forEach(error => this.toastrService.error(error));
          });
    }
  }
}
