<div class="grid xs-pr-32 xs-pl-32">
  <div class="grid__m-12">
    <div class="zc-table toy-stores-table">
      <div class="zc-header-table">
        <div class="zc-header-row">
          <div class="zc-header-column">
            <span>Logo</span>
          </div>
          <div class="zc-header-column">
            <span>Naziv prodavnice</span>
          </div>
          <div class="zc-header-column">
            <span>Rabat</span>
          </div>
          <div class="zc-header-column column-buttons">
          </div>
        </div>
      </div>
      <div class="zc-content-table">
        <div class="zc-content-row" *ngFor="let store of toyStores">
          <div class="zc-content-column">
            <img [src]="store.image?.full_path" alt="">
          </div>
          <div class="zc-content-column">
            <span>{{store.name}}</span>
          </div>
          <div class="zc-content-column">
            <span>{{store.rebate}}%</span>
          </div>
          <div class="zc-content-column column-buttons">
            <div class="buttons-group">
              <div class="wrapper-button" (click)="storeForEdit = store">
                <i class="fa-solid fa-pen-to-square"></i>
              </div>
              <div class="wrapper-button" (click)="storeForDelete = store">
                <i class="fa-solid fa-trash"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<gr-create-or-edit-toy-store
  *ngIf="storeForEdit !== null"
  [storeForEdit]="storeForEdit"
  (closeModal)="storeForEdit = null"
  (confirmEditStore)="onConfirmEditToyStore($event)">
</gr-create-or-edit-toy-store>

<gr-confirm-modal
  *ngIf="storeForDelete !== null"
  [modalTitle]="'Brisanje prodavnice'"
  [modalDescription]="'Da li ste sigurni da zelite da obrišete prodavnicu?'"
  (closeModal)="storeForDelete = null"
  (confirmModal)="onConfirmDeleteToyStore()"
></gr-confirm-modal>
