<div class="wrapper-page faq-page">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div class="wrapper-content-page">
    <div class="wrapper-header-page-content">
      <span class="header-text">Česta pitanja</span>
      <button class="zc-btn zc-btn--primary" (click)="isCreateFaqModalOpen = true"><span>Dodaj</span></button>
    </div>
    <div>
      <gr-search
        (searchQueryChange)="onSearchChange($event)"
      ></gr-search>
    </div>
    <div class="grid xs-pr-32 xs-pl-32">
      <div class="grid__m-12">
        <div class="zc-wrapper-tabs">
          <div class="zc-tab" [ngClass]="{'active': isActiveTab === FaqEnums.users}" (click)="handleTabsChange(FaqEnums.users)">
            <span>{{FaqEnums.users}}</span>
          </div>
          <div class="zc-tab" [ngClass]="{'active': isActiveTab === FaqEnums.businessUsers}" (click)="handleTabsChange(FaqEnums.businessUsers)">
            <span>{{FaqEnums.businessUsers}}</span>
          </div>
        </div>
      </div>
      <div class="grid__m-6" *ngFor="let faq of faqs">
        <div class="zc-card-wrapper">
          <div class="wrapper-faq-card">
            <div class="question-answer">
              <span class="question">{{faq.question}}</span>
              <span class="answer">{{faq.answer}}</span>
            </div>
            <div class="faq-card-buttons">
              <i (click)="faqForEdit = faq" class="fa-solid fa-pen-to-square"></i>
              <i (click)="removeFaqItem(faq.id)" class="fa-solid fa-trash"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="grid__m-12">
        <gr-pagination
          *ngIf="faqs.length"
          [currentPage]="faqPagination?.currentPage"
          [pagId]="'js-faq-table-pagination'"
          [perPage]="faqPagination?.perPage"
          [totalItems]="faqPagination?.totalItems"
          (pageChanged)="onPageChanged($event)">
        </gr-pagination>
      </div>
    </div>
  </div>
  <gr-create-or-edit-faq-modal
    *ngIf="isCreateFaqModalOpen || faqForEdit"
    [faqForEdit]="faqForEdit"
    (closeModal)="isCreateFaqModalOpen = false; faqForEdit = undefined"
    (confirmModal)="onConfirmCreateOrEditFaq($event)">
  </gr-create-or-edit-faq-modal>
</div>
