<div class="wrapper-modal banners">
  <form [formGroup]="createCustomNotificationForm" (ngSubmit)="submitCustomNotificationForm()" class="wrapper-content-modal">
    <div class="header-modal">
      <span class="title-modal">Kreiraj notifikaciju</span>
    </div>
    <div class="content-modal">
      <div class="xs-mb-20">
        <div class="label-banner">
          <span>Izaberi rolu</span>
        </div>
        <div class="wrapper-custom-radiobutton">
          <label>
            <input type="radio" formControlName="role" [value]="UserRole.user" [checked]="createCustomNotificationForm.controls.role.value === UserRole.user">
            <div class="custom-radiobutton">
              <div class="circle-radio">
                <div class="circle-center"></div>
              </div>
              <span>Korisnik</span>
            </div>
          </label>
          <label>
            <input type="radio" formControlName="role" [value]="UserRole.business" [checked]="createCustomNotificationForm.controls.role.value === UserRole.business">
            <div class="custom-radiobutton">
              <div class="circle-radio">
                <div class="circle-center"></div>
              </div>
              <span>Biznis korisnik</span>
            </div>
          </label>
        </div>
        <span class="error-message">{{createCustomNotificationForm.controls.role | formControlErrorMessages: null : formTriedToSubmit}}</span>
      </div>
      <div class="xs-mb-20">
        <div class="label-banner">
          <span>Ubacite sliku banera</span>
        </div>
        <div class="wrapper-custom-gallery banners">
          <div *ngIf="!imageUrl" class="header-custom-gallery">
            <div class="wrapper-btn-gallery">
              <div class="file-input">
                <label class="file-input__label2">
                  <span>Dodaj Sliku</span>
                  <input #fileInput class="file-input__input" type="file" accept=".jpg,.jpeg,.png,.svg" (change)="onFileChange($event)"/>
                </label>
              </div>
            </div>
            <span class="error-message">{{createCustomNotificationForm.controls.image | formControlErrorMessages: null : formTriedToSubmit}}</span>
          </div>
          <div *ngIf="imageUrl" class="content-custom-gallery-banner">
            <div class="wrapper-gallery-image-banner">
              <img [src]="imageUrl" alt="banner-image">
              <div class="overlay-delete" (click)="removeFile()">
                <i class="fa-solid fa-xmark"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="xs-mb-20">
        <div class="label-banner">
          <span>Zakažite notifikaciju</span>
        </div>
        <gr-date-picker [defaultDate]="createCustomNotificationForm.controls.notificationDate.value" (dateSelected)="onSelectedDate($event)"></gr-date-picker>
        <span class="error-message">{{createCustomNotificationForm.controls.notificationDate | formControlErrorMessages: null : formTriedToSubmit}}</span>
        <div class="wrapper-input">
          <div class="wrapper-timepicker">
            <mat-form-field>
              <input matInput [ngxTimepicker]="notificationTime" [format]="24" placeholder="Select time" formControlName="notificationTime" readonly>
              <ngx-material-timepicker #notificationTime></ngx-material-timepicker>
            </mat-form-field>
          </div>
        </div>
        <span class="error-message">{{createCustomNotificationForm.controls.notificationTime | formControlErrorMessages: null : formTriedToSubmit}}</span>
        <div class="label-banner">
          <span>Dodajte naslov notifikacije</span>
        </div>
        <div class="wrapper-input">
          <div class="zc-input">
            <input type="text" formControlName="title">
          </div>
          <span class="error-message">{{createCustomNotificationForm.controls.title | formControlErrorMessages: null : formTriedToSubmit}}</span>
        </div>
        <div class="label-banner">
          <span>Dodajte opis notiifkacije</span>
        </div>
        <div class="zc-textarea">
          <textarea formControlName="description" cols="30" rows="10"></textarea>
        </div>
        <span class="error-message">{{createCustomNotificationForm.controls.description | formControlErrorMessages: null : formTriedToSubmit}}</span>

      </div>
    </div>
    <div class="footer-modal">
      <button type="button" class="zc-btn zc-btn--gray" (click)="closeModal.emit()"><span>Izađi</span></button>
      <button type="submit" class="zc-btn zc-btn--primary"><span>Dodaj</span></button>
    </div>
  </form>
</div>
