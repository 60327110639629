<div class="wrapper-notifications">
  <div class="wrapper-notification">
    <span class="title-notification">Zoran Kustrimovic</span>
    <span class="description-notification">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
    <span class="time-notification">5 min ago</span>
  </div>
  <div class="wrapper-notification">
    <span class="title-notification">Zoran Kustrimovic</span>
    <span class="description-notification">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
    <span class="time-notification">5 min ago</span>
  </div>
  <div class="wrapper-notification">
    <span class="title-notification">Zoran Kustrimovic</span>
    <span class="description-notification">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
    <span class="time-notification">5 min ago</span>
  </div>
</div>
