import {AbstractControl, FormArray, FormGroup, ValidatorFn} from '@angular/forms';

export function confirmMatchControlsValidator(controlName: string, matchingControlName: string): any{
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
      return;
    }
    (control.value !== matchingControl.value)
      ? matchingControl.setErrors({ confirmedValidator: true })
      : matchingControl.setErrors(null);
  };
}

export function minLengthArray(min: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control instanceof FormArray) {
      console.log('controle', control);
      return control.length >= min && control.value[0].value ? null : { minLengthArray: true };
    }
    return null;
  };
}
