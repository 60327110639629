import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {GeneralHttpHelperService} from '../../helpers/general-http-helper.service';
import {GetBannerApiData, GetBannersApiData, GetBannersApiDataFormatted, PostPutBannerApiData} from './banners-http-interfaces';

const BANNERS_API_ROUTES = {
  GET_BANNERS: 'admin/banners',
  GET_BANNER: 'admin/banners/:bannerId',
  POST_BANNER: 'admin/banners',
  PUT_BANNER: 'admin/banners/:bannerId',
  DELETE_BANNER: 'admin/banners/:bannerId',
  GET_BANNER_COMPANY_LINKS: 'admin/get-companies',
  GET_BANNER_CATEGORY_LINKS: 'admin/get-business-categories',
};

@Injectable({
  providedIn: 'root'
})
export class BannersHttpService {


  constructor(private httpClient: HttpClient,
              private generalHttpHelperService: GeneralHttpHelperService) { }
  private getBannersReq(urlParams: any): Observable<GetBannersApiData> {
    const apiUrl = this.generalHttpHelperService.addUrlParams(BANNERS_API_ROUTES.GET_BANNERS, urlParams)
    return this.httpClient.get(apiUrl) as any;
  }

  getBanners(urlParams: any): Promise<GetBannersApiDataFormatted> {
    return new Promise<GetBannersApiDataFormatted>((resolve, reject) => {
      this.getBannersReq(urlParams).subscribe((res: GetBannersApiData) => {
        resolve({
          data: res.data.data,
          pagination: this.generalHttpHelperService.returnPagination(res.data)
        });
      }, error => {
        reject(error);
      });
    });
  }

  private getBannerReq(urlParams?: Record<string, any>): Observable<GetBannerApiData> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(BANNERS_API_ROUTES.GET_BANNER, urlParams);
    return this.httpClient.get(apiUrl) as any;
  }

  getBanner(urlParams?: Record<string, any>): Promise<GetBannerApiData> {
    return new Promise<GetBannerApiData>((resolve, reject) => {
      this.getBannerReq(urlParams).subscribe((res: GetBannerApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private postBannerReq(bodyParams: any): Observable<PostPutBannerApiData> {
    return this.httpClient.post(BANNERS_API_ROUTES.POST_BANNER, bodyParams) as any;
  }

  postBanner(bodyParams: any): Promise<PostPutBannerApiData> {
    return new Promise<any>((resolve, reject) => {
      this.postBannerReq(bodyParams).subscribe((res: PostPutBannerApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private putBannerReq(urlParam: Record<string, any>, bodyParams: any): Observable<PostPutBannerApiData> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(BANNERS_API_ROUTES.PUT_BANNER, urlParam);
    return this.httpClient.post(apiUrl, bodyParams) as any;
  }

  putBanner(urlParam: Record<string, any>, bodyParams: any): Promise<PostPutBannerApiData> {
    return new Promise<any>((resolve, reject) => {
      this.putBannerReq(urlParam, bodyParams).subscribe((res: PostPutBannerApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private deleteBannerReq(urlParam: Record<string, any>): Observable<PostPutBannerApiData> {
    const url = this.generalHttpHelperService.replaceUrlParamsWithValues(BANNERS_API_ROUTES.DELETE_BANNER, urlParam);
    return this.httpClient.delete(url) as any;
  }

  deleteBanner(urlParam: Record<string, any>): Promise<PostPutBannerApiData> {
    return new Promise<any>((resolve, reject) => {
      this.deleteBannerReq(urlParam).subscribe((res: PostPutBannerApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private getAutocompleteBannerLinkReq(urlParams: any): Observable<any> {
    let apiUrl = urlParams.linkType === 'company' ? BANNERS_API_ROUTES.GET_BANNER_COMPANY_LINKS : BANNERS_API_ROUTES.GET_BANNER_CATEGORY_LINKS;
    apiUrl = this.generalHttpHelperService.addUrlParams(apiUrl, {searchTerm: urlParams.searchTerm});
    return this.httpClient.get(apiUrl) as any;
  }

  getAutocompleteLink(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getAutocompleteBannerLinkReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
}
