import { trigger, state, style, animate, transition } from '@angular/animations';

export const fadeInFadeOut = trigger('fadeInFadeOut', [
  state('void', style({
    opacity: 0
  })),
  transition(':enter', [
    animate('400ms ease-in', style({ opacity: 1 }))
  ]),
  transition(':leave', [
    animate('400ms ease-out', style({ opacity: 0 }))
  ])
]);
