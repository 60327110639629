<div class="wrapper-page faq-page">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div class="wrapper-content-page">
    <div class="wrapper-header-page-content">
      <span class="header-text">Biznis profili</span>
      <div *ngIf="isBusinessProfileRequestFinished && isBusinessApproved === null">
        <button (click)="approveOrRejectBusinessProfile(1)">Odobri</button>
        <button (click)="approveOrRejectBusinessProfile(0)">Odbij</button>
      </div>
      <span
        *ngIf="isBusinessProfileRequestFinished && isBusinessApproved !== null">{{isBusinessApproved ? 'Odobren' : 'Odbijen'}}</span>
    </div>
    <div class="zc-wrapper-tabs">
      <div (click)="activeTab = businessProfileTabs.baseData" class="zc-tab"
           [ngClass]="{'active': activeTab === businessProfileTabs.baseData}">
        <span>Osnovni podaci</span>
      </div>
      <div (click)="activeTab = businessProfileTabs.category" class="zc-tab"
           [ngClass]="{'active': activeTab === businessProfileTabs.category}">
        <span>Cenovnik</span>
      </div>
      <div (click)="activeTab = businessProfileTabs.terms" class="zc-tab"
           [ngClass]="{'active': activeTab === businessProfileTabs.terms}">
        <span>Termini</span>
      </div>
    </div>
    <!--   Osnovni podaci   -->
    <gr-base-data
      *ngIf="activeTab === businessProfileTabs.baseData"
      [businessProfileData]="businessData">
    </gr-base-data>
    <!--   Kategorije   -->

    <gr-categories *ngIf="activeTab === businessProfileTabs.category"></gr-categories>
    <!--   Termini   -->
    <div *ngIf="activeTab === businessProfileTabs.terms" class="wrapper-terms xs-p-32">
      <gr-terms
        [urlParams]="urlParams"
      ></gr-terms>

      <div class="grid">
        <div class="grid__m-6">

        </div>
      </div>
    </div>
  </div>
</div>
