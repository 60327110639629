import {Component, Input, OnInit} from '@angular/core';
import {BusinessProfilesHttpService} from '../../../../services/http-services/business-profiles/business-profiles-http.service';
import {GetTermsApiData, GrCalendar} from '../../../../services/http-services/business-profiles/business-profiles-http-interfaces';

@Component({
  selector: 'gr-terms',
  templateUrl: './terms.component.html',
})
export class TermsComponent implements OnInit {
  @Input() urlParams: any;
  termsData!: GetTermsApiData;
  sameWorkTime = false;
  sameSlots = false;
  constructor(private businessProfilesHttpService: BusinessProfilesHttpService) {
  }

  ngOnInit() {
    this.getTermsData();
  }

  getTermsData(): void {
    this.businessProfilesHttpService.getFirstStepTermsData(this.urlParams).then(res => {
      this.termsData = res;
      this.sameWorkTime = res.data.calendars.length > 0 && res.data.calendars.every((element) => {
        return element.working_time_start === res.data.calendars[0].working_time_start && element.working_time_end === res.data.calendars[0].working_time_end;
      });
      this.sameSlots = res.data.calendars.length > 0 && res.data.calendars.every((element) => {
        return element.time_slot === res.data.calendars[0].time_slot && element.break_time === res.data.calendars[0].break_time;
      });
    });
  }
}
