<div class="wrapper-page faq-page">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div class="wrapper-content-page">
    <div class="wrapper-header-page-content">
      <span class="header-text">Rezervacije</span>
      <!--      <button class="zc-btn zc-btn&#45;&#45;primary"><span>Dodaj</span></button>-->
    </div>
    <div class="grid xs-pr-32 xs-pl-32 xs-pt-32">
      <div class="grid__m-6">
        <span>test</span>
      </div>
    </div>
  </div>
</div>
