<div [ngClass]="isButtonLoader ? 'zc-spinner-wrapper-buttons' : 'zc-spinner-wrapper'">
  <div class="zc-spinner">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
