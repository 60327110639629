import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {GrFaq} from '../../../interfaces/faq-interfaces';
import {NotificationsHttpService} from '../../../services/http-services/notifications/notifications-http.service';
import {UserRole} from '../../../enums/general-enums';
import {ToastrService} from 'ngx-toastr';
import {DatePickerModule} from '../../../shared-components/date-picker/date-picker.module';
import {MatFormField} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {DatePipe, NgIf} from '@angular/common';
import {FormControlErrorMessagesModule} from '../../../pipes/form-control-error-messages/form-control-error-messages.module';

@Component({
  selector: 'gr-create-notification-modal',
  standalone: true,
  templateUrl: './create-notification-modal.component.html',
  imports: [
    ReactiveFormsModule,
    DatePickerModule,
    MatFormField,
    MatInput,
    NgxMaterialTimepickerModule,
    FormControlErrorMessagesModule,
    NgIf
  ],
  providers: [DatePipe]
})
export class CreateNotificationModalComponent {
  createCustomNotificationForm!: FormGroup;
  formTriedToSubmit = false;
  imageUrl = '';
  initialImage: any;
  @Input() notificationForEdit: any;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmModal: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  protected readonly UserRole = UserRole;

  constructor(private formBuilder: FormBuilder,
              private toastrService: ToastrService,
              private datePipe: DatePipe,
              private notificationsHttpService: NotificationsHttpService) {}
  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.createCustomNotificationForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      image: [null, Validators.required],
      deleted_images_id: [undefined],
      notificationDate: ['', Validators.required],
      notificationTime: ['', Validators.required],
      role: ['', Validators.required]
    });
    if (this.notificationForEdit) {
      this.createCustomNotificationForm.patchValue(this.notificationForEdit);
      this.createCustomNotificationForm.controls.image.patchValue('not changed');
      this.createCustomNotificationForm.controls.notificationDate.patchValue(this.notificationForEdit.release_date.slice(0, 10));
      this.createCustomNotificationForm.controls.notificationTime.patchValue(this.notificationForEdit.release_date.slice(11, -3));
      this.imageUrl = this.notificationForEdit?.image.full_path;
      this.initialImage = this.notificationForEdit?.image;
    }
  }


  onFileChange(event: any): void {
    const file = event.target.files[0];
    if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/svg') {
      this.toastrService.warning('Pogrešan tip dokumenta. Samo JPG, JPEG, SVG i PNG formati su dozvoljeni.');
      return;
    } else if (file?.size > 2097152) {
      this.toastrService.warning(`Slika ${file.name} je veća od 2MB.`);
      return;
    } else {
      this.createCustomNotificationForm.controls.image.patchValue(file);
      this.imageUrl = URL.createObjectURL(file);
      this.fileInput.nativeElement.value = '';
    }
  }

  removeFile(): void {
    this.createCustomNotificationForm.controls.image.patchValue(null);
    this.imageUrl = '';
    if (this.initialImage)  {
      this.createCustomNotificationForm.controls.deleted_images_id.patchValue(this.initialImage.id);
    }
  }

  onSelectedDate(selectedDate: any): void {
    this.createCustomNotificationForm.controls.notificationDate.patchValue(this.datePipe.transform(selectedDate, 'yyyy-MM-dd'));
  }

  submitCustomNotificationForm(): void {
    this.formTriedToSubmit = true;
    if (this.createCustomNotificationForm.valid) {
      const {notificationDate, notificationTime, ...restForm} = this.createCustomNotificationForm.value;
      const requestBody = {...restForm, release_date: `${notificationDate} ${notificationTime}`};
      const formData = new FormData();
      for (const key in requestBody) {
        console.log(key, requestBody[key]);
        if (requestBody[key] !== 'not changed' && requestBody[key] !== null) {
          formData.append(key, requestBody[key]);
        }
      }
      this.notificationForEdit
        ? this.notificationsHttpService.editCustomNotification({notificationId: this.notificationForEdit.id}, formData).then((res: any) => {
          this.confirmModal.emit(res);
        })
        : this.notificationsHttpService.createCustomNotification(formData).then((res: any) => {
          this.confirmModal.emit(res);
        });
    }
  }
}
