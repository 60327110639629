import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthGuardService} from './guards/auth-guard.service';
import {OuterPagesGuardService} from './guards/outer-pages-guard.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TokenInterceptor} from './interceptors/token.interceptor';
import {BaseUrl} from './interceptors/base-url.interceptor';
import {SideMenuModule} from './shared-components/side-menu/side-menu.module';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxPaginationModule} from 'ngx-pagination';
import {QuillConfigModule, QuillModule} from 'ngx-quill';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DatePipe, registerLocaleData} from '@angular/common';
import localeSrLatin from '@angular/common/locales/sr-Latn';
import {MondayStartDateAdapter} from './services/helpers/monday-start-date-adapter';

registerLocaleData(localeSrLatin);


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    QuillModule.forRoot(),
    // QuillConfigModule.forRoot({
    //   // modules: {
    //   //   syntax: true,
    //   // }
    // }),
    ToastrModule.forRoot({
      timeOut: 2500,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      progressBar: true,
      progressAnimation: 'increasing'
    }),
    NgxPaginationModule,
    SideMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  providers: [
    DatePipe,
    AuthGuardService,
    OuterPagesGuardService,
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: BaseUrl, multi: true},
    provideAnimationsAsync(),
    MatDatepickerModule,
    MatNativeDateModule,
    { provide: DateAdapter, useClass: MondayStartDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: 'sr-Latn' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
