import {Component, OnInit} from '@angular/core';
import {SettingsTabsEnums} from '../../enums/settings-enums';

@Component({
  selector: 'gr-settings',
  templateUrl: './settings.component.html',
})
export class SettingsComponent implements OnInit{
  activeTab = SettingsTabsEnums.base;
  protected readonly SettingsTabsEnums = SettingsTabsEnums;

  constructor() {
  }

  ngOnInit(): void {
  }
}
