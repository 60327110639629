<div class="grid__m-12 xs-pr-32 xs-pl-32 xs-pt-16 ">
  <div class="wrapper-custom-category">
    <div class="header-custom-category xs-pb-16">
      <span class="title-category">Lista svih kategorija</span>
      <button (click)="isCreatePriceListCategoryModalOpen = true" class="zc-btn zc-btn--primary" type="button">
        <span>Dodaj kategoriju</span></button>
    </div>
    <div class="grid content-custom-category">
      <div *ngFor="let category of priceListCategories"
           class="grid__s-12 grid__l-4 grid__xl-3 grdi_xxl-2 wrapper-category-card">
        <div class="title-category-content">
          <span>{{category.name}}</span>
        </div>
        <div class="buttons-category">
          <i (click)="priceListCategoryForEdit = category" class="fa-solid fa-pen-to-square"></i>
          <i (click)="priceListCategoryForDelete = category" class="fa-solid fa-trash"></i>
          <i [routerLink]="['/business-profile', urlParams.companyId, 'price-list-category', category.id]"
             class="fa-solid fa-eye"></i>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="grid">
  <div class="grid__m-12">
    <gr-pagination
      *ngIf="priceListCategories.length"
      [currentPage]="priceListCategoriesPagination?.currentPage"
      [pagId]="'js-price-list-categories-table-pagination'"
      [perPage]="priceListCategoriesPagination?.perPage"
      [totalItems]="priceListCategoriesPagination?.totalItems"
      (pageChanged)="onPageChanged($event)">
    </gr-pagination>
  </div>
</div>
<gr-create-or-edit-price-list-category-modal
  *ngIf="isCreatePriceListCategoryModalOpen || priceListCategoryForEdit !== null"
  [urlParams]="urlParams"
  [priceListCategoryForEdit]="priceListCategoryForEdit"
  (closeModal)="isCreatePriceListCategoryModalOpen = false; priceListCategoryForEdit = null"
  (confirmModal)="onConfirmCreateOrEditPriceListCategory()"
></gr-create-or-edit-price-list-category-modal>

<gr-confirm-modal
  *ngIf="priceListCategoryForDelete !== null"
  [modalTitle]="'Brisanje kategorije u cenovniku'"
  [modalDescription]="'Da li ste sigurni da zelite da obrišete kategoriju cenovnika?'"
  (closeModal)="priceListCategoryForDelete = null"
  (confirmModal)="onConfirmDeletePriceListCategory()"
></gr-confirm-modal>
