import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BusinessProfilesHttpService} from '../../../../../services/http-services/business-profiles/business-profiles-http.service';
import {MatCalendar, MatCalendarCellClassFunction} from '@angular/material/datepicker';
import {DatePipe} from '@angular/common';
import {DaysOfWeek, WorkDays} from '../../../../../constants/general-variables';
import {GetTermsApiData} from '../../../../../services/http-services/business-profiles/business-profiles-http-interfaces';

@Component({
  selector: 'gr-terms-second-step',
  templateUrl: './terms-second-step.component.html',
})
export class TermsSecondStepComponent implements OnInit, OnChanges {
  @Input() urlParams: any;
  @Input() termsData!: GetTermsApiData;
  @Input() sameSlots = false;
  minimalPriceForm!: FormGroup;
  differentPriceForm!: FormGroup;
  formTriedToSubmit = false;
  calendarData: any[] = [];
  calendarSelectedTermsData: any = null;
  calendarDateKeys: any[] = [];
  selectedTermsDate: any = null;
  minDate: any;
  selected: Date | null = null;
  isChangeWorkTermsModalOpen = false;
  minimalPriceFormTriedToSubmit = false;
  diffPriceFormTriedToSubmit = false;
  today: any;
  tomorrow: any;
  @ViewChild('calendar') calendar!: MatCalendar<Date>;
  protected readonly WorkDays = WorkDays;
  filteredDate = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    const notWorkingDays = DaysOfWeek.filter(weekDay => {
      return this.termsData.data.calendars.every(element => element.day !== weekDay.dayForDB);
    });
    return notWorkingDays.every(notWorkDay => {
      return day !== notWorkDay.number;
    });
  };

  constructor(private formBuilder: FormBuilder,
              private datePipe: DatePipe,
              private businessProfilesHttpService: BusinessProfilesHttpService) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.today = new Date();
    this.tomorrow = new Date(this.today);
    this.tomorrow.setDate(this.today.getDate() + 1);
    this.minDate = this.tomorrow.toISOString().split('T')[0];
    this.onViewChanged(this.today);
    this.differentPriceForm.controls.every_term_same_price.valueChanges.subscribe(val => {
      (val)
        ? this.differentPriceForm.controls.time.patchValue('')
        : this.differentPriceForm.controls.time.patchValue(this.selectedTermsDate ? this.selectedTermsDate[0].start_time.slice(0, -3) : '');
    });
    setTimeout(() => {
      this.calendar?.stateChanges.subscribe(() => {
        if (this.calendar.activeDate) {
          this.onViewChanged(this.calendar.activeDate);
        }
      });
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.termsData && changes.termsData.currentValue) {
      this.minimalPriceForm?.controls.price.patchValue(changes.termsData.currentValue.data.minimal_calendar_price.price);
    }
  }

  buildForm(): void {
    this.minimalPriceForm = this.formBuilder.group({
      price: ['', Validators.required],
      every_day_same_price: [false],
    });
    this.minimalPriceForm.controls.price.patchValue(this.termsData?.data?.minimal_calendar_price?.price);

    this.differentPriceForm = this.formBuilder.group({
      date: ['', Validators.required],
      time: [''],
      price: ['', Validators.required],
      every_term_same_price: [false],
    });
  }

  getCalendarData(): void {
    this.businessProfilesHttpService.getCalendarTerms({...this.urlParams}).then(res => {
      this.calendarDateKeys = [];
      this.calendarData = res.data;
      Object.values(res.data).forEach((element: any) => {
        Object.keys(element).forEach((key) => {
          if (element[key].length) {
            this.calendarDateKeys.push({key, date: this.datePipe.transform(key, 'dd.MM.yyyy')});
          }
        });
      });
    });
  }

  onViewChanged(event: any): void {
    console.log('event, ', event);
    (event.getMonth() === this.today.getMonth())
      ? this.urlParams.date_from = this.datePipe.transform(this.tomorrow, 'yyyy-MM-dd')
      : this.urlParams.date_from = this.datePipe.transform(new Date(event.getFullYear(), event.getMonth(), 2), 'yyyy-MM-dd');
    this.urlParams.date_to = this.datePipe.transform(new Date(event.getFullYear(), event.getMonth() + 1, 1), 'yyyy-MM-dd');
    this.getCalendarData();
  }

  onCalendarDateChange(event: any): void {
    this.selected = event;
    const findDate = Object.values(this.calendarData.find(element => {
      return element.hasOwnProperty(this.datePipe.transform(event, 'yyyy-MM-dd'));
    }));
    this.calendarSelectedTermsData = findDate[0];
    this.isChangeWorkTermsModalOpen = true;
  }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    if (view === 'month' && this.termsData) {
      const date = cellDate.getDate();
      const notWorkingDate = this.termsData.data.not_work_days.some(element => {
        const elementDate = new Date(element.date);
        return cellDate.getFullYear() === elementDate.getFullYear() &&
          cellDate.getMonth() === elementDate.getMonth() &&
          cellDate.getDate() === elementDate.getDate();
      });
      return notWorkingDate ? 'calendar-not-work-date' : '';
    }
    return '';
  };

  onSelectedDateOfTerm(date: any): void {
    console.log(date);
    this.differentPriceForm.controls.date.patchValue(date.key);
    const findDate = Object.values(this.calendarData.find(element => {
      return element.hasOwnProperty(date.key);
    }));
    this.selectedTermsDate = findDate[0];
    this.differentPriceForm.controls.every_term_same_price.patchValue(this.selectedTermsDate.every((element: any) => {
      element.price === this.selectedTermsDate[0].price;
    }));
    this.differentPriceForm.controls.price.patchValue(this.selectedTermsDate[0].price);
    this.differentPriceForm.controls.time.patchValue(this.selectedTermsDate[0].start_time.slice(0, -3));
  }

  onSelectTerms(selectedTerm: any): void {
    this.differentPriceForm.controls.price.patchValue(selectedTerm.price);
    this.differentPriceForm.controls.time.patchValue(selectedTerm.start_time.slice(0, -3));
  }

  onSubmitMinimalPriceForm(): void {
    this.minimalPriceFormTriedToSubmit = true;
    if (this.minimalPriceForm.valid) {
      this.businessProfilesHttpService.postMinimalPrice(this.urlParams, this.minimalPriceForm.value).then(res => {
        console.log(res);
      });
    }
  }

  onSubmitDifferentPriceForm(): void {
    this.diffPriceFormTriedToSubmit = true;
    if (this.differentPriceForm.valid) {
      const specificTerm = this.termsData.data.specific_calendar_prices.find(element => {
        return (element.company_id === Number(this.urlParams.companyId) && element.date === this.differentPriceForm.controls.date.value && element.time && element.time.slice(0, -3) === this.differentPriceForm.controls.time.value);
      });
      this.businessProfilesHttpService.postDifferentPrice(this.urlParams, {...this.differentPriceForm.value, id: specificTerm ? specificTerm.id : undefined}).then(res => {
        console.log(res);
      });
    }
  }
}
