import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PostPutFaqApiData} from '../../../../services/http-services/faq/faq-http-interfaces';
import {FaqHttpService} from '../../../../services/http-services/faq/faq-http.service';
import {FaqEnums} from '../../../../enums/faq-enums';
import {GrFaq} from '../../../../interfaces/faq-interfaces';

@Component({
  selector: 'gr-create-or-edit-faq-modal',
  templateUrl: './create-or-edit-faq-modal.component.html',
})
export class CreateOrEditFaqModalComponent implements OnInit {
  createFaqItemForm!: FormGroup;
  formTriedToSubmit = false;
  typeOfUsers = [{name: FaqEnums.user, nameForDB: FaqEnums.userForDB}, {name: FaqEnums.businessUser, nameForDB: FaqEnums.businessUserForDB}]
  @Input() faqForEdit: GrFaq | undefined;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmModal: EventEmitter<any> = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder,
              private faqHttpService: FaqHttpService) {}
  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.createFaqItemForm = this.formBuilder.group({
      question: ['', Validators.required],
      answer: ['', Validators.required],
      type: ['', Validators.required],
    });
    if (this.faqForEdit) {
      this.createFaqItemForm.patchValue(this.faqForEdit);
    }
  }

  submitFaqForm(): void {
    this.formTriedToSubmit = true;
    if (this.createFaqItemForm.valid) {
      this.faqForEdit
      ? this.faqHttpService.putFaqItem({faqId: this.faqForEdit.id}, this.createFaqItemForm.value).then((res: PostPutFaqApiData) => {
          this.confirmModal.emit(res);
        })
      : this.faqHttpService.postFaqItem(this.createFaqItemForm.value).then((res: PostPutFaqApiData) => {
        this.confirmModal.emit(res);
      });
    }
  }
}
