<div class="wrapper-modal">
  <form [formGroup]="createOrEditDeliveryServiceForm" (ngSubmit)="onSubmitDeliveryServiceForm()" class="wrapper-content-modal">
    <div class="header-modal">
      <span class="title-modal">Dodaj dostavljača</span>
    </div>
    <div class="content-modal">
      <div class="wrapper-input">
        <span class="label-input">Ime dostavljača</span>
        <div class="zc-input">
          <input type="text" formControlName="name">
        </div>
        <span class="error-message">{{createOrEditDeliveryServiceForm.controls.name | formControlErrorMessages: null : formTriedToSubmit}}</span>
      </div>
      <gr-dropdown-reactive-form
        [items]="listOfCities"
        [propIdentifier]="'name'"
        [dropdownLabel]="'Grad'"
        [placeholder]="'Grad'"
        [formControl]="createOrEditDeliveryServiceForm.controls.city | formControl"
        [formTriedToSubmit]="formTriedToSubmit"
      ></gr-dropdown-reactive-form>
      <div class="wrapper-input">
        <span class="label-input">Cena korisnika (Prikazuje se korisniku)</span>
        <div class="zc-input">
          <input type="text" formControlName="price_for_user">
        </div>
        <span class="error-message">{{createOrEditDeliveryServiceForm.controls.price_for_user | formControlErrorMessages: 'Unesite validnu cenu.' : formTriedToSubmit}}</span>

      </div>
      <div class="wrapper-input">
        <span class="label-input">Cena dostavljača (Koliko je on ponudio firmi moj rođendan)</span>
        <div class="zc-input">
          <input type="text"  formControlName="price_for_admin">
        </div>
        <span class="error-message">{{createOrEditDeliveryServiceForm.controls.price_for_admin | formControlErrorMessages: 'Unesite validnu cenu.' : formTriedToSubmit}}</span>
      </div>
    </div>
    <div class="footer-modal">
      <button type="button" class="zc-btn zc-btn--gray" (click)="closeModal.emit()"><span>Izađi</span></button>
      <button type="submit" class="zc-btn zc-btn--primary"><span>Dodaj</span></button>
    </div>
  </form>
</div>
