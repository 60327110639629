import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NotificationsHttpService} from '../../../services/http-services/notifications/notifications-http.service';
import {GrPagination} from '../../../interfaces/general-interfaces';
import {NgForOf, SlicePipe} from '@angular/common';

@Component({
  selector: 'gr-notification-custom-tab',
  standalone: true,
  templateUrl: './notification-custom-tab.component.html',
  imports: [
    NgForOf,
    SlicePipe
  ]
})
export class NotificationCustomTabComponent implements OnInit {
  @Output() editNotification: EventEmitter<any> =  new EventEmitter<any>();
  customNotificationsPagination: GrPagination | undefined;
  customNotifications: any[] = [];
  urlParams = {page: 1};

  constructor(private notificationsHttpService: NotificationsHttpService) {
  }

  ngOnInit(): void {
    this.customNotificationsPagination = undefined;
    this.customNotifications = [];
    this.getNotifications();
  }

  getNotifications(): void {
    this.urlParams.page = (this.customNotificationsPagination?.currentPage) ? this.customNotificationsPagination.currentPage : 1;
    this.notificationsHttpService.getCustomNotifications(this.urlParams).then(res => {
      this.customNotifications = [...this.customNotifications, ...res.data];
    });
  }
}
