import {Injectable} from '@angular/core';
import {GrLinksApiData, GrPagination} from '../../interfaces/general-interfaces';

@Injectable({
  providedIn: 'root'
})

export class GeneralHttpHelperService {

  constructor() {
  }

  returnPagination(response: any): GrPagination {
    return {
      currentPage: response.current_page,
      totalItems: response.total,
      perPage: response.per_page,
      nextPageUrl: response.next_page_url,
      to: response.to,
      from: response.from,
      lastPage: response.last_page
    };
  }

  addUrlParams(url?: string, params?: Record<string, any>): string {
    const paramsPairs: any[] = [];
    if (params) {
      for (const key in params) {
        if (typeof params[key] === 'object' && params[key] !== null && params[key] !== undefined && params[key] !== '') {
          for (const itemKey in params[key]) {
            paramsPairs.push(`${key}[${itemKey}]=${params[key][itemKey]}`);
          }
        } else if (params[key] !== null && params[key] !== undefined && params[key] !== '') {
          paramsPairs.push(`${key}=${params[key]}`);
        }
      }
    }

    return `${url}?${paramsPairs.join('&')}`;
  }

  replaceUrlParamsWithValues(url: string, params?: Record<string, any>): string {
    let newUrl = url;
    if(params) {
      for (const key in params) {
        newUrl = newUrl.replace(`:${key}`, params[key]);
      }
    }
    return newUrl;
  }
}
