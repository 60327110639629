import {Component, OnInit} from '@angular/core';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationHttpService} from '../../services/http-services/auth/authentication-http.service';
import {PasswordRegex} from '../../constants/general-variables';
import {confirmMatchControlsValidator} from '../../validators/form-validators.validator';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';

@Component({
  selector: 'gr-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm!: FormGroup;
  formTriedToSubmit = false;
  isPasswordShow = false;
  isConfirmPasswordShow = false;
  isSubmitBtnDisabled = true;
  resetPasswordFormSubscription!: Subscription;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(private formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private toastrService: ToastrService,
              private authenticationHttpService: AuthenticationHttpService) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.activatedRoute.params.subscribe((res: any) => {
      this.resetPasswordForm.controls.token.patchValue(res.token);
    });
    this.resetPasswordFormSubscription = this.resetPasswordForm.valueChanges.subscribe((data) => {
      this.isSubmitBtnDisabled = Object.values(data).some(item => !!item === false);
    })
  }

  buildForm(): void {
    this.resetPasswordForm = this.formBuilder.group({
      token:  ['', Validators.required],
      password: ['', [Validators.required, Validators.pattern(PasswordRegex)]],
      password_confirmation: ['', [Validators.required, Validators.pattern(PasswordRegex)]],
    }, {
      validators: confirmMatchControlsValidator('password', 'password_confirmation')
    });
  }

  submitResetPasswordForm(): void {
    this.formTriedToSubmit = true;
    if (this.resetPasswordForm.valid) {
      this.authenticationHttpService.resetPassword(this.resetPasswordForm.value).then((res: any) => {
        this.toastrService.success(res.message);
      }).catch(({error}: {error:{ errors: { [key: string]: string } }}) => {
        Object.values(error.errors).forEach(error => this.toastrService.error(error));
      });
    }
  }
}
