import {Component, OnInit} from '@angular/core';
import {BannersHttpService} from '../../services/http-services/banners/banners-http.service';
import {GrBanner} from '../../interfaces/banners-interfaces';
import {ToastrService} from 'ngx-toastr';
import {GrPagination} from '../../interfaces/general-interfaces';

@Component({
  selector: 'gr-banners',
  templateUrl: './banners.component.html',
})
export class BannersComponent implements OnInit {
  banners: GrBanner[] = [];
  bannerForDelete: GrBanner | null = null;
  bannerForEdit: GrBanner | null = null;
  bannersPagination: GrPagination | undefined;
  isCreateBannerModalOpen = false;
  constructor(private bannersHttpService: BannersHttpService,
              private toastrService: ToastrService) {
  }

  ngOnInit() {
    this.getBanners();
  }

  getBanners(): void {
    const page = (this.bannersPagination?.currentPage) ? this.bannersPagination.currentPage : 1;
    this.bannersHttpService.getBanners({page}).then(res => {
      this.banners = res.data;
      this.bannersPagination = res.pagination;
    })
  }

  onPageChanged(page: any): void {
    if (this.bannersPagination) {
      this.bannersPagination.currentPage = page;
      this.getBanners();
    }
  }

  onConfirmCreateOrEditBanner(): void {
    this.bannerForEdit = null;
    this.isCreateBannerModalOpen = false;
    this.getBanners();
  }

  onConfirmDeleteBanner(): void {
    this.bannersHttpService.deleteBanner({bannerId: this.bannerForDelete?.id}).then((res: any) => {
      this.bannerForDelete = null;
      this.getBanners();
      this.toastrService.success("Usepšeno ste obrisali banner.")
    }).catch(({error}: {error:{ errors: { [key: string]: string } }}) => {
      Object.values(error.errors).forEach(error => this.toastrService.error(error));
    });
  }
}
