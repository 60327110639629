<div class="wrapper-modal add-invitation-images">
  <form [formGroup]="editInvitationForm" (ngSubmit)="onSubmitEditInvitationForm()" class="wrapper-content-modal">
    <div class="header-modal">
      <span class="title-modal">Izmeni slike za pozivnicu </span>
    </div>
    <div class="content-modal">
      <div class="grid__m-12">
        <div class="wrapper-custom-gallery">
          <div class="header-custom-gallery">
            <span class="title-gallery">Dizajn pozivnica</span>
            <div class="wrapper-btn-gallery">
              <div class="file-input">
                <input
                  #fileInput
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  name="file-input"
                  id="file-input"
                  class="file-input__input"
                  multiple
                  (change)="onFileChange($event)"
                />
                <label class="file-input__label" for="file-input"><span>Dodaj slike</span></label>
              </div>
              <button (click)="removeAllImages()" class="zc-btn zc-btn--delete" type="button"><span>Obriši sve slike</span></button>
            </div>
          </div>
          <div class="content-custom-gallery">
            <div class="wrapper-gallery-images">
              <div class="grid">
                <div *ngFor="let imgUrl of imageUrls" class="wrapper-gallery-image grid__m-2">
                  <img [src]="imgUrl.fullPath" alt="">
                  <span class="icon-delete"
                        (click)="removeSingleImage(imgUrl)">
                    <i class="fa-regular fa-trash-can"></i>
                  </span>
                </div>
              </div>

            </div>
          </div>
          <span class="error-message">{{editInvitationForm.controls.images | formControlErrorMessages: null : formTriedToSubmit}}</span>
        </div>
      </div>
    </div>
    <div class="footer-modal">
      <button class="zc-btn zc-btn--gray" type="button" (click)="closeModal.emit()"><span>Zatvori</span></button>
      <button class="zc-btn zc-btn--primary zc-position-relative" [disabled]="isLoaderActive" type="submit">
        <gr-loader *ngIf="isLoaderActive" [isButtonLoader]="true"></gr-loader>
        <span>Sačuvaj</span>
      </button>
    </div>
  </form>
</div>
