<div class="wrapper-page settings">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div class="wrapper-content-page">
    <div class="wrapper-header-page-content">
      <span class="header-text">Podešavanja</span>
    </div>
    <div class="zc-wrapper-tabs">
      <div class="zc-tab" (click)="activeTab = SettingsTabsEnums.base" [ngClass]="{'active': activeTab === SettingsTabsEnums.base}">
        <span>Osnovni podaci</span>
      </div>
      <div class="zc-tab" (click)="activeTab = SettingsTabsEnums.password" [ngClass]="{'active': activeTab === SettingsTabsEnums.password}">
        <span>Podaci o lozinki</span>
      </div>
      <div class="zc-tab" (click)="activeTab = SettingsTabsEnums.administration" [ngClass]="{'active': activeTab === SettingsTabsEnums.administration}">
        <span>Administrativni troškovi</span>
      </div>
    </div>
    <gr-tab-base-data *ngIf="activeTab === SettingsTabsEnums.base"></gr-tab-base-data>
    <gr-tab-password-data *ngIf="activeTab === SettingsTabsEnums.password"></gr-tab-password-data>
    <gr-tab-administration-data *ngIf="activeTab === SettingsTabsEnums.administration"></gr-tab-administration-data>
  </div>
</div>

