import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {DropdownModule} from '../../../../shared-components/dropdowns/dropdown/dropdown.module';
import {DropdownReactiveFormModule} from '../../../../shared-components/dropdowns/dropdown-reactive-form/dropdown-reactive-form.module';
import {FormControlModule} from '../../../../pipes/form-control/form-control.module';
import {FormControlErrorMessagesModule} from '../../../../pipes/form-control-error-messages/form-control-error-messages.module';
import {NgIf} from '@angular/common';
import {PresentsHttpService} from '../../../../services/http-services/presents/presents-http.service';
import {ToastrService} from 'ngx-toastr';
import {LoaderComponent} from '../../../../shared-components/loader/loader.component';

@Component({
  selector: 'gr-upload-presents-table-modal',
  templateUrl: './upload-presents-table-modal.component.html',
  imports: [
    ReactiveFormsModule,
    DropdownModule,
    DropdownReactiveFormModule,
    FormControlModule,
    FormControlErrorMessagesModule,
    NgIf,
    LoaderComponent,
  ],
  standalone: true
})
export class UploadPresentsTableModalComponent implements OnInit {
  formTriedToSubmit = false;
  uploadPresentTableForm!: FormGroup;
  isLoaderActive = false;
  @Input() stores: any[] = [];
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmModal: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  constructor(private formBuilder: FormBuilder,
              private toastService: ToastrService,
              private presentsHttpService: PresentsHttpService) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.uploadPresentTableForm = this.formBuilder.group({
      supplier_id: ['', Validators.required],
      file: ['', Validators.required],
    });
  }

  onFileChange(event: any): void {
    const file = event.target.files[0];
    if (file?.size > 2097152) {
      this.toastService.warning(`Slika ${file.name} je veća od 2MB.`);
      return;
    } else {
      this.uploadPresentTableForm.controls.file.patchValue(event.target.files[0]);
      this.fileInput.nativeElement.value = '';
    }
  }

  removeFile(): void {
    this.uploadPresentTableForm.controls.file.patchValue(null);
  }

  onSubmitUploadPresentsTableForm(): void {
    this.formTriedToSubmit = true;
    if (this.uploadPresentTableForm.valid) {
      this.isLoaderActive = true;
      const formData: FormData = new FormData();
      formData.append('file', this.uploadPresentTableForm.controls.file.value);
      this.presentsHttpService.uploadPresentTable({presentId: this.uploadPresentTableForm.controls.supplier_id.value}, formData).then(res => {
        this.isLoaderActive = false;
        this.confirmModal.emit();
        this.toastService.success('Uspešno ste uvezli tabelu sa poklonima');
      });
    }
  }
}
