import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {PresentsHttpService} from '../../../../services/http-services/presents/presents-http.service';
import {ToastrService} from 'ngx-toastr';
import {CreateOrEditToyStoreComponent} from '../create-or-edit-toy-store/create-or-edit-toy-store.component';
import {ConfirmModalModule} from '../../../../shared-components/modals/confirm-modal/confirm-modal.module';
import {NgForOf, NgIf} from '@angular/common';

@Component({
  selector: 'gr-tab-toy-stores',
  templateUrl: './tab-toy-stores.component.html',
  imports: [
    CreateOrEditToyStoreComponent,
    ConfirmModalModule,
    NgForOf,
    NgIf
  ],
  standalone: true
})
export class TabToyStoresComponent implements OnChanges {
  @Input() stores: any[] = [];
  storeForEdit: any = null;
  storeForDelete: any = null;
  toyStores: any[] = [];
  constructor(private presentsHttpService: PresentsHttpService,
              private toastService: ToastrService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.toyStores = changes.stores.currentValue;
  }

  onConfirmEditToyStore(store: any): void {
    const storeIndex = this.toyStores.findIndex(item => {
      return store.id === item.id;
    });
    this.toyStores[storeIndex] = store;
    this.storeForEdit = null;
  }

  onConfirmDeleteToyStore(): void {
    this.presentsHttpService.deleteToyStore({storeId: this.storeForDelete.id}).then(res => {
      const storeIndex = this.toyStores.findIndex(item => {
        return this.storeForDelete.id === item.id;
      });
      this.toyStores.splice(storeIndex, 1);
      this.toastService.success('Uspešno ste obrisali prodavnicu.');
      this.storeForDelete = null;
    });
  }
}
