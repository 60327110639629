import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {GeneralHttpHelperService} from '../../helpers/general-http-helper.service';
import {HttpClient} from '@angular/common/http';
import {
  GetPriceListCategoriesApiData,
  GetPriceListCategoriesApiDataFormatted,
  GetPriceListCategoryApiData, GetTermsApiData,
  PostPutPriceListCategoryApiData,
  PostPutPriceListCategoryReqObj
} from './business-profiles-http-interfaces';

const BUSINESS_PROFILE_API_ROUTES = {
  GET_SINGLE_BUSINESS: 'admin/companies/:companyId',
  POST_BUSINESS_PROFILE: 'admin/companies/:companyId',
  PUT_BUSINESS_APPROVE: 'admin/approve/companies/:companyId',
  GET_PRICE_LIST_CATEGORIES: 'admin/companies/:companyId/item-categories',
  GET_PRICE_LIST_CATEGORY: 'admin/companies/:companyId/item-categories/:categoryId',
  POST_PRICE_LIST_CATEGORY: 'admin/companies/:companyId/item-categories',
  PUT_PRICE_LIST_CATEGORY: 'admin/companies/:companyId/item-categories/:categoryId',
  DELETE_PRICE_LIST_CATEGORY: 'admin/companies/:companyId/item-categories/:categoryId',
  GET_PRICE_LIST_ITEMS: 'admin/companies/:companyId/items',
  GET_PRICE_LIST_ITEM: 'admin/companies/:companyId/items/:itemId',
  POST_PRICE_LIST_ITEM: 'admin/companies/:companyId/items',
  PUT_PRICE_LIST_ITEM: 'admin/companies/:companyId/items/:itemId',
  DELETE_PRICE_LIST_ITEM: 'admin/companies/:companyId/items/:itemId',
  POST_TERMS_FIRST_STEP: 'admin/calendar/step2/companies/:companyId',
  POST_MINIMAL_PRICE: 'admin/calendar-price/companies/:companyId',
  POST_DIFFERENT_PRICE: 'admin/calendar-diff-price/companies/:companyId',
  POST_TOGGLE_WORKING_DATE: 'admin/manipulate-work-days/companies/:companyId',
  GET_TERMS_CALENDAR: 'admin/calendar/companies/:companyId',
  GET_FIRST_STEP_TERMS: 'admin/calendar-data/companies/:companyId',
  GET_BUSINESS_CATEGORIES: 'admin/business-categories'
};
@Injectable({
  providedIn: 'root'
})
export class BusinessProfilesHttpService {

  constructor(private generalHttpHelperService: GeneralHttpHelperService,
              private httpClient: HttpClient) { }

  private getBusinessCategoriesReq(): Observable<any> {
    return this.httpClient.get(BUSINESS_PROFILE_API_ROUTES.GET_BUSINESS_CATEGORIES) as any;
  }

  getBusinessCategories(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getBusinessCategoriesReq().subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
  private getSingleBusinessReq(urlParams: any): Observable<any> {
    const url = this.generalHttpHelperService.replaceUrlParamsWithValues(BUSINESS_PROFILE_API_ROUTES.GET_SINGLE_BUSINESS, urlParams);
    return this.httpClient.get(url) as any;
  }

  getSingleBusiness(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getSingleBusinessReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private postBusinessProfileReq(urlParams: any, bodyParams: any): Observable<any> {
    const url = this.generalHttpHelperService.replaceUrlParamsWithValues(BUSINESS_PROFILE_API_ROUTES.POST_BUSINESS_PROFILE, urlParams);
    return this.httpClient.post(url, bodyParams) as any;
  }

  postBusinessProfile(urlParams: any, bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.postBusinessProfileReq(urlParams, bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private putApproveBusinessReq(urlParams: any, approveParam: any): Observable<any> {
    const url = this.generalHttpHelperService.addUrlParams(this.generalHttpHelperService.replaceUrlParamsWithValues(BUSINESS_PROFILE_API_ROUTES.PUT_BUSINESS_APPROVE, urlParams), approveParam);
    return this.httpClient.put(url, {}) as any;
  }

  putApproveBusiness(urlParams: any, approveParam: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.putApproveBusinessReq(urlParams, approveParam).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  // PRICE LIST CATEGORIES START
  private getPriceListCategoriesReq(urlParams: any): Observable<GetPriceListCategoriesApiData> {
    let apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(BUSINESS_PROFILE_API_ROUTES.GET_PRICE_LIST_CATEGORIES, {companyId: urlParams.companyId});
    apiUrl = this.generalHttpHelperService.addUrlParams(apiUrl, {page: urlParams.page});
    return this.httpClient.get(apiUrl) as any;
  }

  getPriceListCategories(urlParams: any): Promise<GetPriceListCategoriesApiDataFormatted> {
    return new Promise<GetPriceListCategoriesApiDataFormatted>((resolve, reject) => {
      this.getPriceListCategoriesReq(urlParams).subscribe((res: GetPriceListCategoriesApiData) => {
        resolve({
          data: res.data.data,
          pagination: this.generalHttpHelperService.returnPagination(res.data)
        });
      }, error => {
        reject(error);
      });
    });
  }

  private getPriceListCategoryReq(urlParams?: Record<string, any>): Observable<GetPriceListCategoryApiData> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(BUSINESS_PROFILE_API_ROUTES.GET_PRICE_LIST_CATEGORY, urlParams);
    return this.httpClient.get(apiUrl) as any;
  }

  getPriceListCategory(urlParams?: Record<string, any>): Promise<GetPriceListCategoryApiData> {
    return new Promise<GetPriceListCategoryApiData>((resolve, reject) => {
      this.getPriceListCategoryReq(urlParams).subscribe((res: GetPriceListCategoryApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private postPriceListCategoryReq(urlParams: any, bodyParams: PostPutPriceListCategoryReqObj): Observable<PostPutPriceListCategoryApiData> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(BUSINESS_PROFILE_API_ROUTES.POST_PRICE_LIST_CATEGORY, urlParams);
    return this.httpClient.post(apiUrl, bodyParams) as any;
  }

  postPriceListCategory(urlParams: any, bodyParams: PostPutPriceListCategoryReqObj): Promise<PostPutPriceListCategoryApiData> {
    return new Promise<any>((resolve, reject) => {
      this.postPriceListCategoryReq(urlParams, bodyParams).subscribe((res: PostPutPriceListCategoryApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private putPriceListCategoryReq(urlParam: Record<string, any>, bodyParams: PostPutPriceListCategoryReqObj): Observable<PostPutPriceListCategoryApiData> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(BUSINESS_PROFILE_API_ROUTES.PUT_PRICE_LIST_CATEGORY, urlParam);
    return this.httpClient.put(apiUrl, bodyParams) as any;
  }

  putPriceListCategory(urlParam: Record<string, any>, bodyParams: PostPutPriceListCategoryReqObj): Promise<PostPutPriceListCategoryApiData> {
    return new Promise<any>((resolve, reject) => {
      this.putPriceListCategoryReq(urlParam, bodyParams).subscribe((res: PostPutPriceListCategoryApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private deletePriceListCategoryReq(urlParam: Record<string, any>): Observable<PostPutPriceListCategoryApiData> {
    const url = this.generalHttpHelperService.replaceUrlParamsWithValues(BUSINESS_PROFILE_API_ROUTES.DELETE_PRICE_LIST_CATEGORY, urlParam);
    return this.httpClient.delete(url) as any;
  }

  deletePriceListCategory(urlParam: Record<string, any>): Promise<PostPutPriceListCategoryApiData> {
    return new Promise<any>((resolve, reject) => {
      this.deletePriceListCategoryReq(urlParam).subscribe((res: PostPutPriceListCategoryApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
  // PRICE LIST CATEGORIES END

  // PRICE LIST ITEMS START
  private getPriceListItemsReq(urlParams: any): Observable<any> {
    let apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(BUSINESS_PROFILE_API_ROUTES.GET_PRICE_LIST_ITEMS, {companyId: urlParams.companyId});
    apiUrl = this.generalHttpHelperService.addUrlParams(apiUrl, {page: urlParams.page});
    return this.httpClient.get(apiUrl) as any;
  }

  getPriceListItems(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getPriceListItemsReq(urlParams).subscribe((res: any) => {
        resolve({
          data: res.data.data,
          pagination: this.generalHttpHelperService.returnPagination(res.data)
        });
      }, error => {
        reject(error);
      });
    });
  }

  private getPriceListItemReq(urlParams?: Record<string, any>): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(BUSINESS_PROFILE_API_ROUTES.GET_PRICE_LIST_ITEM, urlParams);
    return this.httpClient.get(apiUrl) as any;
  }

  getPriceListItem(urlParams?: Record<string, any>): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getPriceListItemReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private postPriceListItemReq(urlParams: any, bodyParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(BUSINESS_PROFILE_API_ROUTES.POST_PRICE_LIST_ITEM, urlParams);
    return this.httpClient.post(apiUrl, bodyParams) as any;
  }

  postPriceListItem(urlParams: any, bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.postPriceListItemReq(urlParams, bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private putPriceListItemReq(urlParam: Record<string, any>, bodyParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(BUSINESS_PROFILE_API_ROUTES.PUT_PRICE_LIST_ITEM, urlParam);
    return this.httpClient.post(apiUrl, bodyParams) as any;
  }

  putPriceListItem(urlParam: Record<string, any>, bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.putPriceListItemReq(urlParam, bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private deletePriceListItemReq(urlParam: Record<string, any>): Observable<any> {
    const url = this.generalHttpHelperService.replaceUrlParamsWithValues(BUSINESS_PROFILE_API_ROUTES.DELETE_PRICE_LIST_ITEM, urlParam);
    return this.httpClient.delete(url) as any;
  }

  deletePriceListItem(urlParam: Record<string, any>): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.deletePriceListItemReq(urlParam).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
  // PRICE LIST ITEMS END

  // TERMS START
  private postTermsFirstStepReq(urlParams: any, bodyParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(BUSINESS_PROFILE_API_ROUTES.POST_TERMS_FIRST_STEP, urlParams);
    return this.httpClient.post(apiUrl, bodyParams) as any;
  }

  postTermsFirstStep(urlParams: any, bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.postTermsFirstStepReq(urlParams, bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private postMinimalPriceReq(urlParams: any, bodyParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(BUSINESS_PROFILE_API_ROUTES.POST_MINIMAL_PRICE, urlParams);
    return this.httpClient.post(apiUrl, bodyParams) as any;
  }

  postMinimalPrice(urlParams: any, bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.postMinimalPriceReq(urlParams, bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private postDifferentPriceReq(urlParams: any, bodyParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(BUSINESS_PROFILE_API_ROUTES.POST_DIFFERENT_PRICE, urlParams);
    return this.httpClient.post(apiUrl, bodyParams) as any;
  }

  postDifferentPrice(urlParams: any, bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.postDifferentPriceReq(urlParams, bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private postToggleWorkingDateReq(urlParams: any, bodyParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(BUSINESS_PROFILE_API_ROUTES.POST_TOGGLE_WORKING_DATE, urlParams);
    return this.httpClient.post(apiUrl, bodyParams) as any;
  }

  postToggleWorkingDate(urlParams: any, bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.postToggleWorkingDateReq(urlParams, bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private getFirstStepTermsDataReq(urlParams: any): Observable<GetTermsApiData> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(BUSINESS_PROFILE_API_ROUTES.GET_FIRST_STEP_TERMS, urlParams);
    return this.httpClient.get(apiUrl) as any;
  }

  getFirstStepTermsData(urlParams: any): Promise<GetTermsApiData> {
    return new Promise<GetTermsApiData>((resolve, reject) => {
      this.getFirstStepTermsDataReq(urlParams).subscribe((res: GetTermsApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private getCalendarTermsReq(urlParams: any): Observable<any> {
    const {companyId, ...rest} = urlParams;
    let apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(BUSINESS_PROFILE_API_ROUTES.GET_TERMS_CALENDAR, {companyId});
    apiUrl = this.generalHttpHelperService.addUrlParams(apiUrl, rest)
    return this.httpClient.get(apiUrl) as any;
  }

  getCalendarTerms(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getCalendarTermsReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
  // TERMS END
}
