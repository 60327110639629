<div class="wrapper-page faq-page">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div class="wrapper-content-page">
    <div class="wrapper-header-page-content">
      <i [routerLink]="[PAGES_AND_PATHS.businessProfile.pagePath, urlParams.companyId]" class="fa-solid fa-arrow-left back-btn"></i>
      <span class="header-text">{{categoryData?.name}}</span>
      <button class="zc-btn zc-btn--primary" [routerLink]="['/business-profile', urlParams.companyId, 'price-list-category', urlParams.categoryId, 'item-create']"><span>Kreiraj novi arikal</span></button>
    </div>
    <div class="wrapper-content-page-list-items grid">
      <div class="grid__m-4" *ngFor="let item of categoryData?.items">
        <div class="card-item-category">
          <div class="wrapper-preview-image-item">
            <div class="first-image">
              <img [src]="item.images[0].full_path" alt="">
            </div>
            <div class="other-image-wrapper">
              <img *ngFor="let image of item.images | slice: 1" [src]="image?.full_path" alt="">
            </div>
          </div>
          <div class="content-item-category">
            <span class="name-item-category">{{item.name}}</span>
            <span class="description-item-category">{{item.description}}</span>
          </div>
          <div class="price-kg">
            <span *ngIf="item.unit" class="label-price">Cena po {{item.unit}}</span>
            <span class="value-price">{{item.price_with_percentage}} RSD</span>
          </div>
          <div class="item-buttons">
            <div class="item-btn" [routerLink]="['/business-profile', urlParams.companyId, 'price-list-category', urlParams.categoryId, 'item', item.id]">
              <i class="fa-solid fa-pen-to-square"></i>
            </div>
            <div class="item-btn" (click)="priceListItemForDelete = item">
              <i class="fa-solid fa-trash"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <gr-confirm-modal
    *ngIf="priceListItemForDelete !== null"
    [modalTitle]="'Da li ste sigurni da zelite da obrisete artikl?'"
    (closeModal)="priceListItemForDelete = null"
    (confirmModal)="onConfirmDeletePriceListItem()"
  ></gr-confirm-modal>
</div>
