import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {DEFAULT_AUTHENTICATED_PAGE} from '../constants/pages-and-paths';
import {AuthenticationHelperService} from '../services/helpers/authentication-helper.service';
import {ActivePageHelperService} from '../services/helpers/active-page-helper.service';


@Injectable()
export class OuterPagesGuardService {

  constructor(public authenticationService: AuthenticationHelperService,
              private router: Router,
              private activePageHelper: ActivePageHelperService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean {
    if (route.data.data) {
      this.activePageHelper.activePage = route.data.data;
      if (this.authenticationService.isAuthenticated &&
        !route.data.data.needAuthentication) {
        this.router.navigate([DEFAULT_AUTHENTICATED_PAGE.page.pagePath]);
        return false;
      }
    }
    return true;
  }

}
