import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'handlePageTitlePipe',
})
export class HandlePageTitlePipe implements PipeTransform {
  transform(value: string): string {
    return value === '1'
      ? 'Uslovi Korišćenja'
      : value === '2'
        ? 'Politika Privatnosti'
        : 'O Nama';
  }
}
