import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BusinessProfilesHttpService} from '../../../../services/http-services/business-profiles/business-profiles-http.service';
import {DatePipe} from '@angular/common';
import {DaysOfWeek} from '../../../../constants/general-variables';

@Component({
  selector: 'gr-change-work-terms-modal',
  templateUrl: './change-work-terms-modal.component.html',
})
export class ChangeWorkTermsModalComponent implements OnInit{
  workingDateForm!: FormGroup;
  initialWorkState = false;
  dayOfWeek!: number;
  @Input() urlParams: any;
  @Input() selectedTermsDate: any = null;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmModal: EventEmitter<void> = new EventEmitter<void>();
  protected readonly DaysOfWeek = DaysOfWeek;

  constructor(private formBuilder: FormBuilder,
              private datePipe: DatePipe,
              private businessProfilesHttpService: BusinessProfilesHttpService) {
  }

  ngOnInit() {
    const date = new Date(this.selectedTermsDate[0].start);
    this.dayOfWeek = date.getUTCDay() - 1;
    this.initialWorkState = !!this.selectedTermsDate[0].work;
    this.buildForm();
  }

  buildForm(): void {
    this.workingDateForm = this.formBuilder.group({
      day: ['', Validators.required],
      work: [false],
    });
    this.workingDateForm.controls.day.patchValue(this.datePipe.transform(this.selectedTermsDate[0].start, 'yyyy-MM-dd'));
    this.workingDateForm.controls.work.patchValue(!!this.selectedTermsDate[0].work);
  }

  onSubmitForm(): void {
    this.businessProfilesHttpService.postToggleWorkingDate(this.urlParams, this.workingDateForm.value).then(res => {
      this.confirmModal.emit();
    });
  }
}
