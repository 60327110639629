<div class="wrapper-modal banners">
  <form [formGroup]="createOrEditBannerForm" (ngSubmit)="submitCreateOrEditBannerForm()" class="wrapper-content-modal">
    <div class="header-modal">
      <span class="title-modal">{{bannerForEdit ? 'Izmeni baner' : 'Kreiraj novi baner'}}</span>
    </div>
    <div class="content-modal">
      <div class="xs-mb-20">
        <div class="label-banner">
          <span>Izaberite status</span>
        </div>
        <div class="wrapper-custom-radiobutton">
          <label>
            <input type="radio" formControlName="active" [value]="true">
            <div class="custom-radiobutton">
              <div class="circle-radio">
                <div class="circle-center"></div>
              </div>
              <span>Aktivan</span>
            </div>
          </label>
          <label>
            <input type="radio" formControlName="active" [value]="false">
            <div class="custom-radiobutton">
              <div class="circle-radio">
                <div class="circle-center"></div>
              </div>
              <span>Neaktivan</span>
            </div>
          </label>
        </div>
      </div>
      <div class="xs-mb-20">
        <div class="label-banner">
          <span>Ubacite sliku banera</span>
        </div>
        <div class="wrapper-custom-gallery banners">
          <div *ngIf="!imageUrl" class="header-custom-gallery">
            <div class="wrapper-btn-gallery">
              <div class="file-input">
                <label class="file-input__label2">
                  <span>Dodaj Sliku</span>
                  <input #fileInput class="file-input__input" type="file" accept=".jpg,.jpeg,.png,.svg" (change)="onFileChange($event)"/>
                </label>
              </div>
            </div>
            <span class="error-message">{{createOrEditBannerForm.controls.image | formControlErrorMessages: null : formTriedToSubmit}}</span>
          </div>
          <div *ngIf="imageUrl" class="content-custom-gallery-banner">
            <div class="wrapper-gallery-image-banner">
              <img [src]="imageUrl" alt="banner-image">
              <div class="overlay-delete" (click)="removeFile()">
                <i class="fa-solid fa-xmark"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="xs-mb-20">
        <div class="label-banner">
          <span>Izaberite tip linka</span>
        </div>
        <div class="wrapper-custom-radiobutton">
          <label>
            <input type="radio" formControlName="link_type" [value]="'company'">
            <div class="custom-radiobutton">
              <div class="circle-radio">
                <div class="circle-center"></div>
              </div>
              <span>Profil kompanije</span>
            </div>
          </label>
          <label>
            <input type="radio" formControlName="link_type" [value]="'category'">
            <div class="custom-radiobutton">
              <div class="circle-radio">
                <div class="circle-center"></div>
              </div>
              <span>Kategorija</span>
            </div>
          </label>
          <label>
            <input type="radio" formControlName="link_type" [value]="'external'">
            <div class="custom-radiobutton">
              <div class="circle-radio">
                <div class="circle-center"></div>
              </div>
              <span>Externi link</span>
            </div>
          </label>
        </div>
      </div>
      <div class="xs-mb-20">
        <div class="label-banner">
          <span>{{createOrEditBannerForm.get('link_type')?.value === 'company' ? 'Unesite profil kompanije' : 'Dodajte link'}}</span>
        </div>
        <div class="wrapper-input">
          <div class="zc-input">
            <input type="text" placeholder="Unesite naziv" [ngModelOptions]="{standalone: true}"
                   [(ngModel)]="searchTerm" (ngModelChange)="autocompleteSubject.next($event)">
          </div>
          <div class="wrapper-dropdown-link">
            <div class="dropdown-link" *ngIf="autocompleteLinkSuggestions.length">
              <div class="item-dropdown-link" *ngFor="let suggestion of autocompleteLinkSuggestions" (click)="onSelectSuggestionLink(suggestion)"><span>{{suggestion.name}}</span></div>
            </div>
          </div>
          <span class="error-message">{{createOrEditBannerForm.controls.link | formControlErrorMessages: 'Unesite validan link' : formTriedToSubmit}}</span>
        </div>
      </div>
      <div class="xs-mb-20">
        <div class="label-banner">
          <span>Izaberite poziciju banera</span>
        </div>
        <div class="wrapper-custom-radiobutton">
          <label>
            <input type="radio" formControlName="position" [value]="1">
            <div class="custom-radiobutton">
              <div class="circle-radio">
                <div class="circle-center"></div>
              </div>
              <span>Pozocija 1</span>
            </div>
          </label>
          <label>
            <input type="radio" formControlName="position" [value]="2">
            <div class="custom-radiobutton">
              <div class="circle-radio">
                <div class="circle-center"></div>
              </div>
              <span>Pozicija 2</span>
            </div>
          </label>
        </div>
      </div>
    </div>
    <div class="footer-modal">
      <button type="button" (click)="closeModal.emit()" class="zc-btn zc-btn--gray"><span>Izađi</span></button>
      <button type="submit" class="zc-btn zc-btn--primary"><span>{{bannerForEdit ? 'Izmeni' : 'Dodaj'}}</span></button>
    </div>
  </form>
</div>


