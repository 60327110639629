import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GrPagination} from '../../interfaces/general-interfaces';

@Component({
  selector: 'gr-pagination',
  templateUrl: './pagination.component.html',
  styles: [
  ]
})
export class PaginationComponent implements OnInit, GrPagination {
  @Input() totalItems: number | undefined;
  @Input() currentPage: number | undefined;
  @Input() perPage: number | undefined;
  @Input() collection: any[] = [];
  @Input() from: number | undefined;
  @Input() to: number | undefined;
  @Input() pagId!: string;
  @Input() paginationClass?: string;
  @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();
  nextPageUrl: string | undefined;
  lastPage!: number;

  constructor() {
  }

  ngOnInit(): void {
  }

  onPageChanged(page: number): void {
    this.pageChanged.emit(page);
  }

}
