import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {PresentsHttpService} from '../../../../services/http-services/presents/presents-http.service';
import {FormControlErrorMessagesModule} from '../../../../pipes/form-control-error-messages/form-control-error-messages.module';
import {NgIf} from '@angular/common';

@Component({
  selector: 'gr-create-or-edit-toy-store',
  templateUrl: './create-or-edit-toy-store.component.html',
  imports: [
    FormControlErrorMessagesModule,
    ReactiveFormsModule,
    NgIf
  ],
  standalone: true
})
export class CreateOrEditToyStoreComponent {
  formTriedToSubmit = false;
  createOrEditStoreForm!: FormGroup;
  imageUrl = '';
  @Input() storeForEdit: any;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmCreateStore: EventEmitter<any> = new EventEmitter<any>();
  @Output() confirmEditStore: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  constructor(private formBuilder: FormBuilder,
              private toastService: ToastrService,
              private presentsHttpService: PresentsHttpService) {
  }
  ngOnInit(): void {
    this.buildForm();
  }
  buildForm(): void {
    this.createOrEditStoreForm = this.formBuilder.group({
      name: ['', Validators.required],
      rebate: ['', Validators.required],
      image: ['', Validators.required],
    });

    if (this.storeForEdit) {
      this.createOrEditStoreForm.patchValue(this.storeForEdit);
      this.createOrEditStoreForm.controls.image.patchValue('not changed');
      this.imageUrl = this.storeForEdit?.image.full_path;
    }
  }

  onFileChange(event: any): void {
    const file = event.target.files[0];
    if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/svg') {
      this.toastService.warning('Pogrešan tip dokumenta. Samo JPG, JPEG, SVG i PNG formati su dozvoljeni.');
      return;
    } else if (file?.size > 2097152) {
      this.toastService.warning(`Slika ${file.name} je veća od 2MB.`);
      return;
    } else {
      this.createOrEditStoreForm.controls.image.patchValue(event.target.files[0]);
      this.imageUrl = URL.createObjectURL(file);
      this.fileInput.nativeElement.value = '';
    }
  }

  removeFile(): void {
    this.createOrEditStoreForm.controls.image.patchValue(null);
    this.imageUrl = '';
  }

  onSubmitStoreForm(): void {
    this.formTriedToSubmit = true;
    if (this.createOrEditStoreForm.valid) {
      const formData = new FormData();
      for (const key in this.createOrEditStoreForm.value) {
        if (this.createOrEditStoreForm.value[key] !== 'not changed') {
          formData.append(key, this.createOrEditStoreForm.value[key]);
        }
      }
      this.storeForEdit
        ? this.presentsHttpService.putToyStore({storeId: this.storeForEdit.id}, formData).then((res: any) => {
          this.toastService.success('Uspešno ste izmanili prodavnicu.');
          this.confirmEditStore.emit(res.data);
          this.formTriedToSubmit = false;
        })
        : this.presentsHttpService.postToyStore(formData).then((res: any) => {
          this.toastService.success('Uspešno ste dodali prodavnicu.');
          this.confirmCreateStore.emit(res.data);
          this.formTriedToSubmit = false;
        });
    }
  }
}
