import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {GetPageApiData, PutPageApiData, PutPageReqObj} from './pages-http-interfaces';
import {GeneralHttpHelperService} from '../../helpers/general-http-helper.service';

const PAGES_API_ROUTES = {
  GET_SINGLE_PAGES: 'pages/:pageId',
  PUT_PAGE: 'admin/pages/:pageId',
};

@Injectable({
  providedIn: 'root'
})
export class PagesHttpService {
  constructor(private httpClient: HttpClient,
              private generalHttpHelperService: GeneralHttpHelperService) { }
  private getSinglePageReq(urlParams: Record<string, string | number>): Observable<GetPageApiData> {
    const url = this.generalHttpHelperService.replaceUrlParamsWithValues(PAGES_API_ROUTES.GET_SINGLE_PAGES, urlParams)
    return this.httpClient.get(url) as any;
  }

  getSinglePage(urlParams: Record<string, string | number>): Promise<GetPageApiData> {
    return new Promise<any>((resolve, reject) => {
      this.getSinglePageReq(urlParams).subscribe((res: GetPageApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private putPageReq(urlParam: Record<string, any>, bodyParams: PutPageReqObj): Observable<PutPageApiData> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(PAGES_API_ROUTES.PUT_PAGE, urlParam);
    return this.httpClient.put(apiUrl, bodyParams) as any;
  }

  putPage(urlParam: Record<string, any>, bodyParams: PutPageReqObj): Promise<PutPageApiData> {
    return new Promise<any>((resolve, reject) => {
      this.putPageReq(urlParam, bodyParams).subscribe((res: PutPageApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
}
