import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'returnGender',
})
export class ReturnGenderPipe implements PipeTransform {

  transform(value: string): string {
    return value === 'universal'
      ? 'Dečaci, Devojċice'
      : value === 'male'
        ? 'Dečaci'
        : 'Devojċice'
  }

}
