<div class="wrapper-modal confirm-modal">
  <div class="wrapper-content-modal">
    <div class="header-modal">
      <span class="title-modal">{{modalTitle}}</span>
    </div>
    <div class="content-modal">

    </div>
    <div class="footer-modal">
      <button type="button" (click)="closeModal.emit()" class="zc-btn zc-btn--gray"><span>Izađi</span></button>
      <button type="button" (click)="confirmModal.emit()" class="zc-btn zc-btn--delete xs-ml-10"><span>Obriši</span></button>
    </div>
  </div>

</div>
