import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {GeneralHttpHelperService} from '../../helpers/general-http-helper.service';
import {HttpClient} from '@angular/common/http';
import {
  GetUsersApiData,
  GetUsersApiDataFormatted,
  GetUsersUrlParams,
  UserProfileApiData,
  PutUserPasswordApiData,
  PutUserPasswordReqObj,
  PostPutUserProfileReqObj, PutUserUrlParams
} from './users-http-interfaces';

const USERS_API_ROUTES = {
  GET_USERS: 'admin/users',
  POST_USER: 'admin/users',
  PUT_USER: 'admin/users/:userId',
  DELETE_USER: 'admin/users/:userId',
  PUT_AUTH_USER_PASSWORD: 'users/update-password',
  PUT_AUTH_USER_PROFILE: 'users',
};
@Injectable({
  providedIn: 'root'
})
export class UsersHttpService {

  constructor(private generalHttpHelperService: GeneralHttpHelperService,
              private httpClient: HttpClient) { }
  private getUsersReq(urlParams: GetUsersUrlParams): Observable<GetUsersApiData> {
    const url = this.generalHttpHelperService.addUrlParams(USERS_API_ROUTES.GET_USERS, urlParams);
    return this.httpClient.get(url) as any;
  }

  getUsers(urlParams: GetUsersUrlParams): Promise<GetUsersApiDataFormatted> {
    return new Promise<GetUsersApiDataFormatted>((resolve, reject) => {
      this.getUsersReq(urlParams).subscribe((res: GetUsersApiData) => {
        resolve(
          {
            data: res.data.data,
            pagination: this.generalHttpHelperService.returnPagination(res.data)
          }
        );
      }, error => {
        reject(error);
      });
    });
  }

  private postUserReq(bodyParams: PostPutUserProfileReqObj): Observable<UserProfileApiData> {
    return this.httpClient.post(USERS_API_ROUTES.POST_USER, bodyParams) as any;
  }

  postUser(bodyParams: PostPutUserProfileReqObj): Promise<UserProfileApiData> {
    return new Promise<UserProfileApiData>((resolve, reject) => {
      this.postUserReq(bodyParams).subscribe((res: UserProfileApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private putUserReq(urlParams: PutUserUrlParams, bodyParams: PostPutUserProfileReqObj): Observable<UserProfileApiData> {
    const url = this.generalHttpHelperService.replaceUrlParamsWithValues(USERS_API_ROUTES.PUT_USER, urlParams);
    return this.httpClient.put(url, bodyParams) as any;
  }

  putUser(urlParams: PutUserUrlParams, bodyParams: PostPutUserProfileReqObj): Promise<UserProfileApiData> {
    return new Promise<UserProfileApiData>((resolve, reject) => {
      this.putUserReq(urlParams, bodyParams).subscribe((res: UserProfileApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private deleteUserReq(urlParams: Record<string, number>): Observable<UserProfileApiData> {
    const url = this.generalHttpHelperService.replaceUrlParamsWithValues(USERS_API_ROUTES.DELETE_USER, urlParams);
    return this.httpClient.delete(url) as any;
  }

  deleteUser(urlParams: Record<string, number>): Promise<UserProfileApiData> {
    return new Promise<UserProfileApiData>((resolve, reject) => {
      this.deleteUserReq(urlParams).subscribe((res: UserProfileApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }


  private putAuthUserReq(bodyParams: PostPutUserProfileReqObj): Observable<UserProfileApiData> {
    return this.httpClient.put(USERS_API_ROUTES.PUT_AUTH_USER_PROFILE, bodyParams) as any;
  }

  putAuthUser(bodyParams: PostPutUserProfileReqObj): Promise<UserProfileApiData> {
    return new Promise<UserProfileApiData>((resolve, reject) => {
      this.putAuthUserReq(bodyParams).subscribe((res: UserProfileApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private putAuthUserPasswordReq(bodyParams: PutUserPasswordReqObj): Observable<PutUserPasswordApiData> {
    return this.httpClient.put(USERS_API_ROUTES.PUT_AUTH_USER_PASSWORD, bodyParams) as any;
  }
  putAuthUserPassword(bodyParams: PutUserPasswordReqObj): Promise<PutUserPasswordApiData> {
    return new Promise<UserProfileApiData>((resolve, reject) => {
      this.putAuthUserPasswordReq(bodyParams).subscribe((res: PutUserPasswordApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
}
