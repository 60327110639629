import { Component } from '@angular/core';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';
import {AuthenticationHelperService} from '../../services/helpers/authentication-helper.service';

@Component({
  selector: 'gr-side-menu',
  templateUrl: './side-menu.component.html',
})
export class SideMenuComponent {
  isPagesDropdownOpen = false;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(public authHelper: AuthenticationHelperService) {
  }

  handleOpenPagesDropdown(event: any): void {
    this.isPagesDropdownOpen = !this.isPagesDropdownOpen;
  }
}
