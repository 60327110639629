<div class="wrapper-modal">
  <div class="wrapper-content-modal">
    <form [formGroup]="workingDateForm" (ngSubmit)="onSubmitForm()" class="wrapper-open-day">
      <div class="title-day">
        <span class="day">{{DaysOfWeek[dayOfWeek].dayForView}}</span>
        <span>{{selectedTermsDate[0].start | date: 'dd.MM.yyyy'}}</span>
      </div>
      <div class="wrapper-checkbox-custom">
        <div>
          <input class="checkbox-custom" type="checkbox" id="checkbox14" formControlName="work">
          <label class="checkbox-custom-label" for="checkbox14">Igraonica je otvorena</label>
        </div>
      </div>
      <div class="terms-title xs-mt-32">Termini</div>
      <div class="wrapper-time-terms">
        <div class="time-term free">
          <span>Slobodni</span>
        </div>
        <div class="time-term unconfirmed">
          <span>Nepotvrđeni</span>
        </div>
        <div class="time-term busy">
          <span>Zauzeti</span>
        </div>
      </div>
      <div class="wrapper-time-terms">
        <!--              &lt;!&ndash;       TODO JS - dodajes klasu "free" i dodajes klasu "not-allowed" e sad ovo cekamo dragana da odg       &ndash;&gt;-->
        <div *ngFor="let terms of selectedTermsDate" class="time-term" [ngClass]="{'free': !terms.reserved}">
          <span>{{terms.start_time | slice: 0: -3}} - {{terms.end_time | slice: 0: -3}}</span>
        </div>
      </div>
      <!--  <div class="price-total">-->
      <!--    <span class="label">Cena</span>-->
      <!--    <span class="value">50.000 RSD</span>-->
      <!--  </div>-->
      <div class="wrapper-btn-open-day">
        <button class="zc-btn zc-btn--gray" type="button" (click)="closeModal.emit()"><span>OTKAŽI</span></button>
        <button
          type="submit"
          [disabled]="initialWorkState === workingDateForm.controls.work.value"
          class="zc-btn zc-btn--primary"
          [ngClass]="{'not-allowed': initialWorkState === workingDateForm.controls.work.value}"><span>POTVRDI IZMENE</span>
        </button>
      </div>
    </form>
  </div>
</div>
