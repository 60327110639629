import {Component, ElementRef, forwardRef, Input, OnChanges, Renderer2, SimpleChanges, ViewChild} from '@angular/core';
import {UiHelperService} from '../../../services/helpers/ui-helper.service';
import {FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'gr-dropdown-reactive-form',
  templateUrl: './dropdown-reactive-form.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownReactiveFormComponent),
      multi: true,
    }
  ]
})
export class DropdownReactiveFormComponent implements OnChanges {
  @Input() items: any[] = [];
  @Input() propForView = 'name';
  @Input() propIdentifier: string | number = '';
  @Input() disableSelect = false;
  @Input() dropdownLabel = '';
  @Input() placeholder = '';
  @Input() formControl!: FormControl;
  @Input() formTriedToSubmit = false;
  @Input() isMultiSelect = false;
  @ViewChild('dropdownBtn') dropdownBtn: ElementRef | undefined;
  isDropdownOpen = false;
  selectedItem: any;
  selectedItems: any[] = [];
  value: any;

  constructor(private renderer: Renderer2,
              private uiHelperService: UiHelperService) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.listenToClickDropdown();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items && changes.items.currentValue.length) {
      this.writeValue(this.value);
    }
  }

  onChange: (value: string) => void = () => {};
  onTouched: () => void = () => {};

  writeValue(value: any): void {
    this.value = value;
    this.isMultiSelect
      ? this.selectedItems = this.items.filter(option => value?.includes(option[this.propIdentifier]))
      : this.selectedItem = this.items.find(option => option[this.propIdentifier] === value) || '';
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}


  toggleDropdown(event: Event): void {
    event.stopImmediatePropagation();
    if (!this.disableSelect) {
      this.isDropdownOpen = !this.isDropdownOpen;
    }
  }

  listenToClickDropdown(): void {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.uiHelperService.checkIfClickedOutsideOfTheElement(this.dropdownBtn, this.dropdownBtn, e)) {
        this.isDropdownOpen = false;
      }
    });
  }

  pushOrRemoveItemFromArray(item: any): void {
    if (this.selectedItems.some((element: any) => element[this.propIdentifier] === item[this.propIdentifier])) {
      this.selectedItems = this.selectedItems.filter((element: any) => element[this.propIdentifier] !== item[this.propIdentifier]);
      this.value = this.value.filter((element: any) => element !== item[this.propIdentifier]);
    } else {
      this.selectedItems.push(item);
      this.value.push(item[this.propIdentifier]);
    }
  }

  onSelectItem(event: any, item: any): void {
    event.stopPropagation();
    this.isDropdownOpen = false;
    if(this.isMultiSelect) {
      this.pushOrRemoveItemFromArray(item)
    } else {
      this.value = item[this.propIdentifier];
      this.selectedItem = item;
    }
    this.onChange(this.value);
    this.onTouched();
  }
}
