import {Component, OnInit} from '@angular/core';
import {PresentsHttpService} from '../../../services/http-services/presents/presents-http.service';
import {ActivatedRoute} from '@angular/router';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';

@Component({
  selector: 'app-single-present',
  templateUrl: './single-present.component.html',
})
export class SinglePresentComponent implements OnInit {
  present: any;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(private presentsHttpService: PresentsHttpService,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(res => {
      if (res.id) {
        this.presentsHttpService.getSinglePresent({presentId: res.id}).then(presentRes => {
          this.present = presentRes.data;
        });
      }
    });
  }
}
