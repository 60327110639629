<div class="wrapper-login-page">
  <div class="content-login-left">
    <div class="overlay-login-image"></div>
  </div>
  <div class="content-login-right">
    <div class="content-login">
      <img src="../../../assets/images/logo.svg" alt="">
      <span class="title">Promenite lozinka</span>
      <span class="subtitle">Unesite vašu novu lozinku</span>
      <form [formGroup]="resetPasswordForm" (ngSubmit)="submitResetPasswordForm()">
        <div class="wrapper-input">
          <span class="label-input">Lozinka</span>
          <div class="zc-input">
            <input [type]="isPasswordShow ? 'text' : 'password'" [formControl]="resetPasswordForm.controls.password | formControl" [placeholder]="'********'">
            <i *ngIf="!isPasswordShow" (click)="isPasswordShow = true" class="fa-solid fa-eye"></i>
            <i *ngIf="isPasswordShow" (click)="isPasswordShow = false" class="fa-solid fa-eye-slash"></i>
          </div>
          <span class="error-message">{{resetPasswordForm.controls['password'] | formControlErrorMessages: 'Minimum 8 karaktera, jedno veliko slovo, broj i jedan specijalni karakter.' : formTriedToSubmit}}</span>
        </div>
        <div class="wrapper-input">
          <span class="label-input">Potvrdite lozinku</span>
          <div class="zc-input">
            <input [type]="isConfirmPasswordShow ? 'text' : 'password'" [formControl]="resetPasswordForm.controls.password_confirmation | formControl" [placeholder]="'********'">
            <i *ngIf="!isConfirmPasswordShow" (click)="isConfirmPasswordShow = true" class="fa-solid fa-eye"></i>
            <i *ngIf="isConfirmPasswordShow" (click)="isConfirmPasswordShow = false" class="fa-solid fa-eye-slash"></i>
          </div>
          <span class="error-message">{{resetPasswordForm.controls['password_confirmation'] | formControlErrorMessages: 'Minimum 8 karaktera, jedno veliko slovo, broj i jedan specijalni karakter.' : formTriedToSubmit}}</span>
        </div>
        <a class="zc-link-label" href="javascript:void(0)" [routerLink]="[PAGES_AND_PATHS.login.pagePath]">Prijavite se</a>
        <button class="zc-btn zc-btn--primary" type="submit"><span>Resetuj lozinku</span></button>
      </form>
    </div>
  </div>
</div>
