import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ForgotPasswordApiData, ForgotPasswordReqObj, LoginApiData, LoginReqObj} from './autentication-http-interfaces';
import {GeneralHttpHelperService} from '../../helpers/general-http-helper.service';

const AUTH_ROUTES = {
  LOGIN: 'auth/admin/login',
  FORGOT_PASSWORD: 'auth/forgot-password',
  RESET_PASSWORD: 'auth/reset-password'
}
@Injectable({
  providedIn: 'root'
})
export class AuthenticationHttpService {
  constructor(private httpClient: HttpClient,
              private generalHttpHelperService: GeneralHttpHelperService) { }

  private loginReq(bodyParams: LoginReqObj): Observable<LoginApiData> {
    return this.httpClient.post(AUTH_ROUTES.LOGIN, bodyParams) as any;
  }

  login(bodyParams: LoginReqObj): Promise<LoginApiData> {
    return new Promise<any>((resolve, reject) => {
      this.loginReq(bodyParams).subscribe((res: LoginApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private forgotPasswordReq(urlParams: ForgotPasswordReqObj): Observable<ForgotPasswordApiData> {
    const url = this.generalHttpHelperService.addUrlParams(AUTH_ROUTES.FORGOT_PASSWORD, urlParams)
    return this.httpClient.get(url) as any;
  }

  forgotPassword(urlParams: ForgotPasswordReqObj): Promise<ForgotPasswordApiData> {
    return new Promise<ForgotPasswordApiData>((resolve, reject) => {
      this.forgotPasswordReq(urlParams).subscribe((res: ForgotPasswordApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }


  private resetPasswordReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(AUTH_ROUTES.RESET_PASSWORD, bodyParams) as any;
  }

  resetPassword(bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.resetPasswordReq(bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
}
