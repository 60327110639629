import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {LocalStorageProperties} from '../../enums/general-enums';
import {GrUser} from '../../interfaces/general-interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationHelperService {
  private isUserAuthenticated = false;
  private userData: GrUser | null = null;
  private token = '';

  constructor(private router: Router) {
    this.checkIfUserIsLogged();
  }

  get isAuthenticated(): boolean {
    return this.isUserAuthenticated;
  }

  get getToken(): string {
    return this.token;
  }

  get getUserData(): any {
    return this.userData;
  }

  clientLogout(redirectionUrl: string): void {
    this.deleteClientInfoFromStorage();
    this.router.navigate([redirectionUrl]);
  }

  setUserData(newData: any): void {
    this.userData = newData;
    localStorage.setItem(LocalStorageProperties.user, JSON.stringify(this.userData));
  }

  clientLogin(user: any, token: string, redirectionUrl: string): void {
    this.setUserData(user);
    this.setToken(token);
    this.isUserAuthenticated = true;
    if (redirectionUrl) {
      setTimeout(() => {
        this.router.navigate([redirectionUrl]);
      });
    }
  }

  private setToken(newData: any): void {
    this.token = newData;
    localStorage.setItem(LocalStorageProperties.token, JSON.stringify(this.token));
  }

  private deleteClientInfoFromStorage(): void {
    localStorage.removeItem(LocalStorageProperties.user);
    localStorage.removeItem(LocalStorageProperties.token);
    this.isUserAuthenticated = false;
  }

  // If user token is still active, set user data
  private checkIfUserIsLogged(): void {
    let user: any;
    let token: any;
    if (localStorage.getItem(LocalStorageProperties.user) !== 'undefined') {
      user = JSON.parse(localStorage.getItem(LocalStorageProperties.user) as string);
    }
    if (localStorage.getItem(LocalStorageProperties.token) !== 'undefined') {
      token = JSON.parse(localStorage.getItem(LocalStorageProperties.token) as string);
    }
    if (user && !this.isUserAuthenticated) {
      this.clientLogin(user, token, '');
    }
  }
}
