export const PasswordRegex = '^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}|:"<>?`\\-=[\\]\\\\;\',./]).{8,}$';
export const EmailRegex = '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$';
export const PhoneRegex = '\d+$';
export const MobileRegex = '^06\\d{7,8}$';
export const UrlRegex = /https?:\/\/(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?/;
export const PibRegex = '\\b\\d{9}\\b';
export const MbRegex = '\\b\\d{8}\\b';
export const DigitRegex = '^\\d+(\\.\\d{2})?$';

export const Units = [
  {unit: 'Kilogram', short: 'kg'},
  {unit: 'Gram', short: 'g'},
  {unit: 'Komad', short: 'kom'}
];

export const WorkDays = {
  monday: 'Ponedeljak',
  tuesday: 'Utorak',
  wednesday: 'Sreda',
  thursday: 'Četvrtak',
  friday: 'Petak',
  saturday: 'Subota',
  sunday: 'Nedelja',
};

export const DaysOfWeek = [
  {
    dayForView: 'Ponedeljak',
    dayForDB: 'monday',
    number: 1
  },
  {
    dayForView: 'Utorak',
    dayForDB: 'tuesday',
    number: 2
  },
  {
    dayForView: 'Sreda',
    dayForDB: 'wednesday',
    number: 3
  },
  {
    dayForView: 'Četvrtak',
    dayForDB: 'thursday',
    number: 4
  },
  {
    dayForView: 'Petak',
    dayForDB: 'friday',
    number: 5
  },
  {
    dayForView: 'Subota',
    dayForDB: 'saturday',
    number: 6
  },
  {
    dayForView: 'Nedelja',
    dayForDB: 'sunday',
    number: 0
  }
];


export const BusinessCategories = [
  {id: 1, name: 'Igraonica'},
  {id: 2, name: 'Dekoracija'},
  {id: 3, name: 'Ketering'},
  {id: 4, name: 'Animatori'},
  {id: 5, name: 'Fotograf'},
  {id: 6, name: 'Torte'},
  {id: 7, name: 'Pokloni'}
];
