<div class="wrapper-custom-notifications">
  <div *ngFor="let notification of customNotifications" class="wrapper-custom-notification">
    <div class="left-custom-notification">
      <div class="wrapper-image-notification">
        <img [src]="notification?.image?.full_path" alt="">
      </div>
      <div class="zc-d-flex zc-flex-column">
        <span class="title-notification">{{notification.title}}</span>
        <span class="description-notification">{{notification.description}}</span>
        <span class="time-notification">{{notification.release_date | slice:0: -3}}</span>
      </div>
    </div>
    <div class="right-custom-notification" (click)="editNotification.emit(notification)">
      <div class="wrapper-button">
        <i class="fa-solid fa-pen-to-square"></i>
      </div>
    </div>
  </div>
</div>
