import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationHelperService} from 'src/app/services/helpers/authentication-helper.service';
import {DEFAULT_AUTHENTICATED_PAGE, PAGES_AND_PATHS} from '../../constants/pages-and-paths';
import {EmailRegex, PasswordRegex} from '../../constants/general-variables';
import {AuthenticationHttpService} from '../../services/http-services/auth/authentication-http.service';
import {LoginApiData} from '../../services/http-services/auth/autentication-http-interfaces';

export enum loginPageEnums {
  errMsg = 'Pogrešni kredencijali, pokušajte ponovo.'
}
@Component({
  selector: 'wx-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  isPasswordShow = false;
  formTriedToSubmit = false;
  errMessage = '';
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(private formBuilder: FormBuilder,
              private authHelper: AuthenticationHelperService,
              private authenticationHttpService: AuthenticationHttpService) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(EmailRegex)]],
      password: ['', [Validators.required, Validators.pattern(PasswordRegex)]],
    });
  }

  submitLoginForm(): void {
    this.formTriedToSubmit = true;
    if (this.loginForm.valid) {
      this.authenticationHttpService.login(this.loginForm.value).then((res: LoginApiData) => {
        this.authHelper.clientLogin(res.data.user, res.data.access_token, DEFAULT_AUTHENTICATED_PAGE.page.pagePath);
      }, error => {
        this.errMessage = loginPageEnums.errMsg;
      });
    }
  }
}
