<div class="wrapper-page invitations-page">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div class="wrapper-content-page">
    <div class="wrapper-header-page-content">
      <span class="header-text">Pozivnice</span>
    </div>
    <div class="zc-wrapper-tabs">
      <div class="zc-tab" (click)="activeTab = InvitationsTabsEnums.boys"
           [ngClass]="{'active': activeTab === InvitationsTabsEnums.boys}">
        <span>Dečaci</span>
      </div>
      <div class="zc-tab" (click)="activeTab = InvitationsTabsEnums.girls"
           [ngClass]="{'active': activeTab === InvitationsTabsEnums.girls}">
        <span>Devojčice</span>
      </div>
    </div>
    <div *ngIf="activeTab === InvitationsTabsEnums.boys" class="content-invitations-page">
      <div *ngFor="let invitation of boysInvitations" class="invitation-single">
        <div class="invitation-single-header">
          <h3>Izgled pozivcnice za {{invitation.age}}{{invitation.age === 7 ? '+' : '.'}} rođendan</h3>
          <button class="zc-btn zc-btn--primary" (click)="invitationForEdit = invitation"><span>Izmeni</span></button>
        </div>
        <div class="invitation-single-content grid">
          <div class="grid__m-2" *ngFor="let image of invitation.images">
            <img [src]="image.full_path" [alt]="image.name">
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="activeTab === InvitationsTabsEnums.girls" class="content-invitations-page">
      <div *ngFor="let invitation of girlsInvitations" class="invitation-single">
        <div class="invitation-single-header">
          <h3>Izgled pozivnice za {{invitation.age}}{{invitation.age === 7 ? '+' : '.'}} rođendan</h3>
          <button class="zc-btn zc-btn--primary" (click)="invitationForEdit = invitation"><span>Izmeni</span></button>
        </div>
        <div class="invitation-single-content grid">
          <div class="grid__m-2" *ngFor="let image of invitation.images">
            <img [src]="image.full_path" [alt]="image.name">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<gr-edit-invitation-modal
  *ngIf="invitationForEdit !== null"
  [invitationForEdit]="invitationForEdit"
  (closeModal)="invitationForEdit = null"
  (confirmModal)="onConfirmEditInvitation($event)"
></gr-edit-invitation-modal>
