<div class="wrapper-page gifts-page">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div class="wrapper-content-page">
    <div class="wrapper-header-page-content">
      <span class="header-text">Pokloni</span>
      <div class="wrapper-buttons-header">
        <button class="zc-btn zc-btn--primary" (click)="isCreateToyStoreModalOpen = true"><span>Dodaj prodavnicu</span>
        </button>
        <button class="zc-btn zc-btn--primary" [routerLink]="[PAGES_AND_PATHS.createPresent.pagePath]"><span>Dodaj poklon</span>
        </button>
        <button class="zc-btn zc-btn--primary" (click)="isUploadPresentsTableModalOpen = true"><span>Uvezi tabelu poklona</span>
        </button>
        <button class="zc-btn zc-btn--primary" [disabled]="checkedPresentIds.length === 0" (click)="isAddPresentsActionModalOpen = true"><span>Dodaj akciju</span>
        </button>
      </div>
    </div>
    <div class="zc-wrapper-tabs">
      <div class="zc-tab" (click)="activeTab = PresentsTabsEnums.presents"
           [ngClass]="{'active': activeTab === PresentsTabsEnums.presents}">
        <span>Pokloni</span>
      </div>
      <div class="zc-tab" (click)="activeTab = PresentsTabsEnums.stores"
           [ngClass]="{'active': activeTab === PresentsTabsEnums.stores}">
        <span>Prodavnice</span>
      </div>
    </div>
    <gr-tab-presents
      *ngIf="activeTab === PresentsTabsEnums.presents"
      [stores]="stores"
      [isPresentsTableChanged]="isPresentsTableChanged"
      (checkedPresentsChange)="checkedPresentIds = $event">
    </gr-tab-presents>
    <gr-tab-toy-stores
      *ngIf="activeTab === PresentsTabsEnums.stores"
      [stores]="stores">
    </gr-tab-toy-stores>
  </div>
</div>
<gr-create-or-edit-toy-store
  *ngIf="isCreateToyStoreModalOpen"
  (closeModal)="isCreateToyStoreModalOpen = false"
  (confirmCreateStore)="onConfirmCreateToyStore($event)">
</gr-create-or-edit-toy-store>

<gr-upload-presents-table-modal
  *ngIf="isUploadPresentsTableModalOpen"
  [stores]="stores"
  (closeModal)="isUploadPresentsTableModalOpen = false"
  (confirmModal)="onNewPresentsTableUploaded()">
</gr-upload-presents-table-modal>

<gr-add-presents-action-modal
  *ngIf="isAddPresentsActionModalOpen && checkedPresentIds.length"
  [checkedPresentIds]="checkedPresentIds"
  (closeModal)="isAddPresentsActionModalOpen = false"
  (confirmModal)="onConfirmAddPresentAction()">
</gr-add-presents-action-modal>
