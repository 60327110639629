<div class="wrapper-page single-page-gift">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div class="wrapper-content-page">
    <div class="wrapper-header-page-content">
      <div class="wrapper-back-btn">
        <div class="back-btn" [routerLink]="[PAGES_AND_PATHS.presents.pagePath]">
          <i class="fa-solid fa-chevron-left"></i>
        </div>
        <span class="header-text">{{present?.name}} {{present?.is_free ? '(GRATIS POKLON)' : ''}}</span>
      </div>
      <button class="zc-btn zc-btn--primary" [routerLink]="[PAGES_AND_PATHS.editPresent.pagePath, present?.id]"><span>Izmeni</span></button>
    </div>
    <div class="wrapper-single-page-gift">
      <div class="grid xs-pl-32 xs-pr-32 xs-pt-32">
        <div class="grid__m-12" *ngIf="present?.discount">
          <div class="wrapper-header-gift discount">
            <p>Početak akcije: <span>{{present?.discount.date_from | date: 'dd.MM.yyyy. HH:mm'}} </span>,</p>
            <p>Kraj akcije: <span>{{present?.discount.date_to | date: 'dd.MM.yyyy. HH:mm'}} </span>, </p>
            <p>Procenat sniženja: <span>{{present?.discount.discount_percentage}}%</span></p>
          </div>
        </div>
        <div class="grid__m-12">
          <div class="wrapper-header-gift">
            <p>Šifra: <span>{{present?.product_code}}</span>,</p>
            <p>Barcode: <span>{{present?.barcode}}</span></p>
          </div>
        </div>
      </div>
      <div class="grid xs-pl-32 xs-pr-32">
        <div class="grid__m-3" *ngFor="let image of present?.images">
          <div class="wrapper-preview-image">
            <img [src]="image.full_path" [alt]="image.name">
          </div>
        </div>
      </div>
      <div class="grid xs-pl-32 xs-pr-32">
        <div class="grid__m-8 grid__xl-9">
          <div class="preview-description">
            <span class="label-desc">Detaljan opis proizvoda:</span>
            <span [innerHTML]="present?.description"></span>
          </div>
        </div>
        <div class="grid__m-4 grid__xl-3">
          <div class="wrapper-other-info">
            <p>Cena MP: <span><b>{{present?.price_for_user}} RSD</b></span></p>
            <p>Cena VP: <span><b>{{present?.wholesale_price}} RSD</b></span></p>
            <p>Pol: <span>{{present?.gender | returnGender}}</span></p>
            <p>Uzrast: <span>{{present?.age_range}}</span></p>
            <p>Rabat: <span>{{present?.rebate ? present?.rebate : present?.supplier.rebate}}%</span></p>
            <h2 [ngClass]="{'red': !present?.quantity}">Na stanju ({{present?.quantity}} kom)</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
