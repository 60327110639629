import {Component, OnInit} from '@angular/core';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationHttpService} from '../../services/http-services/auth/authentication-http.service';
import {EmailRegex} from '../../constants/general-variables';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'gr-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm!: FormGroup;
  formTriedToSubmit = false;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(private formBuilder: FormBuilder,
              private toastrService: ToastrService,
              private authenticationHttpService: AuthenticationHttpService) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(EmailRegex)]],
    });
  }

  submitForgotPasswordForm(): void {
    this.formTriedToSubmit = true;
    if (this.forgotPasswordForm.valid) {
      this.authenticationHttpService.forgotPassword(this.forgotPasswordForm.value).then((res: any) => {
        this.toastrService.success(res.message)
      }).catch(({error}: {error:{ errors: { [key: string]: string } }}) => {
        Object.values(error.errors).forEach(error => this.toastrService.error(error));
      })
    }
  }
}
