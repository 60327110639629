import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild} from '@angular/core';
import {UiHelperService} from '../../../services/helpers/ui-helper.service';

@Component({
  selector: 'gr-dropdown',
  templateUrl: './dropdown.component.html',
})
export class DropdownComponent implements OnInit, OnChanges {
  @Input() items: any[] = [];
  @Input() propForView = 'name';
  @Input() propIdentifier: string | number = '';
  @Input() disableSelect = false;
  @Input() dropdownLabel = '';
  @Input() placeholder = '';
  @Input() defaultSelected: any;
  @Output() selectItem: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('dropdownBtn') dropdownBtn: ElementRef | undefined;
  isDropdownOpen = false;
  selectedItem: any;

  constructor(private renderer: Renderer2,
              private uiHelperService: UiHelperService) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.listenToClickDropdown();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultSelected && changes.defaultSelected.currentValue) {
      this.selectedItem = this.defaultSelected;
    }
  }

  toggleDropdown(event: Event): void {
    event.stopImmediatePropagation();
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  listenToClickDropdown(): void {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.uiHelperService.checkIfClickedOutsideOfTheElement(this.dropdownBtn, this.dropdownBtn, e)) {
        this.isDropdownOpen = false;
      }
    });
  }

  onSelectItem(event: any, item: any): void {
    event.stopPropagation();
    this.selectedItem = item;
    this.isDropdownOpen = false;
    this.selectItem.emit(item);
  }
}
