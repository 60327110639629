import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {NgClass, NgIf} from '@angular/common';
import {DigitRegex, EmailRegex, PhoneRegex} from '../../../../constants/general-variables';
import {SettingsHttpService} from '../../../../services/http-services/settings/settings-http.service';
import {ToastrService} from 'ngx-toastr';
import {FormControlErrorMessagesModule} from '../../../../pipes/form-control-error-messages/form-control-error-messages.module';
import {DropdownReactiveFormModule} from '../../../../shared-components/dropdowns/dropdown-reactive-form/dropdown-reactive-form.module';
import {FormControlModule} from '../../../../pipes/form-control/form-control.module';
import {GrCity} from '../../../../interfaces/general-interfaces';
import {GeneralHttpService} from '../../../../services/http-services/general/general-http.service';

@Component({
  selector: 'gr-add-or-edit-delivery-service-modal',
  templateUrl: './add-or-edit-delivery-service-modal.component.html',
  imports: [
    FormsModule,
    NgIf,
    ReactiveFormsModule,
    FormControlErrorMessagesModule,
    DropdownReactiveFormModule,
    FormControlModule,
    NgClass
  ],
  standalone: true
})
export class AddOrEditDeliveryServiceModalComponent {
  @Input() deliveryForEdit: any;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmCreateDelivery: EventEmitter<any> = new EventEmitter<any>();
  @Output() confirmEditDelivery: EventEmitter<any> = new EventEmitter<any>();
  formTriedToSubmit = false;
  createOrEditDeliveryServiceForm!: FormGroup;
  listOfCities: GrCity[] = [];

  constructor(private formBuilder: FormBuilder,
              private toastService: ToastrService,
              private generalHttpService: GeneralHttpService,
              private settingsHttpService: SettingsHttpService) {
  }
  ngOnInit(): void {
    this.buildForm();
    this.getCities();
  }
  buildForm(): void {
    this.createOrEditDeliveryServiceForm = this.formBuilder.group({
      name: ['', Validators.required],
      city: ['', Validators.required],
      price_for_user: [0, [Validators.required, Validators.pattern(DigitRegex)]],
      price_for_admin: [0, [Validators.required, Validators.pattern(DigitRegex)]],
    });

    if (this.deliveryForEdit) {
      this.createOrEditDeliveryServiceForm.patchValue(this.deliveryForEdit);
    }
  }

  getCities(): void {
    this.generalHttpService.getCities().then(res => {
      this.listOfCities = res.data;
    });
  }

  onSubmitDeliveryServiceForm(): void {
    this.formTriedToSubmit = true;
    if (this.createOrEditDeliveryServiceForm.valid) {
      this.deliveryForEdit
      ? this.settingsHttpService.putDelivery({id: this.deliveryForEdit.id}, this.createOrEditDeliveryServiceForm.value).then((res: any) => {
          this.toastService.success('Uspešno ste izmenili dostavljača.');
          this.confirmEditDelivery.emit(res.data);
          this.formTriedToSubmit = false;
        })
      : this.settingsHttpService.postDelivery(this.createOrEditDeliveryServiceForm.value).then((res: any) => {
          this.toastService.success('Uspešno ste dodali dostavljača.');
          this.confirmCreateDelivery.emit(res.data);
          this.formTriedToSubmit = false;
        });
    }
  }
}
