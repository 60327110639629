import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {GetFaqApiData, GetFaqApiDataFormatted, PostPutFaqApiData, PostPutFaqReqObj} from './faq-http-interfaces';
import {GeneralHttpHelperService} from '../../helpers/general-http-helper.service';

const PAGES_API_ROUTES = {
  GET_FAQS: 'admin/faqs',
  POST_FAQ: 'admin/faqs',
  PUT_FAQ: 'admin/faqs/:faqId',
  DELETE_FAQ: 'admin/faqs/:faqId',
};
@Injectable({
  providedIn: 'root'
})
export class FaqHttpService {


  constructor(private httpClient: HttpClient,
              private generalHttpHelperService: GeneralHttpHelperService) { }
  private getFaqsReq(urlParams?: Record<string, any>): Observable<GetFaqApiData> {
    const apiUrl = urlParams
      ? this.generalHttpHelperService.addUrlParams(PAGES_API_ROUTES.GET_FAQS, urlParams)
      : PAGES_API_ROUTES.GET_FAQS;
    return this.httpClient.get(apiUrl) as any;
  }

  getFaqs(urlParams?: Record<string, any>): Promise<GetFaqApiDataFormatted> {
    return new Promise<GetFaqApiDataFormatted>((resolve, reject) => {
      this.getFaqsReq(urlParams).subscribe((res: GetFaqApiData) => {
        resolve({
          data: res.data.data,
          pagination: this.generalHttpHelperService.returnPagination(res.data)
        });
      }, error => {
        reject(error);
      });
    });
  }

  private postFaqItemReq(bodyParams: PostPutFaqReqObj): Observable<PostPutFaqApiData> {
    return this.httpClient.post(PAGES_API_ROUTES.POST_FAQ, bodyParams) as any;
  }

  postFaqItem(bodyParams: PostPutFaqReqObj): Promise<PostPutFaqApiData> {
    return new Promise<any>((resolve, reject) => {
      this.postFaqItemReq(bodyParams).subscribe((res: PostPutFaqApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private putFaqItemReq(urlParam: Record<string, any>, bodyParams: PostPutFaqReqObj): Observable<PostPutFaqApiData> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(PAGES_API_ROUTES.PUT_FAQ, urlParam);
    return this.httpClient.put(apiUrl, bodyParams) as any;
  }

  putFaqItem(urlParam: Record<string, any>, bodyParams: PostPutFaqReqObj): Promise<PostPutFaqApiData> {
    return new Promise<any>((resolve, reject) => {
      this.putFaqItemReq(urlParam, bodyParams).subscribe((res: PostPutFaqApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private deleteFaqItemReq(urlParam: Record<string, any>): Observable<PostPutFaqApiData> {
    const url = this.generalHttpHelperService.replaceUrlParamsWithValues(PAGES_API_ROUTES.DELETE_FAQ, urlParam);
    return this.httpClient.delete(url) as any;
  }

  deleteFaqItem(urlParam: Record<string, any>): Promise<PostPutFaqApiData> {
    return new Promise<any>((resolve, reject) => {
      this.deleteFaqItemReq(urlParam).subscribe((res: PostPutFaqApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
}
