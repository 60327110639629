import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {GeneralHttpHelperService} from '../../helpers/general-http-helper.service';
import {HttpClient} from '@angular/common/http';

const PRESENTS_API_ROUTES = {
  GET_PRESENTS: 'admin/gifts',
  GET_SINGLE_PRESENTS: 'admin/gifts/:presentId',
  POST_PRESENT: 'admin/gifts',
  PUT_PRESENT: 'admin/gifts/:presentId',
  DELETE_PRESENT: 'admin/gifts/:presentId',
  UPLOAD_PRESENTS_TABLE: 'admin/gifts/import/:presentId',
  ADD_PRESENT_ACTION: 'admin/discounts',
  GET_TOY_STORES: 'admin/suppliers',
  POST_TOY_STORE: 'admin/suppliers',
  PUT_TOY_STORE: 'admin/suppliers/:storeId',
  DELETE_TOY_STORE: 'admin/suppliers/:storeId',
};
@Injectable({
  providedIn: 'root'
})
export class PresentsHttpService {

  constructor(private generalHttpHelperService: GeneralHttpHelperService,
              private httpClient: HttpClient) { }

  private getPresentsReq(urlParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.addUrlParams(PRESENTS_API_ROUTES.GET_PRESENTS, urlParams);
    return this.httpClient.get(apiUrl) as any;
  }

  getPresents(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getPresentsReq(urlParams).subscribe((res: any) => {
        resolve({
          data: res.data.data,
          pagination: this.generalHttpHelperService.returnPagination(res.data)
        });
      }, error => {
        reject(error);
      });
    });
  }

  private getSinglePresentReq(urlParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(PRESENTS_API_ROUTES.GET_SINGLE_PRESENTS, urlParams);
    return this.httpClient.get(apiUrl) as any;
  }

  getSinglePresent(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getSinglePresentReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private postPresentReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(PRESENTS_API_ROUTES.POST_PRESENT, bodyParams) as any;
  }

  postPresent(bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.postPresentReq(bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private putPresentReq(urlParams: any, bodyParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(PRESENTS_API_ROUTES.PUT_PRESENT, urlParams);
    return this.httpClient.post(apiUrl, bodyParams) as any;
  }

  putPresent(urlParams: any, bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.putPresentReq(urlParams, bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private deletePresentReq(urlParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(PRESENTS_API_ROUTES.DELETE_PRESENT, urlParams);
    return this.httpClient.delete(apiUrl) as any;
  }

  deletePresent(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.deletePresentReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private uploadPresentTableReq(urlParams: any, bodyParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(PRESENTS_API_ROUTES.UPLOAD_PRESENTS_TABLE, urlParams);
    return this.httpClient.post(apiUrl, bodyParams) as any;
  }

  uploadPresentTable(urlParams: any, bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.uploadPresentTableReq(urlParams, bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private addPresentsActionReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(PRESENTS_API_ROUTES.ADD_PRESENT_ACTION, bodyParams) as any;
  }

  addPresentsAction(bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.addPresentsActionReq(bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private getToyStoresReq(): Observable<any> {
    return this.httpClient.get(PRESENTS_API_ROUTES.GET_TOY_STORES) as any;
  }

  getToyStores(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getToyStoresReq().subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private postToyStoreReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(PRESENTS_API_ROUTES.POST_TOY_STORE, bodyParams) as any;
  }

  postToyStore(bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.postToyStoreReq(bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private putToyStoreReq(urlParams: any, bodyParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(PRESENTS_API_ROUTES.PUT_TOY_STORE, urlParams);
    return this.httpClient.post(apiUrl, bodyParams) as any;
  }

  putToyStore(urlParams: any, bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.putToyStoreReq(urlParams, bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private deleteToyStoreReq(urlParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(PRESENTS_API_ROUTES.DELETE_TOY_STORE, urlParams);
    return this.httpClient.delete(apiUrl) as any;
  }

  deleteToyStore(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.deleteToyStoreReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
}
