<div class="wrapper-input">
  <span class="label-input">{{dropdownLabel}}</span>
  <div class="zc-wrapper-dropdown" [ngClass]="{'open': isDropdownOpen}">
    <div class="dropdown-label" #dropdownBtn (click)="toggleDropdown($event)">
      <span>{{selectedItem ? selectedItem[propForView] : placeholder}}</span>
      <i class="fa-solid fa-chevron-up"></i>
    </div>
    <div class="zc-dropdown-collapsed">
      <div
        *ngFor="let item of items"
        (click)="onSelectItem($event, item)"
        class="item-dropdown">
        <span>{{item[propForView] ? item[propForView] : item}}</span>
      </div>
    </div>
  </div>
</div>
