import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {GetFaqApiData, GetFaqApiDataFormatted, PostPutFaqApiData} from '../../services/http-services/faq/faq-http-interfaces';
import {GrFaq} from '../../interfaces/faq-interfaces';
import {FaqHttpService} from '../../services/http-services/faq/faq-http.service';
import {GrPagination} from '../../interfaces/general-interfaces';
import {FaqEnums} from '../../enums/faq-enums';

@Component({
  selector: 'gr-faq',
  templateUrl: './faq.component.html'
})
export class FaqComponent implements OnInit {
  faqs: GrFaq[] = [];
  faqPagination: GrPagination | undefined;
  isCreateFaqModalOpen = false;
  isActiveTab = FaqEnums.users;
  urlParams: any = {type: FaqEnums.userForDB, search: null};
  faqForEdit: GrFaq | undefined;
  protected readonly FaqEnums = FaqEnums;

  constructor(private faqHttpService: FaqHttpService,
              private toastrService: ToastrService) {}

  ngOnInit(): void {
    this.getFaqs();
  }

  getFaqs(): void {
    const page = (this.faqPagination?.currentPage) ? this.faqPagination.currentPage : 1;
    this.faqHttpService.getFaqs({page, ...this.urlParams}).then((res: GetFaqApiDataFormatted) => {
      this.faqs = res.data;
      this.faqPagination = res.pagination;
    });
  }

  onSearchChange(searchQuery: string): void {
    this.urlParams.search = searchQuery;
    if (this.faqPagination?.currentPage) {
      this.faqPagination.currentPage = 1;
    }
    this.getFaqs();
  }

  handleTabsChange(tab: string): void {
    if (tab === FaqEnums.users) {
      this.isActiveTab = FaqEnums.users;
      this.urlParams.type = FaqEnums.userForDB;
    } else {
      this.isActiveTab = FaqEnums.businessUsers;
      this.urlParams.type = FaqEnums.businessUserForDB;
    }
    if (this.faqPagination?.currentPage) {
      this.faqPagination.currentPage = 1;
    }
    this.getFaqs();
  }
  onPageChanged(page: any): void {
    if (this.faqPagination) {
      this.faqPagination.currentPage = page;
      this.getFaqs();
    }
  }

  onConfirmCreateOrEditFaq(faqData: PostPutFaqApiData): void {
    this.faqForEdit = undefined;
    this.isCreateFaqModalOpen = false;
    this.getFaqs();
    this.toastrService.success(faqData.message);
  }

  removeFaqItem(faqId: number): void {
    this.faqHttpService.deleteFaqItem({faqId}).then(res => {
      this.getFaqs();
      this.toastrService.success('Uspešno ste obrisali često pitanje.');
    }, error => {
      this.toastrService.warning('Došlo je do greške, pokušajte ponovo.');
    });
  }
}
