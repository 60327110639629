<div class="wrapper-page">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div class="wrapper-content-page">
    <div class="wrapper-header-page-content">
      <span class="header-text">{{pageId | handlePageTitlePipe}}</span>
    </div>
    <form [formGroup]="updatePageForm" (ngSubmit)="submitUpdatePageForm()">
      <div class="wrapper-quill-editor">
        <quill-editor [formControl]="updatePageForm.controls.content | formControl" [ngClass]="{'not-allowed': isFormDisabled}"></quill-editor>
        <span class="error-message">{{updatePageForm.controls.content | formControlErrorMessages: null : formTriedToSubmit}}</span>
      </div>
      <div class="wrapper-save-btn">
        <button *ngIf="isFormDisabled" (click)="enableForm()" class="zc-btn zc-btn--primary xs-mr-32 xs-mt-10" type="button">
          <span>{{isPageCreated ? 'Izmeni' : 'Dodaj'}}</span>
        </button>
        <button *ngIf="!isFormDisabled" class="zc-btn zc-btn--primary" type="submit">
          <span>Sačuvaj</span>
        </button>
      </div>
    </form>
  </div>
</div>
