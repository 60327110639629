import { Component } from '@angular/core';

@Component({
  selector: 'gr-notification-payment-tab',
  standalone: true,
  templateUrl: './notification-payment-tab.component.html',
})
export class NotificationPaymentTabComponent {

}
