import { Component } from '@angular/core';
import {NotificationTabsEnums} from '../../enums/notification-enums';

@Component({
  selector: 'gr-notifications',
  templateUrl: './notifications.component.html',
})
export class NotificationsComponent {
  activeTab = NotificationTabsEnums.users;
  isCreateNotificationModalOpen = false;
  customNotificationForEdit: any = null;
  protected readonly NotificationTabsEnums = NotificationTabsEnums;
}
