<form class="grid" [formGroup]="termsFirstStepForm" (ngSubmit)="onSubmitTermsFirstStepForm()">
  <div class="grid__m-6">
    <div class="">
      <span class="terms-title">Kada je otvorena firma?</span>
      <div class="wrapper-checkbox-custom grid">
        <div class="grid__m-6" *ngFor="let day of daysOfWeek; index as i">
          <input class="checkbox-custom" type="checkbox" [id]="'checkbox-' + i" [value]="day.dayForDB"
                 [checked]="initialCheckedDays.includes(day.dayForDB)"
                 (change)="onDaysCheckboxChange($event)">
          <label class="checkbox-custom-label" [for]="'checkbox-' + i">{{day.dayForView}}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="grid__m-12">
    <span class="terms-title">Radno vreme</span>
  </div>
  <div class="grid__m-12">
    <div class="grid grid-gap-0">
    <div *ngIf="termsFirstStepForm.controls.everyDaySameWorkingTime.value" class="grid__m-6">
      <div class="wrapper-input">
        <div class="zc-input">
          <input type="text" placeholder="Od" formControlName="workingTimeStart">
        </div>
        <span
          class="error-message">{{termsFirstStepForm.controls.workingTimeStart | formControlErrorMessages: null : formTriedToSubmit}}</span>
      </div>
    </div>
    <div *ngIf="termsFirstStepForm.controls.everyDaySameWorkingTime.value" class="grid__m-6">
      <div class="wrapper-input">
        <div class="zc-input">
          <input type="text" placeholder="Do" formControlName="workingTimeEnd">
        </div>
        <span
          class="error-message">{{termsFirstStepForm.controls.workingTimeEnd | formControlErrorMessages: null : formTriedToSubmit}}</span>
      </div>
    </div>
    </div>
    <div
      *ngIf="!termsFirstStepForm.controls.everyDaySameWorkingTime.value && termsFirstStepForm.controls.days.value.length"
      class="grid grid-gap-0">
      <div class="grid__m-4" *ngFor="let day of termsFirstStepForm.controls.days.value; let i = index">
        <div [formGroup]="getIndividualTimeGroup(i)">
          <label class="label-day">{{ day | returnDayForView}}</label>
          <div class="wrapper-input">
            <div class="zc-input">
              <input type="text" placeholder="Od" formControlName="workingTimeStart">
            </div>
            <span
              class="error-message">{{getIndividualTimeGroup(i).controls.workingTimeStart | formControlErrorMessages: null : formTriedToSubmit}}</span>
          </div>
          <div class="wrapper-input">
            <div class="zc-input">
              <input type="text" placeholder="Do" formControlName="workingTimeEnd">
            </div>
            <span
              class="error-message">{{getIndividualTimeGroup(i).controls.workingTimeEnd | formControlErrorMessages: null : formTriedToSubmit}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="grid__m-12">
      <div class="wrapper-checkbox-custom">
        <div>
          <input class="checkbox-custom" type="checkbox" id="checkbox10" formControlName="everyDaySameWorkingTime">
          <label class="checkbox-custom-label" for="checkbox10">Svaki dan isto radno vreme</label>
        </div>
      </div>
    </div>
  </div>
  <div class="grid__m-12">
    <span class="terms-title xs-mt-32">Raspon slotova (slobodnih termina)</span>
    <span class="terms-subtitle">Unesite vremenski interval (npr. od 120 minuta), koliko traje termin, kao i pauzu između dva termina (npr. 30 minuta).</span>
  </div>
  <div class="grid__m-12">
    <div class="grid grid-gap-0">
      <div *ngIf="termsFirstStepForm.controls.everyDaySameSlots.value" class="grid__m-6">
        <div class="wrapper-input">
          <div class="zc-input">
            <input type="text" placeholder="Trajanje termina" [formControlName]="'timeSlot'">
          </div>
          <span
            class="error-message">{{termsFirstStepForm.controls.timeSlot | formControlErrorMessages: null : formTriedToSubmit}}</span>
        </div>
      </div>
      <div *ngIf="termsFirstStepForm.controls.everyDaySameSlots.value" class="grid__m-6">
        <div class="wrapper-input">
          <div class="zc-input">
            <input type="text" placeholder="Pauza" [formControlName]="'breakTime'">
          </div>
          <span
            class="error-message">{{termsFirstStepForm.controls.breakTime | formControlErrorMessages: null : formTriedToSubmit}}</span>
        </div>
      </div>
    </div>
    <div class="grid grid-gap-0"
         *ngIf="!termsFirstStepForm.controls.everyDaySameSlots.value && termsFirstStepForm.controls.days.value.length"
    >
      <div class="grid__m-4" *ngFor="let day of termsFirstStepForm.controls.days.value; let i = index">
        <div [formGroup]="getIndividualSlotsGroup(i)">
          <label class="label-day">{{ day | returnDayForView }}</label>
          <div class="wrapper-input">
            <div class="zc-input">
              <input type="text" placeholder="Trajanje termina" [formControlName]="'timeSlot'">
            </div>
            <span
              class="error-message">{{getIndividualSlotsGroup(i).controls.timeSlot | formControlErrorMessages: null : formTriedToSubmit}}</span>
          </div>
          <div class="wrapper-input">
            <div class="zc-input">
              <input type="text" placeholder="Pauza" [formControlName]="'breakTime'">
            </div>
            <span
              class="error-message">{{getIndividualSlotsGroup(i).controls.breakTime | formControlErrorMessages: null : formTriedToSubmit}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="grid__m-12">
      <div class="wrapper-checkbox-custom">
        <div>
          <input class="checkbox-custom" type="checkbox" id="checkbox11" formControlName="everyDaySameSlots">
          <label class="checkbox-custom-label" for="checkbox11">Svaki dan isto radno vreme</label>
        </div>
      </div>
    </div>
  </div>
  <div class="grid__m-12">
    <div class="zc-d-flex zc-align-items zc-justify-end xs-mb-32">
      <button class="zc-btn zc-btn--primary" type="submit"><span>Sačuvaj</span></button>
    </div>
  </div>
</form>
