<div class="wrapper-page faq-page">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div class="wrapper-content-page">
    <div class="wrapper-header-page-content">
      <span class="header-text">Admin korisnici</span>
      <button class="zc-btn zc-btn--primary" (click)="isCreateAdminUserModalOpen = true"><span>Dodaj</span></button>
    </div>
    <div>
      <gr-search (searchQueryChange)="onSearchChange($event)"></gr-search>
    </div>
<!--    <div class="grid xs-pr-32 xs-pl-32">-->
<!--      <div class="grid__m-12">-->
<!--        <div class="zc-table">-->
<!--          <div class="zc-header-table">-->
<!--            <div class="zc-header-row">-->
<!--              <div class="zc-header-column">-->
<!--                <span>Ime admina</span>-->
<!--              </div>-->
<!--              <div class="zc-header-column column-buttons">-->
<!--                <span>Alatke</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="zc-content-table">-->
<!--            <div class="zc-content-row" *ngFor="let user of adminUsersList">-->
<!--              <div class="zc-content-column">-->
<!--                <span>{{user.full_name}}</span>-->
<!--              </div>-->
<!--              <div class="zc-content-column column-buttons">-->
<!--                <div class="buttons-group">-->
<!--                  <div class="wrapper-button">-->
<!--                    <i class="fa-solid fa-eye"></i>-->
<!--                  </div>-->
<!--                  <div class="wrapper-button" (click)="adminUserForEdit = user">-->
<!--                    <i class="fa-solid fa-pen-to-square"></i>-->
<!--                  </div>-->
<!--                  <div class="wrapper-button" (click)="adminUserForDelete = user">-->
<!--                    <i class="fa-solid fa-trash"></i>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="grid xs-pr-32 xs-pl-32">
      <div class="grid__m-12">
        <div class="zc-table">
          <div class="zc-header-table">
            <div class="zc-header-row">
              <div class="zc-header-column">
                <span>Ime</span>
                <div class="wrapper-sort" (click)="onSortChange('first_name')" [ngClass]="{'active': urlParams.sort === 'first_name'}">
                  <i class="fa-solid" [ngClass]="(urlParams.sort === 'first_name' && urlParams.direction === 'desc') ? 'fa-arrow-up-a-z': 'fa-arrow-down-a-z'"></i>
                </div>
              </div>
              <div class="zc-header-column">
                <span>Prezime</span>
                <div class="wrapper-sort" (click)="onSortChange('last_name')" [ngClass]="{'active': urlParams.sort === 'last_name'}">
                  <i class="fa-solid" [ngClass]="(urlParams.sort === 'last_name' && urlParams.direction === 'desc') ? 'fa-arrow-up-a-z': 'fa-arrow-down-a-z'"></i>
                </div>
              </div>
              <div class="zc-header-column">
                <span>Email</span>
                <div class="wrapper-sort" (click)="onSortChange('email')" [ngClass]="{'active': urlParams.sort === 'email'}">
                  <i class="fa-solid" [ngClass]="(urlParams.sort === 'email' && urlParams.direction === 'desc') ? 'fa-arrow-up-a-z': 'fa-arrow-down-a-z'"></i>
                </div>
              </div>
              <div class="zc-header-column">
                <span>Broj telefona</span>
              </div>
              <div class="zc-header-column column-buttons">
                <span>Alatke</span>
              </div>
            </div>
          </div>
          <div class="zc-content-table">
            <div class="zc-content-row" *ngFor="let user of adminUsersList">
              <div class="zc-content-column">
                <span>{{user.first_name}}</span>
              </div>
              <div class="zc-content-column">
                <span>{{user.last_name}}</span>
              </div>
              <div class="zc-content-column">
                <span>{{user.email}}</span>
              </div>
              <div class="zc-content-column">
                <span>{{user.phone}}</span>
              </div>
              <div class="zc-content-column column-buttons">
                <div class="buttons-group">
                  <div class="wrapper-button" (click)="adminUserForEdit = user">
                    <i class="fa-solid fa-pen-to-square"></i>
                  </div>
                  <div class="wrapper-button" (click)="adminUserForDelete = user">
                    <i class="fa-solid fa-trash"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid">
      <div class="grid__m-12">
        <gr-pagination
          *ngIf="adminUsersList.length"
          [currentPage]="adminUsersPagination?.currentPage"
          [pagId]="'js-admin-table-pagination'"
          [perPage]="adminUsersPagination?.perPage"
          [totalItems]="adminUsersPagination?.totalItems"
          (pageChanged)="onPageChanged($event)">
        </gr-pagination>
      </div>
    </div>
  </div>
</div>

<gr-create-or-edit-admin-modal
  *ngIf="adminUserForEdit || isCreateAdminUserModalOpen"
  [adminUserForEdit]="adminUserForEdit"
  (closeModal)="adminUserForEdit = null; isCreateAdminUserModalOpen = false"
  (confirmModal)="onConfirmCreateOrEditAdminUser()"
></gr-create-or-edit-admin-modal>

<gr-confirm-modal
  *ngIf="adminUserForDelete"
  [modalTitle]="'Brisanje admin korisnika'"
  [modalDescription]="'Da li ste sigurni da želite da obrišete admin korisnika?'"
  (closeModal)="adminUserForDelete = null"
  (confirmModal)="removeAdminUser(adminUserForDelete ? adminUserForDelete.id : null)">
</gr-confirm-modal>


