<div class="wrapper-page faq-page">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div class="wrapper-content-page">
    <div class="wrapper-header-page-content">
      <span class="header-text">Baneri</span>
      <button class="zc-btn zc-btn--primary" (click)="isCreateBannerModalOpen = true"><span>Dodaj banner</span></button>
    </div>
    <div class="grid xs-p-16">
      <div  *ngFor="let banner of banners" class="grid__l-4 grid__xl-3">
        <div class="wrapper-banner-preview">
          <img class="preview-image" [src]="banner.image.full_path" alt="">
          <div class="wrapper-footer-banner-preview">
            <span>{{banner.active ? 'Aktivan' : 'Neaktivan'}}</span>
            <span>Pozicija {{banner.position}}</span>
            <div class="wrapper-buttons-banner-preview">
              <i (click)="bannerForEdit = banner" class="fa-solid fa-pen-to-square"></i>
              <i (click)="bannerForDelete = banner" class="fa-solid fa-trash"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid">
      <div class="grid__m-12">
        <gr-pagination
          *ngIf="banners.length"
          [currentPage]="bannersPagination?.currentPage"
          [pagId]="'js-banner-table-pagination'"
          [perPage]="bannersPagination?.perPage"
          [totalItems]="bannersPagination?.totalItems"
          (pageChanged)="onPageChanged($event)">
        </gr-pagination>
      </div>
    </div>
  </div>
  <gr-confirm-modal
    *ngIf="bannerForDelete !== null"
    [modalTitle]="'Da li ste sigurni da zelite da obrisete banner?'"
    (closeModal)="bannerForDelete = null"
    (confirmModal)="onConfirmDeleteBanner()"
  ></gr-confirm-modal>

  <gr-create-or-edit-banner-modal
    *ngIf="isCreateBannerModalOpen || bannerForEdit !== null"
    [bannerForEdit]="bannerForEdit"
    (closeModal)="isCreateBannerModalOpen = false; bannerForEdit = null"
    (confirmModal)="onConfirmCreateOrEditBanner()"
  ></gr-create-or-edit-banner-modal>
</div>


