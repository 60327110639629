import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';
import {AuthGuardService} from '../../guards/auth-guard.service';
import {PresentsComponent} from './presents.component';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.presents.pageInRouting,
    data: {data: PAGES_AND_PATHS.presents},
    component: PresentsComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./presents.module').then(m => m.PresentsModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PresentsRoutingModule { }
