import { Component } from '@angular/core';
import {FormControlErrorMessagesModule} from '../../../../pipes/form-control-error-messages/form-control-error-messages.module';
import {FormControlModule} from '../../../../pipes/form-control/form-control.module';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {NgClass, NgIf} from '@angular/common';
import {EmailRegex, PhoneRegex} from '../../../../constants/general-variables';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationHelperService} from '../../../../services/helpers/authentication-helper.service';
import {UsersHttpService} from '../../../../services/http-services/users/users-http.service';

@Component({
  selector: 'gr-tab-base-data',
  templateUrl: './tab-base-data.component.html',
  imports: [
    FormControlErrorMessagesModule,
    FormControlModule,
    FormsModule,
    NgIf,
    ReactiveFormsModule,
    NgClass
  ],
  standalone: true
})
export class TabBaseDataComponent {
  settingsUserProfileForm!: FormGroup;
  formUserProfileTriedToSubmit = false;
  isUserProfileFormDisabled = true;
  constructor(private formBuilder: FormBuilder,
              private toastrService: ToastrService,
              private authenticationHelperService: AuthenticationHelperService,
              private usersHttpService: UsersHttpService) {
  }
  ngOnInit(): void {
    this.buildForm();
  }
  buildForm(): void {
    this.settingsUserProfileForm = this.formBuilder.group({
      first_name: ['', [Validators.required, Validators.minLength(2)]],
      last_name: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.required, Validators.pattern(EmailRegex)]],
      phone: ['', [Validators.required, Validators.pattern(PhoneRegex)]],
    });
    this.settingsUserProfileForm.patchValue(this.authenticationHelperService.getUserData);
    this.disabledUserProfileForm();
  }

  enableUserProfileForm(): void {
    this.isUserProfileFormDisabled = false;
    this.settingsUserProfileForm.enable();
  }
  disabledUserProfileForm(): void {
    this.isUserProfileFormDisabled = true;
    this.settingsUserProfileForm.disable();
  }
  onSubmitSettingsUserProfileForm(): void {
    this.formUserProfileTriedToSubmit = true;
    if (this.settingsUserProfileForm.valid) {
      this.usersHttpService.putAuthUser(this.settingsUserProfileForm.value).then((res: any) => {
        this.disabledUserProfileForm();
        this.toastrService.success('Uspešno ste izmenili podatke.');
        this.formUserProfileTriedToSubmit = false;
      });
    }
  }
}
