<div class="wrapper-page faq-page">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div class="wrapper-content-page">
    <div class="wrapper-header-page-content">
      <span class="header-text">
        <i [routerLink]="['/business-profile', urlParams.companyId, 'price-list-category', urlParams.categoryId]" class="fa-solid fa-arrow-left back-btn"></i>
        {{urlParams.itemId ? 'Izmeni artikl' : 'Kreirajte novi artikl'}}
      </span>
    </div>
    <form [formGroup]="createOrEditPriceListItemForm" (ngSubmit)="submitCreateOrEditPriceListItemForm()" class="grid xs-p-32">
      <div class="grid__m-12">
        <div class="wrapper-input">
          <span class="label-input">Ime artikla</span>
          <div class="zc-input">
            <input type="text" [formControl]="createOrEditPriceListItemForm.controls.name | formControl">
          </div>
         <span class="error-message">{{createOrEditPriceListItemForm.controls.name | formControlErrorMessages: null : formTriedToSubmit}}</span>
        </div>
      </div>
      <div class="grid__m-12">
        <div class="wrapper-custom-gallery">
          <div class="header-custom-gallery">
            <span class="title-gallery">Galerija</span>
            <div class="wrapper-btn-gallery">
              <div class="file-input">
                <input
                  #fileInput
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  name="file-input"
                  id="file-input"
                  class="file-input__input"
                  multiple
                  (change)="onFileChange($event)"
                  />
                <label class="file-input__label" for="file-input"><span>Dodaj galeriju</span></label>
              </div>
              <button (click)="removeAllImages()" class="zc-btn zc-btn--delete" type="button"><span>Obriši sve slike</span></button>
            </div>
          </div>
          <div class="content-custom-gallery">
            <div class="wrapper-gallery-images">
              <div *ngFor="let imgUrl of imageUrls" class="wrapper-gallery-image">
                <img [src]="imgUrl.fullPath" alt="">
                <span class="icon-delete"
                      (click)="removeSingleImage(imgUrl)">
                    <i class="fa-regular fa-trash-can"></i>
                  </span>
              </div>
            </div>
          </div>
          <span class="error-message">{{createOrEditPriceListItemForm.controls.images | formControlErrorMessages: null : formTriedToSubmit}}</span>
        </div>
      </div>
      <div *ngIf="businessProfile?.business_category_id !== 1 && businessProfile?.business_category_id !== 4 && businessProfile?.business_category_id !== 5" class="grid__m-6">
        <gr-dropdown-reactive-form
          [items]="Units"
          [propIdentifier]="'short'"
          [propForView]="'unit'"
          [dropdownLabel]="'Jedinica mere'"
          [placeholder]="'Izaberi jedinicu mere'"
          [formControl]="createOrEditPriceListItemForm.controls.unit | formControl"
          [formTriedToSubmit]="formTriedToSubmit"
        ></gr-dropdown-reactive-form>
      </div>
      <div [ngClass]="(businessProfile?.business_category_id !== 1 && businessProfile?.business_category_id !== 4 && businessProfile?.business_category_id !== 5) ? 'grid__m-6' : 'grid__m-12' ">
        <div class="wrapper-input">
          <span class="label-input">Cena</span>
          <div class="zc-input">
            <input type="text" [formControl]="createOrEditPriceListItemForm.controls.price | formControl">
          </div>
          <span class="error-message">{{createOrEditPriceListItemForm.controls.price | formControlErrorMessages: 'Unesite validan broj.' : formTriedToSubmit}}</span>
        </div>
      </div>
      <div class="grid__m-12">
        <div class="wrapper-input">
          <span class="label-input">Kapara</span>
          <div class="zc-input">
            <input type="text" [formControl]="createOrEditPriceListItemForm.controls.down_payment | formControl">
          </div>
          <span class="error-message">{{createOrEditPriceListItemForm.controls.down_payment | formControlErrorMessages: 'Unesite validan broj.' : formTriedToSubmit}}</span>
        </div>
      </div>
      <div class="grid__m-6" *ngIf="businessProfile?.business_category_id === 6">
        <div class="wrapper-input">
          <span class="label-input">Minimalano kilograma</span>
          <div class="zc-input">
            <input type="text" [formControl]="createOrEditPriceListItemForm.controls.down_payment | formControl">
          </div>
          <span class="error-message">{{createOrEditPriceListItemForm.controls.down_payment | formControlErrorMessages: 'Unesite validan broj.' : formTriedToSubmit}}</span>
        </div>
      </div>
      <div class="grid__m-6" *ngIf="businessProfile?.business_category_id === 6">
        <div class="wrapper-input">
          <span class="label-input">Maximalno kilograma</span>
          <div class="zc-input">
            <input type="text" [formControl]="createOrEditPriceListItemForm.controls.down_payment | formControl">
          </div>
          <span class="error-message">{{createOrEditPriceListItemForm.controls.down_payment | formControlErrorMessages: 'Unesite validan broj.' : formTriedToSubmit}}</span>
        </div>
      </div>
      <div *ngIf="businessProfile?.business_category_id === 6" class="grid__m-12">
        <div class="wrapper-input">
          <span class="label-input">Broj spratova</span>
          <div class="zc-input">
            <input type="text" [formControl]="createOrEditPriceListItemForm.controls.number_of_floors | formControl">
          </div>
          <span class="error-message">{{createOrEditPriceListItemForm.controls.number_of_floors | formControlErrorMessages: 'Unesite validan broj.' : formTriedToSubmit}}</span>
        </div>
      </div>
      <div *ngIf="businessProfile?.business_category_id === 6 && createOrEditPriceListItemForm.controls.number_of_floors" class="grid__m-12">
        <div *ngFor="let n of number; index as i">
          <h3>Sprat {{i + 1}}</h3>
          <div *ngIf="businessProfile?.business_category_id === 6" class="grid__m-12">
            <div class="wrapper-input" [formArrayName]="'flavours'">
              <span class="label-input">Ukus torte</span>
              <div class="zc-input" *ngFor="let control of flavours.controls; index as i">
                <div [formGroupName]="i">
                  <input type="text" formControlName="value">
                  <div class="close-flavor" type="button" (click)="removeFlavourField(i)"><i class="fa-solid fa-trash"></i></div>
                </div>
              </div>
              <span class="error-message">{{createOrEditPriceListItemForm.controls.flavours | formControlErrorMessages: null : formTriedToSubmit}}</span>
            </div>
          </div>
          <div class="grid__m-12">
            <button style="float: right;" class="zc-btn zc-btn--primary" type="button" (click)="addNewFlavoursField()"><span>Dodaj novi ukus</span></button>
          </div>
        </div>
      </div>
      <div class="grid__m-12">
        <div class="wrapper-quill-editor item-category xs-mr-0 xs-ml-0 xs-mt-0">
          <span class="title-editor">Opis artikla</span>
          <quill-editor
            [placeholder]="'Unesite opis...'"
            [formControl]="createOrEditPriceListItemForm.controls.description | formControl"
          ></quill-editor>
          <span class="error-message">{{createOrEditPriceListItemForm.controls.description | formControlErrorMessages: null : formTriedToSubmit}}</span>
        </div>
      </div>
      <div class="grid__m-12">
        <button style="float: right;" class="zc-btn zc-btn--primary" type="submit"><span>{{urlParams.itemId ? 'Sačuvaj izmene' : 'Sačuvaj'}}</span></button>
      </div>
    </form>
  </div>
</div>


