import {Component, OnInit} from '@angular/core';
import {GrPagination, GrUser} from '../../interfaces/general-interfaces';
import {UsersHttpService} from '../../services/http-services/users/users-http.service';
import {ToastrService} from 'ngx-toastr';
import {GetUsersApiDataFormatted} from '../../services/http-services/users/users-http-interfaces';
import {UserRole} from '../../enums/general-enums';

@Component({
  selector: 'gr-user-list',
  templateUrl: './user-list.component.html'
})
export class UserListComponent implements OnInit {
  usersList: GrUser[] = [];
  userForEdit: GrUser | null = null;
  userForDelete: GrUser | null = null;
  usersPagination: GrPagination | undefined;
  urlParams: any = {page: 1, role: UserRole.user, search: null, sort: 'first_name', direction: 'asc'};
  constructor(private usersHttpService: UsersHttpService,
              private toastService: ToastrService) {
  }
  ngOnInit(): void {
    this.getUsers();
  }

  getUsers(): void {
    this.urlParams.page = (this.usersPagination?.currentPage) ? this.usersPagination.currentPage : 1;
    this.usersHttpService.getUsers(this.urlParams).then((res: GetUsersApiDataFormatted) => {
      this.usersList = res.data;
      this.usersPagination = res.pagination;
    });
  }

  onSortChange(type: string): void {
    if (this.urlParams.sort === type) {
      this.urlParams.direction === 'asc' ? this.urlParams.direction = 'desc' : this.urlParams.direction = 'asc';
    } else {
      this.urlParams.sort = type;
      this.urlParams.direction = 'asc';
    }
    this.getUsers();
  }

  onSearchChange(searchQuery: string): void {
    this.urlParams.search = searchQuery;
    this.usersPagination = undefined;
    this.getUsers();
  }

  onPageChanged(page: any): void {
    if (this.usersPagination) {
      this.usersPagination.currentPage = page;
      this.getUsers();
    }
  }

  onConfirmEditUser(): void {
    this.userForEdit = null;
    this.getUsers();
  }

  removeUser(userId: number | null): void {
    if (userId) {
      this.usersHttpService.deleteUser({userId}).then(res => {
        this.getUsers();
        this.toastService.success('Uspešno ste obrisali korisnika.');
      }, error => {
        this.toastService.warning('Došlo je do greške, pokušajte ponovo.');
      });
    }
  }
}


