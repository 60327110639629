<div class="wrapper-page faq-page">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div class="wrapper-content-page">
    <div class="wrapper-header-page-content">
      <span class="header-text">Obaveštenja</span>
      <button class="zc-btn zc-btn--primary" (click)="isCreateNotificationModalOpen = true"><span>Kreiraj notifikaciju</span></button>
    </div>
    <div class="zc-wrapper-tabs">
      <div class="zc-tab" (click)="activeTab = NotificationTabsEnums.users" [ngClass]="{'active': activeTab === NotificationTabsEnums.users}">
        <span>Korisnici</span>
      </div>
      <div class="zc-tab" (click)="activeTab = NotificationTabsEnums.payment" [ngClass]="{'active': activeTab === NotificationTabsEnums.payment}">
        <span>Plaćanje</span>
      </div>
      <div class="zc-tab" (click)="activeTab = NotificationTabsEnums.support" [ngClass]="{'active': activeTab === NotificationTabsEnums.support}">
        <span>Podrška</span>
      </div>
      <div class="zc-tab" (click)="activeTab = NotificationTabsEnums.custom" [ngClass]="{'active': activeTab === NotificationTabsEnums.custom}">
        <span>Kreirane notifikacije</span>
      </div>
    </div>
    <gr-notification-users-tab
      *ngIf="activeTab === NotificationTabsEnums.users">
    </gr-notification-users-tab>
    <gr-notification-payment-tab
      *ngIf="activeTab === NotificationTabsEnums.payment">
    </gr-notification-payment-tab>
    <gr-notification-support-tab
      *ngIf="activeTab === NotificationTabsEnums.support">
    </gr-notification-support-tab>
    <gr-notification-custom-tab
      *ngIf="activeTab === NotificationTabsEnums.custom"
      (editNotification)="customNotificationForEdit = $event">
    </gr-notification-custom-tab>
  </div>
</div>

<gr-create-notification-modal
  *ngIf="isCreateNotificationModalOpen || customNotificationForEdit"
  [notificationForEdit]="customNotificationForEdit"
  (closeModal)="isCreateNotificationModalOpen = false; customNotificationForEdit = null"
></gr-create-notification-modal>
