<div class="wrapper-page settings">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div class="wrapper-content-page">
    <div class="wrapper-header-page-content">
      <span class="header-text">Podešavanja</span>
    </div>
    <div class="grid">
      <div class="grid__m-6">
        <form [formGroup]="settingsUserProfileForm" (ngSubmit)="onSubmitSettingsUserProfileForm()" class="grid xs-pr-32 xs-pl-32 xs-pt-32">
          <div class="grid__m-12">
            <span class="subtitle-page">Osnovni podaci</span>
          </div>
          <div class="grid__m-12">
            <div class="wrapper-input">
              <span class="label-input">Ime</span>
              <div class="zc-input">
                <input type="text" [formControl]="settingsUserProfileForm.controls.first_name | formControl" [ngClass]="{'not-allowed': isUserProfileFormDisabled}">
              </div>
              <span class="error-message">{{settingsUserProfileForm.controls.first_name | formControlErrorMessages: null : formUserProfileTriedToSubmit}}</span>
            </div>
          </div>
          <div class="grid__m-12">
            <div class="wrapper-input">
              <span class="label-input">Prezime</span>
              <div class="zc-input">
                <input type="text" [formControl]="settingsUserProfileForm.controls.last_name | formControl" [ngClass]="{'not-allowed': isUserProfileFormDisabled}">
              </div>
              <span class="error-message">{{settingsUserProfileForm.controls.last_name | formControlErrorMessages: null : formUserProfileTriedToSubmit}}</span>
            </div>
          </div>
          <div class="grid__m-12">
            <div class="wrapper-input">
              <span class="label-input">E-pošta</span>
              <div class="zc-input">
                <input type="email" [formControl]="settingsUserProfileForm.controls.email | formControl" [ngClass]="{'not-allowed': isUserProfileFormDisabled}">
              </div>
              <span class="error-message">{{settingsUserProfileForm.controls.email | formControlErrorMessages: 'Unesite validnu email adresu.' : formUserProfileTriedToSubmit}}</span>
            </div>
          </div>
          <div class="grid__m-12">
            <div class="wrapper-input">
              <span class="label-input">Telefon</span>
              <div class="zc-input">
                <input type="text" [formControl]="settingsUserProfileForm.controls.phone | formControl" [ngClass]="{'not-allowed': isUserProfileFormDisabled}">
              </div>
              <span class="error-message">{{settingsUserProfileForm.controls.phone | formControlErrorMessages: 'Unesite validan broj.' : formUserProfileTriedToSubmit}}</span>
            </div>
          </div>
          <div class="grid__m-12">
            <button *ngIf="isUserProfileFormDisabled" (click)="enableUserProfileForm()" type="button" class="zc-btn zc-btn--primary"><span>Izmeni podatke</span></button>
            <button *ngIf="!isUserProfileFormDisabled" type="submit" class="zc-btn zc-btn--primary"><span>Sačuvaj</span></button>
          </div>
        </form>
      </div>
      <div class="grid__m-6">
        <form [formGroup]="settingsUserPasswordForm" (ngSubmit)="onSubmitSettingsUserPasswordForm()" class="grid xs-pr-32 xs-pl-32 xs-pt-32">
          <div class="grid__m-12">
            <span class="subtitle-page">Podaci o lozinki</span>
          </div>
          <div class="grid__m-12">
            <div class="wrapper-input">
              <span class="label-input">Lozinka</span>
              <div class="zc-input">
                <input [type]="isOldPasswordShow ? 'text' : 'password'" [formControl]="settingsUserPasswordForm.controls.old_password | formControl" [ngClass]="{'not-allowed': isUserPasswordFormDisabled}">
                <i *ngIf="!isOldPasswordShow" (click)="isOldPasswordShow = true" class="fa-solid fa-eye"></i>
                <i *ngIf="isOldPasswordShow" (click)="isOldPasswordShow = false" class="fa-solid fa-eye-slash"></i>
              </div>
              <span class="error-message">{{settingsUserPasswordForm.controls.old_password | formControlErrorMessages: 'Minimum 8 karaktera, jedno veliko slovo i jedan specijalni karakter.' : formUserPasswordTriedToSubmit}}</span>
            </div>
          </div>
          <div class="grid__m-12">
            <div class="wrapper-input">
              <span class="label-input">Nova lozinka</span>
              <div class="zc-input">
                <input [type]="isPasswordShow ? 'text' : 'password'" [formControl]="settingsUserPasswordForm.controls.password | formControl" [ngClass]="{'not-allowed': isUserPasswordFormDisabled}">
                <i *ngIf="!isPasswordShow" (click)="isPasswordShow = true" class="fa-solid fa-eye"></i>
                <i *ngIf="isPasswordShow" (click)="isPasswordShow = false" class="fa-solid fa-eye-slash"></i>
              </div>
              <span class="error-message">{{settingsUserPasswordForm.controls.password | formControlErrorMessages: 'Minimum 8 karaktera, jedno veliko slovo i jedan specijalni karakter.' : formUserPasswordTriedToSubmit}}</span>
            </div>
          </div>
          <div class="grid__m-12">
            <div class="wrapper-input">
              <span class="label-input">Potvrdite lozinku</span>
              <div class="zc-input">
                <input [type]="isConfirmPasswordShow ? 'text' : 'password'" [formControl]="settingsUserPasswordForm.controls['password_confirmation'] | formControl" [ngClass]="{'not-allowed': isUserPasswordFormDisabled}">
                <i *ngIf="!isConfirmPasswordShow" (click)="isConfirmPasswordShow = true" class="fa-solid fa-eye"></i>
                <i *ngIf="isConfirmPasswordShow" (click)="isConfirmPasswordShow = false" class="fa-solid fa-eye-slash"></i>
              </div>
              <span class="error-message">{{settingsUserPasswordForm.controls['password_confirmation'] | formControlErrorMessages: 'Minimum 8 karaktera, jedno veliko slovo i jedan specijalni karakter.' : formUserPasswordTriedToSubmit}}</span>
            </div>
          </div>
          <div class="grid__m-12">
            <button *ngIf="isUserPasswordFormDisabled" (click)="enableUserPasswordForm()" type="button" class="zc-btn zc-btn--primary"><span>Izmeni lozinku</span></button>
            <button *ngIf="!isUserPasswordFormDisabled" type="submit" class="zc-btn zc-btn--primary"><span>Sačuvaj</span></button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
