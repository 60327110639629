import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EmailRegex, PasswordRegex, PhoneRegex} from '../../constants/general-variables';
import {confirmMatchControlsValidator} from '../../validators/form-validators.validator';
import {ToastrService} from 'ngx-toastr';
import {UsersHttpService} from '../../services/http-services/users/users-http.service';
import {AuthenticationHelperService} from '../../services/helpers/authentication-helper.service';

@Component({
  selector: 'gr-settings',
  templateUrl: './settings.component.html',
})
export class SettingsComponent implements OnInit{
  settingsUserProfileForm!: FormGroup;
  settingsUserPasswordForm!: FormGroup;
  isPasswordShow = false;
  isOldPasswordShow = false;
  isConfirmPasswordShow = false;
  formUserProfileTriedToSubmit = false;
  formUserPasswordTriedToSubmit = false;
  isUserProfileFormDisabled = true;
  isUserPasswordFormDisabled = true;
  constructor(private formBuilder: FormBuilder,
              private toastrService: ToastrService,
              private authenticationHelperService: AuthenticationHelperService,
              private usersHttpService: UsersHttpService) {
  }
  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.settingsUserProfileForm = this.formBuilder.group({
      first_name: ['', [Validators.required, Validators.minLength(2)]],
      last_name: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.required, Validators.pattern(EmailRegex)]],
      phone: ['', [Validators.required, Validators.pattern(PhoneRegex)]],
    });
    this.settingsUserProfileForm.patchValue(this.authenticationHelperService.getUserData);

    this.settingsUserPasswordForm = this.formBuilder.group({
      old_password: ['', [Validators.required, Validators.pattern(PasswordRegex)]],
      password: ['', [Validators.required, Validators.pattern(PasswordRegex)]],
      password_confirmation: ['', [Validators.required, Validators.pattern(PasswordRegex)]],
    }, {
      validators: confirmMatchControlsValidator('password', 'password_confirmation')
    });
    this.disabledUserProfileForm();
    this.disabledUserPasswordForm();
  }

  enableUserProfileForm(): void {
    this.isUserProfileFormDisabled = false
    this.settingsUserProfileForm.enable()
  }
  disabledUserProfileForm(): void {
    this.isUserProfileFormDisabled = true;
    this.settingsUserProfileForm.disable()
  }

  enableUserPasswordForm(): void {
    this.isUserPasswordFormDisabled = false
    this.settingsUserPasswordForm.enable()
  }
  disabledUserPasswordForm(): void {
    this.isUserPasswordFormDisabled = true;
    this.settingsUserPasswordForm.disable()
  }

  onSubmitSettingsUserProfileForm(): void {
    this.formUserProfileTriedToSubmit = true;
    if (this.settingsUserProfileForm.valid) {
      this.usersHttpService.putAuthUser(this.settingsUserProfileForm.value).then((res: any) => {
        this.disabledUserProfileForm();
        this.toastrService.success('Uspešno ste izmenili podatke.');
        this.formUserProfileTriedToSubmit = false;
      });
    }
  }

  onSubmitSettingsUserPasswordForm(): void {
    this.formUserPasswordTriedToSubmit = true;
    if (this.settingsUserProfileForm.valid) {
      this.usersHttpService.putAuthUserPassword(this.settingsUserPasswordForm.value).then((res: any) => {
        this.disabledUserPasswordForm();
        this.toastrService.success('Uspešno ste izmenili lozinku.');
        this.formUserPasswordTriedToSubmit = false;
      });
    }
  }
}
