<div class="zc-wrapper-datepicker">
  <div class="label-datepicker">{{datepickerLabel}}</div>
  <div class="wrapper-datepicker-content" (click)="datepicker.open()">
    <input
      matInput
      [matDatepicker]="datepicker"
      [min]="currentDate"
      [(ngModel)]="selectedDate"
      [placeholder]="'Select a date'"
      (dateChange)="onDateChange($event)"
      readonly>
    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
    <mat-datepicker #datepicker></mat-datepicker>
  </div>
</div>


