import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {GeneralHttpHelperService} from '../../helpers/general-http-helper.service';
import {HttpClient} from '@angular/common/http';

const SETTINGS_API_ROUTES = {
  GET_ADMINISTRATION: 'admin/administrations',
  PUT_ADMINISTRATION: 'admin/administrations/1',
  GET_DELIVERIES: 'admin/deliveries',
  POST_DELIVERIES: 'admin/deliveries',
  PUT_DELIVERIE: 'admin/deliveries/:id',
  DELETE_DELIVERIE: 'admin/deliveries/:id',
};
@Injectable({
  providedIn: 'root'
})
export class SettingsHttpService {

  constructor(private generalHttpHelperService: GeneralHttpHelperService,
              private httpClient: HttpClient) { }

  private getDeliveriesReq(): Observable<any> {
    return this.httpClient.get(SETTINGS_API_ROUTES.GET_DELIVERIES) as any;
  }

  getDeliveries(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getDeliveriesReq().subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private postDeliveryReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(SETTINGS_API_ROUTES.POST_DELIVERIES, bodyParams) as any;
  }

  postDelivery(bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.postDeliveryReq(bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private putDeliveryReq(urlParams: any, bodyParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(SETTINGS_API_ROUTES.PUT_DELIVERIE, urlParams);
    return this.httpClient.put(apiUrl, bodyParams) as any;
  }

  putDelivery(urlParams: any, bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.putDeliveryReq(urlParams, bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private deleteDeliveryReq(urlParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(SETTINGS_API_ROUTES.DELETE_DELIVERIE, urlParams);
    return this.httpClient.delete(apiUrl) as any;
  }

  deleteDelivery(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.deleteDeliveryReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private getAdministrationReq(): Observable<any> {
    return this.httpClient.get(SETTINGS_API_ROUTES.GET_ADMINISTRATION) as any;
  }

  getAdministration(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getAdministrationReq().subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private putAdministrationReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(SETTINGS_API_ROUTES.PUT_ADMINISTRATION, bodyParams) as any;
  }

  putAdministration(bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.putAdministrationReq(bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
}
