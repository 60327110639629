import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {PriceListCategoryComponent} from '../price-list-category/price-list-category.component';
import {AuthGuardService} from '../../../guards/auth-guard.service';
import {PriceListCategoryItemCreateOrEditComponent} from './price-list-category-item-create-or-edit.component';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.priceListCategoryItemCreate.pageInRouting,
    data: {data: PAGES_AND_PATHS.priceListCategoryItemCreate},
    component: PriceListCategoryItemCreateOrEditComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./price-list-category-item-create-or-edit.module').then(m => m.PriceListCategoryItemCreateOrEditModule)
  },
  {
    path: PAGES_AND_PATHS.priceListCategoryItemEdit.pageInRouting,
    data: {data: PAGES_AND_PATHS.priceListCategoryItemEdit},
    component: PriceListCategoryItemCreateOrEditComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./price-list-category-item-create-or-edit.module').then(m => m.PriceListCategoryItemCreateOrEditModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PriceListCategoryItemCreateOrEditRoutingModule { }
