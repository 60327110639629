import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GrUser} from '../../../../interfaces/general-interfaces';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EmailRegex, PhoneRegex} from '../../../../constants/general-variables';
import {UsersHttpService} from '../../../../services/http-services/users/users-http.service';
import {ToastrService} from 'ngx-toastr';
import {GeneralHttpService} from '../../../../services/http-services/general/general-http.service';

@Component({
  selector: 'gr-edit-business-user-modal',
  templateUrl: './edit-business-user-modal.component.html'
})
export class EditBusinessUserModalComponent implements OnInit{
  @Input() businessUserForEdit!: GrUser;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmModal: EventEmitter<void> = new EventEmitter<void>();
  editBusinessUserForm!: FormGroup;
  formTriedToSubmit = false;

  constructor(private formBuilder: FormBuilder,
              private toastService: ToastrService,
              private generalHttpService: GeneralHttpService,
              private usersHttpService: UsersHttpService) {
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm(): void {
    this.editBusinessUserForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(EmailRegex)]],
      phone: ['', [Validators.required, Validators.pattern(PhoneRegex)]],
    });
    this.editBusinessUserForm.patchValue(this.businessUserForEdit);
  }

  submitEditBusinessUserForm(): void {
    this.formTriedToSubmit = true;
    if (this.editBusinessUserForm.valid) {
      this.usersHttpService.putUser({userId: this.businessUserForEdit.id}, this.editBusinessUserForm.value).then(res => {
        this.toastService.success(res.message);
        this.confirmModal.emit();
      }).catch(({error}: {error:{ errors: { [key: string]: string } }}) => {
        Object.values(error.errors).forEach(error => this.toastService.error(error));
      })
    }
  }
}

