<div class="wrapper-login-page">
  <div class="content-login-left">
    <div class="overlay-login-image"></div>
  </div>
  <div class="content-login-right">
    <div class="content-login">
      <img src="../../../assets/images/logo.svg" alt="">
      <span class="title">Zaboravljena lozinka</span>
      <span class="subtitle">Unesite vašu e-poštu i promenite lozinku</span>
      <form [formGroup]="forgotPasswordForm" (ngSubmit)="submitForgotPasswordForm()">
        <div class="wrapper-input">
          <span class="label-input">E-pošta</span>
          <div class="zc-input">
            <input type="email"[placeholder]="'E-pošta'" [formControl]="forgotPasswordForm.controls.email | formControl">
          </div>
          <span class="error-message">{{forgotPasswordForm.controls.email | formControlErrorMessages: 'Unesite validnu email adresu.' : formTriedToSubmit}}</span>
        </div>
        <a class="zc-link-label" href="javascript:void(0)" [routerLink]="[PAGES_AND_PATHS.login.pagePath]">Prijavite se</a>
        <button class="zc-btn zc-btn--primary" type="submit"><span>Pošalji</span></button>
      </form>
    </div>
  </div>
</div>
