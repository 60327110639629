<form [formGroup]="editBusinessProfileForm"
      (ngSubmit)="submitEditBusinessProfileForm()"
      class="grid xs-pr-32 xs-pl-32 xs-pt-32">
  <div class="grid__m-12">
    <div class="wrapper-custom-radiobutton">
      <label>
        <input type="radio" formControlName="active" [ngClass]="isBusinessProfileFormDisabled ? 'not-allowed' : ''"
               [value]="true">
        <div class="custom-radiobutton">
          <div class="circle-radio">
            <div class="circle-center"></div>
          </div>
          <span>Aktivan</span>
        </div>
      </label>
      <label>
        <input type="radio" formControlName="active" [ngClass]="isBusinessProfileFormDisabled ? 'not-allowed' : ''"
               [value]="false">
        <div class="custom-radiobutton">
          <div class="circle-radio">
            <div class="circle-center"></div>
          </div>
          <span>Neaktivan</span>
        </div>
      </label>
    </div>
  </div>
  <div class="grid__m-6">
    <div class="wrapper-input">
      <span class="label-input">Naziv firme</span>
      <div class="zc-input">
        <input type="text" [formControl]="editBusinessProfileForm.controls.name | formControl"
               [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}" [placeholder]="'Naziv firme'">
      </div>
      <span
        class="error-message">{{editBusinessProfileForm.controls.name | formControlErrorMessages: null : formTriedToSubmit}}</span>
    </div>
    <div class="wrapper-input">
      <span class="label-input">Adresa firme</span>
      <div class="zc-input">
        <input type="text" [formControl]="editBusinessProfileForm.controls.address | formControl"
               [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}" [placeholder]="'Adresa firme'">
      </div>
      <span
        class="error-message">{{editBusinessProfileForm.controls.address | formControlErrorMessages: null : formTriedToSubmit}}</span>
    </div>
    <gr-dropdown-reactive-form
      [items]="listOfCities"
      [propIdentifier]="'id'"
      [disableSelect]="isBusinessProfileFormDisabled"
      [dropdownLabel]="'Grad firme'"
      [placeholder]="'Grad firme'"
      [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}"
      [formControl]="editBusinessProfileForm.controls.business_location_id | formControl"
      [formTriedToSubmit]="formTriedToSubmit"
    ></gr-dropdown-reactive-form>
    <gr-dropdown-reactive-form
      [items]="listOfMunicipalities"
      [propIdentifier]="'id'"
      [disableSelect]="isBusinessProfileFormDisabled"
      [dropdownLabel]="'Opština firme'"
      [placeholder]="'Opština firme'"
      [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}"
      [formControl]="editBusinessProfileForm.controls.municipality_id | formControl"
      [formTriedToSubmit]="formTriedToSubmit"
    ></gr-dropdown-reactive-form>
    <gr-dropdown-reactive-form
      [items]="listOfMunicipalities"
      [isMultiSelect]="true"
      [propIdentifier]="'id'"
      [disableSelect]="isBusinessProfileFormDisabled"
      [dropdownLabel]="'Opštine obavljanja delatnosti'"
      [placeholder]="'Opštine obavljanja delatnosti'"
      [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}"
      [formControl]="editBusinessProfileForm.controls.workingMunicipalities | formControl"
      [formTriedToSubmit]="formTriedToSubmit"
    ></gr-dropdown-reactive-form>
    <div class="grid__m-6">
      <div class="wrapper-input">
        <span class="label-input">Rabat</span>
        <div class="zc-input">
          <input type="text" formControlName="rebate" [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}" [placeholder]="'Rabat'">
        </div>
        <span class="error-message">{{editBusinessProfileForm.controls.rebate | formControlErrorMessages: null : formTriedToSubmit}}</span>
      </div>
    </div>
    <div *ngIf="businessProfileData?.business_category_id === 6 || businessProfileData?.business_category_id === 3" class="grid__m-6">
      <div class="wrapper-input">
        <span class="label-input">{{businessProfileData?.business_category_id === 6 ? 'Maximalan broj torti u jednom danu' : 'Maximalan broj paketa u jednom danu'}}</span>
        <div class="zc-input">
          <input type="text" [formControl]="editBusinessProfileForm.controls.max_order_quantity | formControl" [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}" [placeholder]="'Maximalan broj torti u jednom danu'">
        </div>
        <span class="error-message">{{editBusinessProfileForm.controls.max_order_quantity | formControlErrorMessages: 'Unesite validan broj.' : formTriedToSubmit}}</span>
      </div>
    </div>
    <div class="wrapper-input">
      <span class="label-input">Fiksni telefon firme</span>
      <div class="zc-input">
        <input type="text" [formControl]="editBusinessProfileForm.controls.phone_number | formControl"
               [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}" [placeholder]="'Fiksni telefon firme'">
      </div>
      <span
        class="error-message">{{editBusinessProfileForm.controls.phone_number | formControlErrorMessages: 'Unesite validan broj telefona.' : formTriedToSubmit}}</span>
    </div>
    <div class="wrapper-input">
      <span class="label-input">Mobilni telefon firme</span>
      <div class="zc-input">
        <input type="text" [formControl]="editBusinessProfileForm.controls.mobile_number | formControl"
               [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}" [placeholder]="'Mobilni telefon firme'">
      </div>
      <span
        class="error-message">{{editBusinessProfileForm.controls.mobile_number | formControlErrorMessages: 'Unesite validan broj telefona.' : formTriedToSubmit}}</span>
    </div>
    <div class="wrapper-input">
      <span class="label-input">Matični broj</span>
      <div class="zc-input">
        <input type="text" [formControl]="editBusinessProfileForm.controls.identification_number | formControl"
               [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}" [placeholder]="'Matični broj'">
      </div>
      <span
        class="error-message">{{editBusinessProfileForm.controls.identification_number | formControlErrorMessages: 'Unesite validan matični broj.' : formTriedToSubmit}}</span>
    </div>
    <div class="wrapper-input">
      <span class="label-input">PIB</span>
      <div class="zc-input">
        <input type="text" [formControl]="editBusinessProfileForm.controls.tax_identification_number | formControl"
               [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}" [placeholder]="'PIB'">
      </div>
      <span
        class="error-message">{{editBusinessProfileForm.controls.tax_identification_number | formControlErrorMessages: 'Unesite validan PIB broj.' : formTriedToSubmit}}</span>
    </div>
    <div class="wrapper-checkbox-custom">
      <div>
        <input class="checkbox-custom" [disabled]="isBusinessProfileFormDisabled" type="checkbox"
               id="highlightedBusiness"
               [formControl]="editBusinessProfileForm.controls.highlighted | formControl"
               [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}" [placeholder]="'Naziv firme'">
        <label class="checkbox-custom-label" for="highlightedBusiness">Istaknut</label>
      </div>
      <div>
        <input class="checkbox-custom" [disabled]="isBusinessProfileFormDisabled" type="checkbox"
               id="recommendedBusiness"
               [formControl]="editBusinessProfileForm.controls.recommended | formControl"
               [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}" [placeholder]="'Naziv firme'">
        <label class="checkbox-custom-label" for="recommendedBusiness">Preporuka</label>
      </div>
    </div>
  </div>
  <div class="grid__m-6">
    <div class="wrapper-input">
      <span class="label-input">Početak radnog vremena</span>
      <div class="wrapper-timepicker">
        <mat-form-field>
          <input matInput [ngxTimepicker]="startPicker" [format]="24" placeholder="Select time"
                 [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}"
                 formControlName="time_start" readonly>
          <ngx-material-timepicker #startPicker></ngx-material-timepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="wrapper-input">
      <span class="label-input">Kraj radnog vremena</span>
      <div class="wrapper-timepicker">
        <mat-form-field>
          <input matInput [ngxTimepicker]="endPicker" [format]="24" placeholder="Select time"
                 [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}" formControlName="time_end"
                 readonly>
          <ngx-material-timepicker #endPicker></ngx-material-timepicker>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="editBusinessProfileForm.controls.business_category_id.value === 1" class="wrapper-input">
      <span class="label-input">Minimalni uzrast</span>
      <div class="zc-input">
        <input type="text" [formControl]="editBusinessProfileForm.controls.age_range_from | formControl"
               [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}" [placeholder]="'Primer: 3-10'">
      </div>
      <span class="error-message">{{editBusinessProfileForm.controls.age_range_from | formControlErrorMessages: 'Unesite validan format.' : formTriedToSubmit}}</span>
    </div>
    <div *ngIf="editBusinessProfileForm.controls.business_category_id.value === 1" class="wrapper-input">
      <span class="label-input">Maximalni uzrast</span>
      <div class="zc-input">
        <input type="text" [formControl]="editBusinessProfileForm.controls.age_range_to | formControl"
               [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}" [placeholder]="'Primer: 3-10'">
      </div>
      <span class="error-message">{{editBusinessProfileForm.controls.age_range_to | formControlErrorMessages: 'Unesite validan format.' : formTriedToSubmit}}</span>
    </div>
    <div *ngIf="editBusinessProfileForm.controls.business_category_id.value === 1" class="wrapper-input">
      <span class="label-input">Kapacitet dece</span>
      <div class="zc-input">
        <input type="text" [formControl]="editBusinessProfileForm.controls.children_capacity | formControl"
               [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}" [placeholder]="'Primer: 30'">
      </div>
      <span
        class="error-message">{{editBusinessProfileForm.controls.children_capacity | formControlErrorMessages: 'Unesite validan broj.' : formTriedToSubmit}}</span>
    </div>
    <div *ngIf="editBusinessProfileForm.controls.business_category_id.value === 1" class="wrapper-input">
      <span class="label-input">Kapacitet odraslih</span>
      <div class="zc-input">
        <input type="text" [formControl]="editBusinessProfileForm.controls.adult_capacity | formControl"
               [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}" [placeholder]="'Primer: 10'">
      </div>
      <span
        class="error-message">{{editBusinessProfileForm.controls.adult_capacity | formControlErrorMessages: 'Unesite validan broj.' : formTriedToSubmit}}</span>
    </div>
    <div class="wrapper-input">
      <span class="label-input">Geografska širina</span>
      <div class="zc-input">
        <input type="text" [formControl]="editBusinessProfileForm.controls.latitude | formControl"
               [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}" [placeholder]="'Primer: 43.23233'">
      </div>
      <span
        class="error-message">{{editBusinessProfileForm.controls.latitude | formControlErrorMessages: 'Unesite validan broj.' : formTriedToSubmit}}</span>
    </div>
    <div class="wrapper-input">
      <span class="label-input">Geografska dužina</span>
      <div class="zc-input">
        <input type="text" [formControl]="editBusinessProfileForm.controls.longitude | formControl"
               [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}" [placeholder]="'Primer: 43.23233'">
      </div>
      <span class="error-message">{{editBusinessProfileForm.controls.longitude | formControlErrorMessages: 'Unesite validan broj.' : formTriedToSubmit}}</span>
    </div>
    <gr-dropdown-reactive-form
      [items]="listOfCategories"
      [propIdentifier]="'id'"
      [dropdownLabel]="'Kategorije'"
      [disableSelect]="isBusinessProfileFormDisabled || isDisabledBusinessCategory"
      [ngClass]="{'not-allowed': isBusinessProfileFormDisabled || isDisabledBusinessCategory}"
      [placeholder]="'Izaberi kategoriju'"
      [formControl]="editBusinessProfileForm.controls.business_category_id | formControl"
      [formTriedToSubmit]="formTriedToSubmit"
    ></gr-dropdown-reactive-form>
    <gr-dropdown-reactive-form
      [items]="listOfPictograms"
      [isMultiSelect]="true"
      [propIdentifier]="'id'"
      [disableSelect]="isBusinessProfileFormDisabled"
      [dropdownLabel]="'Karakteristike (piktogrami)'"
      [placeholder]="'Odaberi'"
      [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}"
      [formControl]="editBusinessProfileForm.controls.pictograms_ids | formControl"
      [formTriedToSubmit]="formTriedToSubmit"
    ></gr-dropdown-reactive-form>
  </div>
  <div class="grid__m-12">
    <div class="wrapper-custom-gallery">
      <div class="header-custom-gallery">
        <span class="title-gallery">Galerija</span>
        <div class="wrapper-btn-gallery">
          <div class="file-input">
            <input
              #fileInput
              type="file"
              accept=".jpg,.jpeg,.png,.svg"
              name="file-input"
              id="file-input"
              [disabled]="isBusinessProfileFormDisabled"
              [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}"
              class="file-input__input"
              multiple
              (change)="onFileChange($event)"
            />
            <label class="file-input__label" for="file-input"><span>Dodaj galeriju</span></label>
          </div>
          <button (click)="removeAllImages()" [disabled]="isBusinessProfileFormDisabled"
                  [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}" class="zc-btn zc-btn--delete"
                  type="button"><span>Obriši sve slike</span></button>
        </div>
      </div>
      <div class="content-custom-gallery">
        <div class="wrapper-gallery-images">
          <div *ngFor="let imgUrl of imageUrls" class="wrapper-gallery-image">
            <img [src]="imgUrl.fullPath" alt="">
            <span [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}" class="icon-delete"
                  (click)="removeSingleImage(imgUrl)">
                    <i class="fa-regular fa-trash-can"></i>
                  </span>
          </div>
        </div>
      </div>
      <span class="error-message">{{editBusinessProfileForm.controls.images | formControlErrorMessages: null : formTriedToSubmit}}</span>
    </div>
  </div>
  <div class="grid__m-12">
    <div class="wrapper-quill-editor xs-mr-0 xs-ml-0 xs-mt-0">
      <span class="title-editor">Opis</span>
      <quill-editor
        [placeholder]="'Unesite opis...'"
        [ngClass]="{'not-allowed': isBusinessProfileFormDisabled}"
        [formControl]="editBusinessProfileForm.controls.description | formControl"></quill-editor>
      <span
        class="error-message">{{editBusinessProfileForm.controls.description | formControlErrorMessages: null : formTriedToSubmit}}</span>
    </div>
  </div>
  <div class="grid__m-12">
    <div class="wrapper-save-btn">
      <button *ngIf="isBusinessProfileFormDisabled"
              (click)="enableBusinessProfileForm()"
              class="zc-btn zc-btn--primary" type="button"><span>Izmeni</span>
      </button>
      <button *ngIf="!isBusinessProfileFormDisabled" class="zc-btn zc-btn--primary" type="submit">
        <span>Sačuvaj</span>
      </button>
    </div>
  </div>
</form>
