<div class="grid">
  <div class="grid__m-6">
    <span class="terms-title">Slobodni termini</span>
    <span class="terms-subtitle">Kalendar pokazuje, u plavom okviru sve dane kada je igraonica otvorena, s slobodnim terminima. Ukoliko neki dan igraonica ne radi, možete manuelno “zatvoriti” taj dan klikom na datum.</span>
    <div class="wrapper-datepicker">
      <mat-card *ngIf="termsData && calendarDateKeys?.length" class="demo-inline-calendar-card">
        <mat-calendar #calendar [dateClass]="dateClass" [selected]="selected" [minDate]="minDate" [dateFilter]="filteredDate" (selectedChange)="onCalendarDateChange($event)"></mat-calendar>
      </mat-card>
    </div>
  </div>
  <div class="grid__m-6 xs-pl-32">
    <form [formGroup]="minimalPriceForm" (ngSubmit)="onSubmitMinimalPriceForm()">
      <span class="terms-title">Definiši minimualnu cenu trajanja termina</span>
      <span class="terms-subtitle">Kalendar pokazuje, u plavom okviru sve dane kada je igraonica otvorena, s slobodnim terminima. Ukoliko neki dan igraonica ne radi, možete manuelno “zatvoriti” taj dan klikom na datum.</span>
      <div class="wrapper-input">
        <div class="zc-input">
          <input formControlName="price" type="text" placeholder="Cena (RSD)">
        </div>
        <span class="error-message">{{minimalPriceForm.controls.price | formControlErrorMessages: null : formTriedToSubmit}}</span>
      </div>
      <div class="wrapper-checkbox-custom">
        <div>
          <input class="checkbox-custom" type="checkbox" id="checkbox12" formControlName="every_day_same_price">
          <label class="checkbox-custom-label" for="checkbox12">Svi dani i temini imaju istu cenu</label>
        </div>
      </div>
      <div class="grid__m-12">
        <div class="zc-d-flex zc-align-items zc-justify-end xs-mb-32">
          <button class="zc-btn zc-btn--primary" type="submit"><span>Sačuvaj</span></button>
        </div>
      </div>
    </form>
    <form [formGroup]="differentPriceForm" (ngSubmit)="onSubmitDifferentPriceForm()">
      <span class="terms-title xs-mt-32">Definiš drugu cenu termina</span>
      <span class="terms-subtitle">Ukoliko nekim danima cena je skuplja od minimalne cene, odaberite dan i termin i promenite cenu ručno.</span>
      <gr-dropdown
        [items]="calendarDateKeys"
        [propForView]="'date'"
        (selectItem)="onSelectedDateOfTerm($event)"
      ></gr-dropdown>
      <div class="wrapper-checkbox-custom">
        <div>
          <input class="checkbox-custom" type="checkbox" id="checkbox13" formControlName="every_term_same_price">
          <label class="checkbox-custom-label" for="checkbox13">Svi termini imaju istu novu cenu za odabrani dan</label>
        </div>
      </div>
      <div class="xs-mt-32">
        <div *ngIf="!this.differentPriceForm.controls.every_term_same_price.value" class="wrapper-time-terms">
          <div *ngFor="let terms of selectedTermsDate" class="time-term" (click)="onSelectTerms(terms)" [ngClass]="{'active': (terms.start_time | slice: 0: -3) === differentPriceForm.controls.time.value}">
            <span>{{terms.start_time | slice: 0: -3}} - {{terms.end_time | slice: 0: -3}}</span>
          </div>
        </div>
      </div>
      <div class="wrapper-input">
        <div class="zc-input">
          <input type="text" formControlName="price" placeholder="Cena (RSD)">
        </div>
        <span class="error-message">{{differentPriceForm.controls.price | formControlErrorMessages: null : formTriedToSubmit}}</span>
      </div>
      <div class="grid__m-12">
        <div class="zc-d-flex zc-align-items zc-justify-end xs-mb-32">
          <button class="zc-btn zc-btn--primary" type="submit"><span>Sačuvaj</span></button>
        </div>
      </div>
    </form>
  </div>
  <div class="grid__m-12">
    <div *ngIf="sameSlots && termsData">
      <span class="terms-title xs-mt-32">Radno vreme</span>
      <span class="terms-subtitle"><span *ngFor="let calendarDay of termsData.data.calendars; index as i">{{WorkDays[calendarDay.day]}}<span *ngIf="i !== termsData.data.calendars.length - 1">, </span></span></span>
      <span class="terms-title xs-mt-32">Trajanje termina</span>
      <span class="terms-subtitle">{{termsData.data.calendars[0].time_slot}} min</span>
      <span class="terms-title xs-mt-32">Pauza</span>
      <span class="terms-subtitle">{{termsData.data.calendars[0].break_time}} min</span>
    </div>
  </div>
  <div class="grid__m-12">
    <div class="grid grid-gap-0" *ngIf="!sameSlots && termsData">
      <div class="grid__m-12">
        <span class="terms-title">Radno vreme</span>
      </div>
      <div class="grid__m-3" *ngFor="let calendarDay of termsData.data.calendars">
        <div class="wrapper-day-termin">
          <div class="day-termin">
            <span class="label-day">{{WorkDays[calendarDay.day]}}</span>
            <span class="terms-subtitle">Trajanje termina: {{calendarDay.time_slot}} min</span>
            <span class="terms-subtitle">Pauza: {{calendarDay.break_time}} min</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<gr-change-work-terms-modal
  *ngIf="isChangeWorkTermsModalOpen"
  [urlParams]="urlParams"
  [selectedTermsDate]="calendarSelectedTermsData"
  (closeModal)="isChangeWorkTermsModalOpen = false"
  (confirmModal)="isChangeWorkTermsModalOpen = false"
></gr-change-work-terms-modal>
