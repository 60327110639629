import {Component, Input} from '@angular/core';
import {NgClass} from '@angular/common';

@Component({
  selector: 'gr-loader',
  templateUrl: './loader.component.html',
  imports: [
    NgClass
  ],
  standalone: true
})
export class LoaderComponent {
  @Input() isButtonLoader = false;
}
