import { Component } from '@angular/core';

@Component({
  selector: 'gr-notification-users-tab',
  standalone: true,
  templateUrl: './notification-users-tab.component.html',
})
export class NotificationUsersTabComponent {

}
