import { Component } from '@angular/core';
import {GrCompany, GrPagination, GrUser} from '../../interfaces/general-interfaces';
import {UserRole} from '../../enums/general-enums';
import {ToastrService} from 'ngx-toastr';
import {UsersHttpService} from '../../services/http-services/users/users-http.service';
import {GetBusinessUsersApiDataFormatted} from '../../services/http-services/users/users-http-interfaces';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'gr-business-list',
  templateUrl: './business-list.component.html',
})
export class BusinessListComponent {
  businessUsersList: GrCompany[] = [];
  businessUserForEdit: GrUser | null = null;
  businessUserForDelete: GrCompany | null = null;
  businessUsersPagination: GrPagination | undefined;
  urlParams: any = {page: 1, role: UserRole.business, search: null, business_category: 1, sort: null, direction: null};
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(private usersHttpService: UsersHttpService,
              private activatedRoute: ActivatedRoute,
              private toastService: ToastrService) {
  }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe(res => {
      if (res.categoryId) {
        this.urlParams.business_category = res.categoryId;
        this.getBusinessUsers();
      }
    });
  }

  getBusinessUsers(): void {
    this.urlParams.page = (this.businessUsersPagination?.currentPage) ? this.businessUsersPagination.currentPage : 1;
    this.usersHttpService.getBusinessUsers(this.urlParams).then((res: GetBusinessUsersApiDataFormatted) => {
      this.businessUsersList = res.data;
      this.businessUsersPagination = res.pagination;
    });
  }

  onSearchChange(searchQuery: string): void {
    this.urlParams.search = searchQuery;
    this.businessUsersPagination = undefined;
    this.getBusinessUsers();
  }

  onPageChanged(page: any): void {
    if (this.businessUsersPagination) {
      this.businessUsersPagination.currentPage = page;
      this.getBusinessUsers();
    }
  }

  onSortChange(type: string): void {
    if (this.urlParams.sort === type) {
      this.urlParams.direction === 'asc' ? this.urlParams.direction = 'desc' : this.urlParams.direction = 'asc';
    } else {
      this.urlParams.sort = type;
      this.urlParams.direction = 'asc';
    }
    this.getBusinessUsers();
  }

  onConfirmEditBusinessUser(): void {
    this.businessUserForEdit = null;
    this.getBusinessUsers();
  }

  removeBusinessUser(businessUserId: number | null): void {
    if (businessUserId) {
      this.usersHttpService.deleteUser({businessUserId}).then(res => {
        this.getBusinessUsers();
        this.toastService.success('Uspešno ste obrisali biznis korisnika.');
      }, error => {
        this.toastService.warning('Došlo je do greške, pokušajte ponovo.');
      });
    }
  }
}
