import { Component } from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {EmailRegex, PasswordRegex, PhoneRegex} from '../../../../constants/general-variables';
import {confirmMatchControlsValidator} from '../../../../validators/form-validators.validator';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationHelperService} from '../../../../services/helpers/authentication-helper.service';
import {UsersHttpService} from '../../../../services/http-services/users/users-http.service';
import {FormControlErrorMessagesModule} from '../../../../pipes/form-control-error-messages/form-control-error-messages.module';
import {FormControlModule} from '../../../../pipes/form-control/form-control.module';
import {NgClass, NgIf} from '@angular/common';

@Component({
  selector: 'gr-tab-password-data',
  templateUrl: './tab-password-data.component.html',
  imports: [
    FormControlErrorMessagesModule,
    FormControlModule,
    FormsModule,
    NgIf,
    ReactiveFormsModule,
    NgClass
  ],
  standalone: true
})
export class TabPasswordDataComponent {
  settingsUserProfileForm!: FormGroup;
  settingsUserPasswordForm!: FormGroup;
  isPasswordShow = false;
  isOldPasswordShow = false;
  isConfirmPasswordShow = false;
  formUserPasswordTriedToSubmit = false;
  isUserPasswordFormDisabled = true;

  constructor(private formBuilder: FormBuilder,
              private toastrService: ToastrService,
              private authenticationHelperService: AuthenticationHelperService,
              private usersHttpService: UsersHttpService) {
  }
  ngOnInit(): void {
    this.buildForm();
  }
  buildForm(): void {
    this.settingsUserProfileForm = this.formBuilder.group({
      first_name: ['', [Validators.required, Validators.minLength(2)]],
      last_name: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.required, Validators.pattern(EmailRegex)]],
      phone: ['', [Validators.required, Validators.pattern(PhoneRegex)]],
    });
    this.settingsUserProfileForm.patchValue(this.authenticationHelperService.getUserData);

    this.settingsUserPasswordForm = this.formBuilder.group({
      old_password: ['', [Validators.required, Validators.pattern(PasswordRegex)]],
      password: ['', [Validators.required, Validators.pattern(PasswordRegex)]],
      password_confirmation: ['', [Validators.required, Validators.pattern(PasswordRegex)]],
    }, {
      validators: confirmMatchControlsValidator('password', 'password_confirmation')
    });

    this.disabledUserPasswordForm();
  }



  enableUserPasswordForm(): void {
    this.isUserPasswordFormDisabled = false;
    this.settingsUserPasswordForm.enable();
  }
  disabledUserPasswordForm(): void {
    this.isUserPasswordFormDisabled = true;
    this.settingsUserPasswordForm.disable();
  }



  onSubmitSettingsUserPasswordForm(): void {
    this.formUserPasswordTriedToSubmit = true;
    if (this.settingsUserProfileForm.valid) {
      this.usersHttpService.putAuthUserPassword(this.settingsUserPasswordForm.value).then((res: any) => {
        this.disabledUserPasswordForm();
        this.toastrService.success('Uspešno ste izmenili lozinku.');
        this.formUserPasswordTriedToSubmit = false;
      });
    }
  }
}
