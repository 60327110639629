<div class="wrapper-page faq-page">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div class="wrapper-content-page">
    <div class="wrapper-header-page-content">
      <!--   TODO JS - Ovde ce da menjas naslov u zavisnosti na koju je kategoriju kliknuo iz sidebar-a u zagradi je ukupan broj registrovanih   -->
      <span class="header-text">Biznis korisnici ({{businessUsersPagination?.totalItems}})</span>
    </div>
    <div>
      <gr-search (searchQueryChange)="onSearchChange($event)"></gr-search>
    </div>
    <div class="grid xs-pr-32 xs-pl-32 business-table">
      <div class="grid__m-12">
        <div class="zc-table">
          <div class="zc-header-table">
            <div class="zc-header-row">
              <div class="zc-header-column">
                <span>ID</span>
              </div>
              <div class="zc-header-column">
                <span>Status</span>
              </div>
              <div class="zc-header-column">
                <span>Naziv</span>
                <div class="wrapper-sort" (click)="onSortChange('name')" [ngClass]="{'active': urlParams.sort === 'name'}">
                  <i class="fa-solid" [ngClass]="(urlParams.sort === 'name' && urlParams.direction === 'desc') ? 'fa-arrow-up-a-z': 'fa-arrow-down-a-z'"></i>
                </div>
              </div>
              <div class="zc-header-column">
                <span>Opis</span>
                <div class="wrapper-sort" (click)="onSortChange('description')" [ngClass]="{'active': urlParams.sort === 'description'}">
                  <i class="fa-solid" [ngClass]="(urlParams.sort === 'description' && urlParams.direction === 'desc') ? 'fa-arrow-up-a-z': 'fa-arrow-down-a-z'"></i>
                </div>
              </div>
              <div class="zc-header-column">
                <span>Adresa</span>
                <div class="wrapper-sort" (click)="onSortChange('address')" [ngClass]="{'active': urlParams.sort === 'address'}">
                  <i class="fa-solid" [ngClass]="(urlParams.sort === 'address' && urlParams.direction === 'desc') ? 'fa-arrow-up-a-z': 'fa-arrow-down-a-z'"></i>
                </div>
              </div>
              <div class="zc-header-column column-buttons">
                <span>Alatke</span>
              </div>
            </div>
          </div>
          <div class="zc-content-table">
            <div class="zc-content-row" *ngFor="let company of businessUsersList">
              <div class="zc-content-column">
                <span>{{company?.user_id}}</span>
              </div>
              <div class="zc-content-column">
                <span>{{company?.active ? 'Aktivan': 'Neaktivan'}}</span>
              </div>
              <div class="zc-content-column">
                <span>{{company?.name}}</span>
              </div>
              <div class="zc-content-column">
                <span>{{company?.description}}</span>
              </div>
              <div class="zc-content-column">
                <span>{{company?.address}}</span>
              </div>
              <div class="zc-content-column column-buttons">
                <div class="buttons-group">
                  <div class="wrapper-button">
                    <i class="fa-solid fa-eye" [routerLink]="[PAGES_AND_PATHS.businessProfile.pagePath, company?.id]"></i>
                  </div>
                  <div class="wrapper-button" (click)="businessUserForEdit = company?.user ? company.user : null">
                    <i class="fa-solid fa-pen-to-square"></i>
                  </div>
                  <div class="wrapper-button" (click)="businessUserForDelete = company">
                    <i class="fa-solid fa-trash"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="grid__m-12">
        <gr-pagination
          *ngIf="businessUsersList.length"
          [currentPage]="businessUsersPagination?.currentPage"
          [pagId]="'js-business-users-table-pagination'"
          [perPage]="businessUsersPagination?.perPage"
          [totalItems]="businessUsersPagination?.totalItems"
          (pageChanged)="onPageChanged($event)">
        </gr-pagination>
      </div>
    </div>
  </div>
</div>

<gr-edit-business-user-modal
  *ngIf="businessUserForEdit"
  [businessUserForEdit]="businessUserForEdit"
  (closeModal)="businessUserForEdit = null"
  (confirmModal)="onConfirmEditBusinessUser()"
></gr-edit-business-user-modal>

<gr-confirm-modal
  *ngIf="businessUserForDelete"
  [modalTitle]="'Brisanje biznis korisnika'"
  [modalDescription]="'Da li ste sigurni da želite da obrišete biznis korisnika?'"
  (closeModal)="businessUserForDelete = null"
  (confirmModal)="removeBusinessUser(businessUserForDelete ? businessUserForDelete.id : null)">
</gr-confirm-modal>
