import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {ToastrService} from 'ngx-toastr';
import {BusinessProfilesHttpService} from '../../../services/http-services/business-profiles/business-profiles-http.service';
import {DigitRegex, Units} from '../../../constants/general-variables';
import {minLengthArray} from '../../../validators/form-validators.validator';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-price-list-category-item-create-or-edit',
  templateUrl: './price-list-category-item-create-or-edit.component.html',
})
export class PriceListCategoryItemCreateOrEditComponent implements OnInit {
  createOrEditPriceListItemForm!: FormGroup;
  formTriedToSubmit = false;
  urlParams = {
    companyId: null,
    categoryId: null,
    itemId: null,
  }
  imageUrls: any[] = [];
  businessProfile: any;
  deletedImagesIds: number[] = [];
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
  protected readonly Units = Units;
  constructor(private formBuilder: FormBuilder,
              private toastService: ToastrService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private businessProfilesHttpService: BusinessProfilesHttpService) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.activatedRoute.params.subscribe((res: any) => {
      this.urlParams.companyId = res.id;
      this.urlParams.categoryId = res.categoryId;
      this.urlParams.itemId = res.itemId;
      if (this.urlParams.categoryId) {
        this.createOrEditPriceListItemForm.controls.item_category_id.patchValue(this.urlParams.categoryId);
      }
      this.getBusinessProfile();
    });
    this.createOrEditPriceListItemForm.controls.price.valueChanges.subscribe(value => {
      if (this.createOrEditPriceListItemForm.controls.owner_percentage.value) {
        this.createOrEditPriceListItemForm.controls.price_with_percentage.patchValue(Number(value) + (value * this.createOrEditPriceListItemForm.controls.owner_percentage.value / 100));
      }
    });

    this.createOrEditPriceListItemForm.controls.owner_percentage.valueChanges.subscribe(value => {
      if (this.createOrEditPriceListItemForm.controls.price.value) {
        this.createOrEditPriceListItemForm.controls.price_with_percentage.patchValue(Number(this.createOrEditPriceListItemForm.controls.price.value) + (this.createOrEditPriceListItemForm.controls.price.value * value / 100));
      }
    });
  }

  buildForm(): void {
    this.createOrEditPriceListItemForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      unit: ['', Validators.required],
      price: ['', [Validators.required, Validators.pattern(DigitRegex)]],
      owner_percentage: ['', [Validators.required, Validators.pattern(DigitRegex)]],
      price_with_percentage: ['', [Validators.required, Validators.pattern(DigitRegex)]],
      images: [null, Validators.required],
      deleted_images_ids: [[]],
      item_category_id: ['', Validators.required]
    });
  }

  getPriceListItem(): void {
    const { categoryId, ...rest } = this.urlParams;
    this.businessProfilesHttpService.getPriceListItem(rest).then(res => {
      this.createOrEditPriceListItemForm.patchValue(res.data);
      this.imageUrls = res.data.images.map((item: any) => {
        return {fullPath: item.full_path, id: item.id, file: undefined};
      })
      if (this.businessProfile.business_category_id === 6) {
        if (res.data.flavours.length) {
          res.data.flavours.forEach((flavour: string) => {
            this.addNewFlavoursField(flavour);
          });
          this.createOrEditPriceListItemForm.controls.number_of_floors.patchValue(res.data.number_of_floors);
        }
      }
    })
  }

  getBusinessProfile(): void {
    this.businessProfilesHttpService.getSingleBusiness({companyId: this.urlParams.companyId}).then(res => {
      this.businessProfile = res.data;
      if (this.businessProfile.business_category_id === 6) {
        this.createOrEditPriceListItemForm.addControl('flavours', this.formBuilder.array([], minLengthArray(1)));
        this.createOrEditPriceListItemForm.addControl('number_of_floors', this.formBuilder.control('', [Validators.required, Validators.pattern(DigitRegex)]));
        if (!this.urlParams.itemId) {
          this.addNewFlavoursField();
        }
      }
      (this.businessProfile.business_category_id === 1 || this.businessProfile.business_category_id === 4 || this.businessProfile.business_category_id === 5 )
        ? this.createOrEditPriceListItemForm.controls.unit.clearValidators()
        :  this.createOrEditPriceListItemForm.controls.unit.setValidators([Validators.required]);
      this.createOrEditPriceListItemForm.controls.unit.updateValueAndValidity();
      if (this.urlParams.itemId) {
        this.getPriceListItem();
      }
    })
  }

  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const selectedFiles = Array.from(input.files);
      if (selectedFiles.length > 5 - this.imageUrls.length) {
        this.toastService.warning('Neke od slika nece biti uploadovane zato sto ste selektovali vise slika od broja slobodnih slotova, maksimalno mozete dodati 5 slika')
      }
      selectedFiles.forEach(file => {
        if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/svg') {
          this.toastService.warning('Pogrešan tip dokumenta. Samo JPG, JPEG, SVG i PNG formati su dozvoljeni.');
          return;
        } else if (file?.size > 2097152) {
          this.toastService.warning(`Slika ${file.name} je veća od 2MB.`);
          return;
        } else if (this.imageUrls.length < 5) {
          this.imageUrls.push({fullPath: URL.createObjectURL(file), id: undefined, file: file});
        }
      })
      this.createOrEditPriceListItemForm.controls.images.patchValue(this.imageUrls);
      if (this.fileInput) {
        this.fileInput.nativeElement.value = '';
      }
    }
  }

  removeAllImages(): void {
    this.imageUrls.forEach((image: any) => {
      if (image.id) {
        this.deletedImagesIds.push(image.id)
      }
    })
    this.imageUrls = [];
    this.createOrEditPriceListItemForm.controls.deleted_images_ids.patchValue(this.deletedImagesIds);
    this.createOrEditPriceListItemForm.controls.images.patchValue(null);
  }

  removeSingleImage(imageURL: any) {
    const imageIndex = this.imageUrls.findIndex((imgUrl: any) => {
      return imgUrl.fullPath === imageURL.fullPath;
    })
    if (imageIndex !== -1) {
      if (imageURL.id) {
        this.deletedImagesIds.push(imageURL.id)
      }
      this.imageUrls.splice(imageIndex, 1);
      this.createOrEditPriceListItemForm.controls.deleted_images_ids.patchValue(this.deletedImagesIds);
      this.createOrEditPriceListItemForm.controls.images.patchValue(this.imageUrls.length ? this.imageUrls : null);
    }
  }

  get flavours() {
    return this.createOrEditPriceListItemForm.get('flavours') as FormArray;
  }

  addNewFlavoursField(value = '') {
    const field = this.formBuilder.group({
      value: [value ? value : '', Validators.required]
    });
    this.flavours.push(field);
  }

  removeFlavourField(index: number) {
    this.flavours.removeAt(index);
  }

  submitCreateOrEditPriceListItemForm(): void {
    this.formTriedToSubmit = true;
    if (this.createOrEditPriceListItemForm.valid) {
      const formData: FormData = new FormData();
      for (const key in this.createOrEditPriceListItemForm.value) {
        if (Array.isArray(this.createOrEditPriceListItemForm.value[key]) ) {
          if (key === 'images') {
            this.createOrEditPriceListItemForm.value[key].forEach((element: any, index: number) => {
              formData.append(`images[${index}][position]`, (index + 1).toString());
              (element.id)
                ? formData.append(`images[${index}][id]`, element.id.toString())
                : formData.append(`images[${index}][file]`, element.file);
            });
          } else if (key === 'flavours') {
            this.createOrEditPriceListItemForm.value[key].forEach((element: any, index: number) => {
              formData.append(`flavours[${index}]`, element.value);
            });
          } else {
            this.createOrEditPriceListItemForm.value[key].forEach((element: any) => {
              formData.append(`${key}[]`, element);
            });
          }
        } else {
          formData.append(key, this.createOrEditPriceListItemForm.value[key]);
        }
      }
      this.urlParams.itemId
      ? this.businessProfilesHttpService.putPriceListItem(this.urlParams, formData).then(res => {
          this.deletedImagesIds = [];
          this.createOrEditPriceListItemForm.controls.deleted_images_ids.patchValue([]);
          this.toastService.success(res.message);
          this.router.navigate(['/business-profile', this.urlParams.companyId, 'price-list-category', this.urlParams.categoryId]);
        }).catch(({error}: {error:{ errors: { [key: string]: string } }}) => {
          Object.values(error.errors).forEach(error => this.toastService.error(error));
        })
      : this.businessProfilesHttpService.postPriceListItem(this.urlParams, formData).then(res => {
          this.deletedImagesIds = [];
          this.createOrEditPriceListItemForm.controls.deleted_images_ids.patchValue([]);
          this.toastService.success(res.message);
          this.router.navigate(['/business-profile', this.urlParams.companyId, 'price-list-category', this.urlParams.categoryId]);
        }).catch(({error}: {error:{ errors: { [key: string]: string } }}) => {
          Object.values(error.errors).forEach(error => this.toastService.error(error));
        });
    }
  }
}
