import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';
import {AuthGuardService} from '../../guards/auth-guard.service';
import {NotificationsComponent} from './notifications.component';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.notifications.pageInRouting,
    data: {data: PAGES_AND_PATHS.notifications},
    component: NotificationsComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./notifications.module').then(m => m.NotificationsModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotificationsRoutingModule { }
