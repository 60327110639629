import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {PresentsHttpService} from '../../../../services/http-services/presents/presents-http.service';
import {DatePickerModule} from '../../../../shared-components/date-picker/date-picker.module';
import {FormControlErrorMessagesModule} from '../../../../pipes/form-control-error-messages/form-control-error-messages.module';
import {MatFormField} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'gr-add-presents-action-modal',
  templateUrl: './add-presents-action-modal.component.html',
  imports: [
    ReactiveFormsModule,
    DatePickerModule,
    FormControlErrorMessagesModule,
    MatFormField,
    MatInput,
    NgxMaterialTimepickerModule
  ],
  standalone: true,
  providers: [DatePipe]
})

export class AddPresentsActionModalComponent implements OnInit{
  @Input() checkedPresentIds: number[] = [];
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmModal: EventEmitter<void> = new EventEmitter<void>();
  addPresentsActionForm!: FormGroup;
  formTriedToSubmit = false;

  constructor(private formBuilder: FormBuilder,
              private datePipe: DatePipe,
              private presentsHttpService: PresentsHttpService) {
  }
  ngOnInit(): void {
    this.buildForm();
  }
  buildForm(): void {
    this.addPresentsActionForm = this.formBuilder.group({
      gift_ids: [null, Validators.required],
      date_from: ['', Validators.required],
      date_to: ['', Validators.required],
      discount_percentage: ['', Validators.required],
    });
    this.addPresentsActionForm.controls.gift_ids.patchValue(this.checkedPresentIds);
  }


  onSelectedDate(selectedDate: any, isStartDate: boolean): void {
    isStartDate
      ? this.addPresentsActionForm.controls.date_from.patchValue(this.datePipe.transform(selectedDate, 'yyyy-MM-dd'))
      : this.addPresentsActionForm.controls.date_to.patchValue(this.datePipe.transform(selectedDate, 'yyyy-MM-dd'));
  }

  onSubmitPresentsActionForm(): void {
    this.formTriedToSubmit = true;
    if (this.addPresentsActionForm.valid) {
      this.presentsHttpService.addPresentsAction(this.addPresentsActionForm.value).then(res => {
        this.confirmModal.emit();
      });
    }
  }
}
