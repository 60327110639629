<div class="wrapper-modal">
  <form [formGroup]="createOrEditStoreForm" (ngSubmit)="onSubmitStoreForm()" class="wrapper-content-modal">
    <div class="header-modal">
      <span class="title-modal">Dodajte prodavnicu igračaka</span>
    </div>
    <div class="content-modal">
      <div class="wrapper-input">
        <span class="label-input">Naziv prodavnice</span>
        <div class="zc-input">
          <input type="text" formControlName="name">
        </div>
        <span class="error-message">{{createOrEditStoreForm.controls.name | formControlErrorMessages: null : formTriedToSubmit}}</span>
      </div>
      <div class="wrapper-input">
        <span class="label-input">Rabat</span>
        <div class="zc-input">
          <input type="text" formControlName="rebate">
        </div>
        <span class="error-message">{{createOrEditStoreForm.controls.rebate | formControlErrorMessages: null : formTriedToSubmit}}</span>
      </div>
      <div class="xs-mb-20">
        <div class="label-banner">
          <span>Ubacite logo prodavnice (preporučena dimenzija slike 300x300)</span>
        </div>
        <div class="wrapper-custom-gallery banners">
          <div *ngIf="!imageUrl" class="header-custom-gallery">
            <div class="wrapper-btn-gallery">
              <div class="file-input">
                <label class="file-input__label2">
                  <span>Dodaj Sliku</span>
                  <input #fileInput class="file-input__input" type="file" accept=".jpg,.jpeg,.png,.svg" (change)="onFileChange($event)"/>
                </label>
              </div>
            </div>
            <span class="error-message">{{createOrEditStoreForm.controls.image | formControlErrorMessages: null : formTriedToSubmit}}</span>
          </div>
          <div *ngIf="imageUrl" class="content-custom-gallery-banner">
            <div class="wrapper-gallery-image-banner">
              <img [src]="imageUrl" alt="banner-image">
              <div class="overlay-delete" (click)="removeFile()">
                <i class="fa-solid fa-xmark"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-modal">
      <button type="button" class="zc-btn zc-btn--gray" (click)="closeModal.emit()"><span>Izađi</span></button>
      <button type="submit" class="zc-btn zc-btn--primary"><span>Dodaj</span></button>
    </div>
  </form>
</div>
