<div class="wrapper-page faq-page">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div class="wrapper-content-page">
    <div class="wrapper-header-page-content">
      <span class="header-text">Biznis korisnici</span>
    </div>
    <div>
      <gr-search (searchQueryChange)="onSearchChange($event)"></gr-search>
    </div>
    <div class="grid xs-pr-32 xs-pl-32">
      <div class="grid__m-12">
        <div class="zc-table">
          <div class="zc-header-table">
            <div class="zc-header-row">
              <div class="zc-header-column">
                <span>Ime admina</span>
              </div>
              <div class="zc-header-column column-buttons">
                <span>Alatke</span>
              </div>
            </div>
          </div>
          <div class="zc-content-table">
            <div class="zc-content-row" *ngFor="let user of businessUsersList">
              <div class="zc-content-column">
                <span>{{user.full_name}}</span>
                <span>{{user.company?.name}}</span>
              </div>
              <div class="zc-content-column column-buttons">
                <div class="buttons-group">
                  <div class="wrapper-button">
                    <i class="fa-solid fa-eye" [routerLink]="[PAGES_AND_PATHS.businessProfile.pagePath, user.company?.id]"></i>
                  </div>
                  <div class="wrapper-button" (click)="businessUserForEdit = user">
                    <i class="fa-solid fa-pen-to-square"></i>
                  </div>
                  <div class="wrapper-button" (click)="businessUserForDelete = user">
                    <i class="fa-solid fa-trash"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="grid__m-12">
        <gr-pagination
          *ngIf="businessUsersList.length"
          [currentPage]="businessUsersPagination?.currentPage"
          [pagId]="'js-business-users-table-pagination'"
          [perPage]="businessUsersPagination?.perPage"
          [totalItems]="businessUsersPagination?.totalItems"
          (pageChanged)="onPageChanged($event)">
        </gr-pagination>
      </div>
    </div>
  </div>
</div>

<gr-edit-business-user-modal
  *ngIf="businessUserForEdit"
  [businessUserForEdit]="businessUserForEdit"
  (closeModal)="businessUserForEdit = null"
  (confirmModal)="onConfirmEditBusinessUser()"
></gr-edit-business-user-modal>

<gr-confirm-modal
  *ngIf="businessUserForDelete"
  (closeModal)="businessUserForDelete = null"
  (confirmModal)="removeBusinessUser(businessUserForDelete ? businessUserForDelete.id : null)">
</gr-confirm-modal>
