import { Component } from '@angular/core';
import {GrPagination, GrUser} from '../../interfaces/general-interfaces';
import {UserRole} from '../../enums/general-enums';
import {ToastrService} from 'ngx-toastr';
import {UsersHttpService} from '../../services/http-services/users/users-http.service';
import {GetUsersApiDataFormatted} from '../../services/http-services/users/users-http-interfaces';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';

@Component({
  selector: 'gr-business-list',
  templateUrl: './business-list.component.html',
})
export class BusinessListComponent {
  businessUsersList: GrUser[] = [];
  businessUserForEdit: GrUser | null = null;
  businessUserForDelete: GrUser | null = null;
  businessUsersPagination: GrPagination | undefined;
  urlParams: any = {role: UserRole.business, search: null};

  constructor(private usersHttpService: UsersHttpService,
              private toastService: ToastrService) {
  }
  ngOnInit(): void {
    this.getBusinessUsers();
  }

  getBusinessUsers(): void {
    const page = (this.businessUsersPagination?.currentPage) ? this.businessUsersPagination.currentPage : 1;
    this.usersHttpService.getUsers({page, ...this.urlParams}).then((res: GetUsersApiDataFormatted) => {
      this.businessUsersList = res.data;
      this.businessUsersPagination = res.pagination;
    });
  }

  onSearchChange(searchQuery: string): void {
    this.urlParams.search = searchQuery;
    if (this.businessUsersPagination?.currentPage) {
      this.businessUsersPagination.currentPage = 1;
    }
    this.getBusinessUsers();
  }

  onPageChanged(page: any): void {
    if (this.businessUsersPagination) {
      this.businessUsersPagination.currentPage = page;
      this.getBusinessUsers();
    }
  }

  onConfirmEditBusinessUser(): void {
    this.businessUserForEdit = null;
    this.getBusinessUsers();
  }

  removeBusinessUser(businessUserId: number | null): void {
    if (businessUserId) {
      this.usersHttpService.deleteUser({businessUserId}).then(res => {
        this.getBusinessUsers();
        this.toastService.success('Uspešno ste obrisali biznis korisnika.');
      }, error => {
        this.toastService.warning('Došlo je do greške, pokušajte ponovo.');
      });
    }
  }

    protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
}
