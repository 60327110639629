import { Component } from '@angular/core';
import {GrPagination} from '../../../interfaces/general-interfaces';
import {NotificationsHttpService} from '../../../services/http-services/notifications/notifications-http.service';
import {NgForOf, NgIf} from '@angular/common';
import {TimeAgoPipe} from '../../../pipes/time-ago/time-ago.pipe';
import {fadeInFadeOut} from '../../../animations/fade-in-out-animation';

@Component({
  selector: 'gr-notification-support-tab',
  standalone: true,
  templateUrl: './notification-support-tab.component.html',
  imports: [
    NgForOf,
    TimeAgoPipe,
    NgIf
  ],
  animations: [fadeInFadeOut]
})
export class NotificationSupportTabComponent {
  supportNotificationsPagination: GrPagination | undefined;
  supportNotifications: any[] = [];
  urlParams = {page: 1};
  imageForPreview = null;

  constructor(private notificationsHttpService: NotificationsHttpService) {
  }

  ngOnInit(): void {
    this.supportNotificationsPagination = undefined;
    this.supportNotifications = [];
    this.getNotifications();
  }

  getNotifications(): void {
    this.urlParams.page = (this.supportNotificationsPagination?.currentPage) ? this.supportNotificationsPagination.currentPage : 1;
    this.notificationsHttpService.getSupportNotifications(this.urlParams).then(res => {
      this.supportNotifications = [...this.supportNotifications, ...res.data];
    });
  }

  onScroll(event: any): void {
    const threshold = 100;
    const position = event.target.scrollTop + event.target.offsetHeight;
    const height = event.target.scrollHeight;

    if (position > height - threshold && (this.supportNotificationsPagination?.currentPage ?? 0) < (this.supportNotificationsPagination?.currentPage ?? 0)) {
      this.getNotifications();
    }
  }
}
