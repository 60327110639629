import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {AuthGuardService} from '../../../guards/auth-guard.service';
import {CreateOrEditPresentComponent} from './create-or-edit-present.component';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.createPresent.pageInRouting,
    data: {data: PAGES_AND_PATHS.createPresent},
    component: CreateOrEditPresentComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./create-or-edit-present.module').then(m => m.CreateOrEditPresentModule)
  },
  {
    path: PAGES_AND_PATHS.editPresent.pageInRouting,
    data: {data: PAGES_AND_PATHS.editPresent},
    component: CreateOrEditPresentComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./create-or-edit-present.module').then(m => m.CreateOrEditPresentModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CreateOrEditPresentRoutingModule { }
