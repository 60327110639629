import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';
import {AuthGuardService} from '../../guards/auth-guard.service';
import {BannersComponent} from './banners.component';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.banners.pageInRouting,
    data: {data: PAGES_AND_PATHS.banners},
    component: BannersComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./banners.module').then(m => m.BannersModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BannersRoutingModule { }
