<div class="wrapper-modal add-table-gifts">
  <form [formGroup]="uploadPresentTableForm" (ngSubmit)="onSubmitUploadPresentsTableForm()" class="wrapper-content-modal">
    <div class="header-modal">
      <span class="title-modal">Dodajte tabelu sa poklonima</span>
    </div>
    <div class="content-modal">
      <gr-dropdown-reactive-form
        [items]="stores"
        [propIdentifier]="'id'"
        [dropdownLabel]="'Prodavnica igračaka'"
        [placeholder]="'Prodavnica igračaka'"
        [formControl]="uploadPresentTableForm.controls.supplier_id | formControl"
        [formTriedToSubmit]="formTriedToSubmit"
      ></gr-dropdown-reactive-form>
      <div class="wrapper-custom-gallery banners">
        <div *ngIf="!uploadPresentTableForm.controls.file.value" class="header-custom-gallery">
          <div class="wrapper-btn-gallery">
            <div class="file-input">
              <label class="file-input__label2">
                <span>Uvezi tabelu</span>
                <input #fileInput class="file-input__input" type="file" accept=".xml, .xlsx, .xls" (change)="onFileChange($event)"/>
              </label>
            </div>
          </div>
        </div>
        <div *ngIf="uploadPresentTableForm.controls.file.value" class="preview-table-file">
          <span class="label-file">Naziv fajla:</span>
          <span class="name-file">{{uploadPresentTableForm.controls.file.value.name}} <i (click)="removeFile()" class="fa-solid fa-circle-xmark"></i></span>
        </div>
      </div>
    </div>
    <div class="footer-modal">
      <button class="zc-btn zc-btn--gray" type="button" (click)="closeModal.emit()"><span>Zatvori</span></button>
      <button class="zc-btn zc-btn--primary zc-position-relative" [disabled]="isLoaderActive" type="submit">
        <gr-loader *ngIf="isLoaderActive" [isButtonLoader]="true"></gr-loader>
        <span>Uvezi</span>
      </button>
    </div>
  </form>
</div>
