import { Pipe, PipeTransform } from '@angular/core';
import {DaysOfWeek} from '../../constants/general-variables';

@Pipe({
  name: 'returnDayForView',
})
export class ReturnDayForViewPipe implements PipeTransform {

  transform(value: string): unknown {
    const day = DaysOfWeek.find(element => element.dayForDB === value);
    return day?.dayForView;
  }

}
