<div class="wrapper-login-page">
  <div class="content-login-left">
    <div class="overlay-login-image"></div>
  </div>
  <div class="content-login-right">
    <div class="content-login">
      <img src="../../../assets/images/logo.svg" alt="">
      <span class="title">Dobrodošli u igraonice</span>
      <span class="subtitle">Unesite mejl i lozinku kako bi se prijavili na admin panel</span>
      <form class="" [formGroup]="loginForm" (ngSubmit)="submitLoginForm()">
        <div class="wrapper-input">
          <span class="label-input">E-pošta</span>
          <div class="zc-input">
            <input type="email" [formControl]="loginForm.controls.email | formControl" [placeholder]="'E-pošta'">
          </div>
          <span class="error-message">{{loginForm.controls.email | formControlErrorMessages: 'Unesite validnu email adresu.' : formTriedToSubmit}}</span>
        </div>
        <div class="wrapper-input">
          <span class="label-input">Lozinka</span>
          <div class="zc-input">
            <input [type]="isPasswordShow ? 'text' : 'password'" [formControl]="loginForm.controls.password | formControl" [placeholder]="'Lozinka'">
            <i *ngIf="!isPasswordShow" (click)="isPasswordShow = true" class="fa-solid fa-eye"></i>
            <i *ngIf="isPasswordShow" (click)="isPasswordShow = false" class="fa-solid fa-eye-slash"></i>
          </div>
          <span class="error-message">{{loginForm.controls.password | formControlErrorMessages: 'Pogrešna lozinka, pokušajte ponovo.' : formTriedToSubmit}}</span>
          <span class="error-message" *ngIf="errMessage">{{errMessage}}</span>
        </div>
        <a class="zc-link-label" href="javascript:void(0)" [routerLink]="[PAGES_AND_PATHS.forgotPassword.pagePath]">Zaboravili ste lozinku</a>
        <button class="zc-btn zc-btn--primary" type="submit"><span>Prijavite se</span></button>
      </form>
    </div>
  </div>
</div>
