import { Component } from '@angular/core';
import {BusinessProfilesHttpService} from '../../../services/http-services/business-profiles/business-profiles-http.service';
import {ActivatedRoute} from '@angular/router';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {ToastrService} from 'ngx-toastr';
import {GrPriceListCategory} from '../../../interfaces/general-interfaces';

@Component({
  selector: 'app-price-list-category',
  templateUrl: './price-list-category.component.html',
})
export class PriceListCategoryComponent {
  urlParams = {
    companyId: undefined,
    categoryId: undefined,
  }
  categoryData!: GrPriceListCategory;
  priceListItemForDelete: any = null;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
  constructor(private activatedRoute: ActivatedRoute,
      private toastrService: ToastrService,
      private businessProfilesHttpService: BusinessProfilesHttpService) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((res: any) => {
      this.urlParams.companyId = res.id;
      this.urlParams.categoryId = res.categoryId;
      this.getCategoryItems();
    });
  }

  getCategoryItems(): void {
    this.businessProfilesHttpService.getPriceListCategory(this.urlParams).then(res => {
      this.categoryData = res.data;
    });
  }

  onConfirmDeletePriceListItem(): void {
    this.categoryData.items = this.categoryData.items.filter((item: any) => {
      return item.id === this.priceListItemForDelete.id;
    })
    this.toastrService.success('Uspesno ste obrisali artikl.')
    this.priceListItemForDelete = null;
  }
}
