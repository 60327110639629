import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControlModule} from '../../../../pipes/form-control/form-control.module';
import {NgForOf, NgIf} from '@angular/common';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {InvitationsHttpService} from '../../../../services/http-services/invitations/invitations-http.service';
import {FormControlErrorMessagesModule} from '../../../../pipes/form-control-error-messages/form-control-error-messages.module';
import {LoaderComponent} from '../../../../shared-components/loader/loader.component';

@Component({
  selector: 'gr-edit-invitation-modal',
  templateUrl: './edit-invitation-modal.component.html',
  imports: [
    FormControlModule,
    NgIf,
    ReactiveFormsModule,
    FormControlErrorMessagesModule,
    NgForOf,
    LoaderComponent
  ],
  standalone: true
})
export class EditInvitationModalComponent implements OnInit {
  formTriedToSubmit = false;
  editInvitationForm!: FormGroup;
  imageUrls: any[] = [];
  deletedImagesIds: number[] = [];
  isLoaderActive = false;
  @Input() invitationForEdit: any;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmModal: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  constructor(private formBuilder: FormBuilder,
              private toastService: ToastrService,
              private invitationsHttpService: InvitationsHttpService) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.editInvitationForm = this.formBuilder.group({
      images: [null, Validators.required],
      deleted_images_ids: [[]]
    });
    if (this.invitationForEdit) {
      this.editInvitationForm.patchValue(this.invitationForEdit);
      (!this.invitationForEdit.images?.length)
        ? this.editInvitationForm.controls.images.patchValue(null)
        : this.imageUrls = this.invitationForEdit.images.map((item: any) => {
          return {fullPath: item.full_path, id: item.id, file: undefined};
        });
    }
  }

  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const selectedFiles = Array.from(input.files);
      if (selectedFiles.length > 10 - this.imageUrls.length) {
        this.toastService.warning('Neke od slika nece biti uploadovane zato sto ste selektovali vise slika od broja slobodnih slotova, maksimalno mozete dodati 10 slika');
      }
      selectedFiles.forEach(file => {
        if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/svg') {
          this.toastService.warning('Pogrešan tip dokumenta. Samo JPG, JPEG, SVG i PNG formati su dozvoljeni.');
          return;
        } else if (file?.size > 2097152) {
          this.toastService.warning(`Slika ${file.name} je veća od 2MB.`);
          return;
        } else if (this.imageUrls.length < 10) {
          this.imageUrls.push({fullPath: URL.createObjectURL(file), id: undefined, file});
        }
      });
      this.editInvitationForm.controls.images.patchValue(this.imageUrls);
      if (this.fileInput) {
        this.fileInput.nativeElement.value = '';
      }
    }
  }

  removeAllImages(): void {
    this.imageUrls.forEach((image: any) => {
      if (image.id) {
        this.deletedImagesIds.push(image.id);
      }
    });
    this.imageUrls = [];
    this.editInvitationForm.controls.deleted_images_ids.patchValue(this.deletedImagesIds);
    this.editInvitationForm.controls.images.patchValue(null);
  }

  removeSingleImage(imageURL: any): void {
    const imageIndex = this.imageUrls.findIndex((imgUrl: any) => {
      return imgUrl.fullPath === imageURL.fullPath;
    });
    if (imageIndex !== -1) {
      if (imageURL.id) {
        this.deletedImagesIds.push(imageURL.id);
      }
      this.imageUrls.splice(imageIndex, 1);
      this.editInvitationForm.controls.deleted_images_ids.patchValue(this.deletedImagesIds);
      this.editInvitationForm.controls.images.patchValue(this.imageUrls.length ? this.imageUrls : null);
    }
  }

  onSubmitEditInvitationForm(): void {
    this.formTriedToSubmit = true;
    if (this.editInvitationForm.valid) {
      this.isLoaderActive = true;
      const formData: FormData = new FormData();
      for (const key in this.editInvitationForm.value) {
        if (Array.isArray(this.editInvitationForm.value[key])) {
          if (key === 'images') {
            this.editInvitationForm.value[key].forEach((element: any, index: number) => {
              formData.append(`images[${index}][position]`, (index + 1).toString());
              (element.id)
                ? formData.append(`images[${index}][id]`, element.id.toString())
                : formData.append(`images[${index}][file]`, element.file);
            });
          } else {
            this.editInvitationForm.value[key].forEach((element: any) => {
              formData.append(`${key}[]`, element);
            });
          }
        }
      }
      this.invitationsHttpService.putInvitation({invitationId: this.invitationForEdit.id}, formData).then((res: any) => {
        this.toastService.success('Uspešno ste izmenili dizajn pozivnica.');
        this.isLoaderActive = false;
        this.formTriedToSubmit = false;
        this.confirmModal.emit(res.data);
      });
    }
  }
}
