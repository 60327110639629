import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormControlErrorMessagesModule} from '../../../pipes/form-control-error-messages/form-control-error-messages.module';
import {DropdownComponent} from './dropdown.component';



@NgModule({
  declarations: [DropdownComponent],
    imports: [
        CommonModule,
        FormControlErrorMessagesModule
    ],
  exports: [DropdownComponent]
})
export class DropdownModule { }
