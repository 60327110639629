import {Component, OnInit} from '@angular/core';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';
import {AuthenticationHelperService} from '../../services/helpers/authentication-helper.service';
import {BusinessProfilesHttpService} from '../../services/http-services/business-profiles/business-profiles-http.service';

@Component({
  selector: 'gr-side-menu',
  templateUrl: './side-menu.component.html',
})
export class SideMenuComponent implements OnInit {
  isPagesDropdownOpen = false;
  isBusinessUsersDropdownOpen = false;
  businessCategories: any[] = [];
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(public authHelper: AuthenticationHelperService,
              private businessProfilesHttpService: BusinessProfilesHttpService) {
  }

  ngOnInit(): void {
    this.getBusinessCategories();
  }

  getBusinessCategories(): void {
    this.businessProfilesHttpService.getBusinessCategories().then(res => {
      this.businessCategories = res.data.data;
    });
  }

  handleOpenPagesDropdown(): void {
    this.isPagesDropdownOpen = !this.isPagesDropdownOpen;
  }

  handleOpenBusinessUsersDropdown(): void {
    this.isBusinessUsersDropdownOpen = !this.isBusinessUsersDropdownOpen;
  }
}
