import {Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {PresentsHttpService} from '../../../services/http-services/presents/presents-http.service';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';

@Component({
  selector: 'app-create-or-edit-present',
  templateUrl: './create-or-edit-present.component.html',
})
export class CreateOrEditPresentComponent {
  urlParams = {presentId: null};
  formTriedToSubmit = false;
  createOrEditPresentForm!: FormGroup;
  toyStores: any[] = [];
  imageUrls: any[] = [];
  deletedImagesIds: number[] = [];
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmCreateDelivery: EventEmitter<any> = new EventEmitter<any>();
  @Output() confirmEditDelivery: EventEmitter<any> = new EventEmitter<any>();
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(private formBuilder: FormBuilder,
              private toastService: ToastrService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private presentsHttpService: PresentsHttpService) {
  }
  ngOnInit(): void {
    this.buildForm();
    this.activatedRoute.params.subscribe(res => {
      this.urlParams.presentId = res.id;
      if (this.urlParams.presentId) {
        this.getSinglePresent();
      }
    });
    this.getToyStores();
  }
  buildForm(): void {
    this.createOrEditPresentForm = this.formBuilder.group({
      name: ['', Validators.required],
      supplier_id: ['', Validators.required],
      images: [null, Validators.required],
      barcode: ['', Validators.required],
      product_code: ['', Validators.required],
      price_for_user: ['', Validators.required],
      wholesale_price: ['', Validators.required],
      rebate: [null],
      quantity: ['', Validators.required],
      gender: ['male', Validators.required],
      age_range: ['0-2', Validators.required],
      is_free: [false],
      description: ['', Validators.required],
      deleted_images_ids: [[]]
    });
  }

  getSinglePresent(): void {
    this.presentsHttpService.getSinglePresent(this.urlParams).then(res => {
      this.createOrEditPresentForm.patchValue(res.data);
      this.createOrEditPresentForm.controls.rebate.patchValue(res.data.rebate ? res.data.rebate : res.data.supplier.rebate);
      this.createOrEditPresentForm.controls.is_free.patchValue(!!res.data.is_free);
      (!res.data.images?.length)
        ? this.createOrEditPresentForm.controls.images.patchValue(null)
        : this.imageUrls = res.data.images.map((item: any) => {
            return {fullPath: item.full_path, id: item.id, file: undefined};
          });
      this.createOrEditPresentForm.controls.supplier_id.valueChanges.subscribe(value => {
        const selectedSupplier = this.toyStores.find(store => store.id === value);
        this.createOrEditPresentForm.controls.rebate.patchValue(selectedSupplier.rebate);
      });
    });
  }

  getToyStores(): void {
    this.presentsHttpService.getToyStores().then(res => {
      this.toyStores = res.data;
    });
  }

  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const selectedFiles = Array.from(input.files);
      if (selectedFiles.length > 5 - this.imageUrls.length) {
        this.toastService.warning('Neke od slika nece biti uploadovane zato sto ste selektovali vise slika od broja slobodnih slotova, maksimalno mozete dodati 5 slika');
      }
      selectedFiles.forEach(file => {
        if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/svg') {
          this.toastService.warning('Pogrešan tip dokumenta. Samo JPG, JPEG, SVG i PNG formati su dozvoljeni.');
          return;
        } else if (file?.size > 2097152) {
          this.toastService.warning(`Slika ${file.name} je veća od 2MB.`);
          return;
        } else if (this.imageUrls.length < 5) {
          this.imageUrls.push({fullPath: URL.createObjectURL(file), id: undefined, file});
        }
      });
      this.createOrEditPresentForm.controls.images.patchValue(this.imageUrls);
      if (this.fileInput) {
        this.fileInput.nativeElement.value = '';
      }
    }
  }

  removeAllImages(): void {
    this.imageUrls.forEach((image: any) => {
      if (image.id) {
        this.deletedImagesIds.push(image.id);
      }
    });
    this.imageUrls = [];
    this.createOrEditPresentForm.controls.deleted_images_ids.patchValue(this.deletedImagesIds);
    this.createOrEditPresentForm.controls.images.patchValue(null);
  }

  removeSingleImage(imageURL: any): void {
    const imageIndex = this.imageUrls.findIndex((imgUrl: any) => {
      return imgUrl.fullPath === imageURL.fullPath;
    });
    if (imageIndex !== -1) {
      if (imageURL.id) {
        this.deletedImagesIds.push(imageURL.id);
      }
      this.imageUrls.splice(imageIndex, 1);
      this.createOrEditPresentForm.controls.deleted_images_ids.patchValue(this.deletedImagesIds);
      this.createOrEditPresentForm.controls.images.patchValue(this.imageUrls.length ? this.imageUrls : null);
    }
  }

  onSubmitPresentForm(): void {
    this.formTriedToSubmit = true;
    if (this.createOrEditPresentForm.valid) {
      const formData: FormData = new FormData();
      for (const key in this.createOrEditPresentForm.value) {
        if (Array.isArray(this.createOrEditPresentForm.value[key]) ) {
          if (key === 'images') {
            this.createOrEditPresentForm.value[key].forEach((element: any, index: number) => {
              formData.append(`images[${index}][position]`, (index + 1).toString());
              (element.id)
                ? formData.append(`images[${index}][id]`, element.id.toString())
                : formData.append(`images[${index}][file]`, element.file);
            });
          } else {
            this.createOrEditPresentForm.value[key].forEach((element: any) => {
              formData.append(`${key}[]`, element);
            });
          }
        } else {
          if (key === 'is_free') {
            formData.append(key, this.createOrEditPresentForm.value[key] ? '1' : '0');
          } else if (key === 'rebate' && this.createOrEditPresentForm.value[key] === null) {
          } else {
            formData.append(key, this.createOrEditPresentForm.value[key]);
          }
        }
      }
      this.urlParams.presentId
        ? this.presentsHttpService.putPresent(this.urlParams, formData).then((res: any) => {
          this.toastService.success('Uspešno ste izmenili poklon.');
          this.confirmEditDelivery.emit(res.data);
          this.formTriedToSubmit = false;
          this.router.navigate([PAGES_AND_PATHS.presents.pagePath]);
        })
        : this.presentsHttpService.postPresent(formData).then((res: any) => {
          this.toastService.success('Uspešno ste dodali poklon.');
          this.confirmCreateDelivery.emit(res.data);
          this.formTriedToSubmit = false;
          this.router.navigate([PAGES_AND_PATHS.presents.pagePath]);
        });
    }
  }
}
