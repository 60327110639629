import {Component, EventEmitter, Input, Output} from '@angular/core';
import {GrPriceListCategory, GrUser} from '../../../../interfaces/general-interfaces';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BusinessProfilesHttpService} from '../../../../services/http-services/business-profiles/business-profiles-http.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'gr-create-or-edit-price-list-category-modal',
  templateUrl: './create-or-edit-price-list-category-modal.component.html',
})
export class CreateOrEditPriceListCategoryModalComponent {
  createOrEditPriceListCategoryForm!: FormGroup;
  formTriedToSubmit = false;
  @Input() priceListCategoryForEdit: GrPriceListCategory | null = null;
  @Input() urlParams: any;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmModal: EventEmitter<void> = new EventEmitter<void>();

  constructor(private formBuilder: FormBuilder,
              private toastrService: ToastrService,
              private businessProfilesHttpService: BusinessProfilesHttpService) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.createOrEditPriceListCategoryForm = this.formBuilder.group({
      name: ['', Validators.required],
    });
    if (this.priceListCategoryForEdit) {
      this.createOrEditPriceListCategoryForm.patchValue(this.priceListCategoryForEdit);
    }
  }

  submitCreateOrEditPriceListCategoryForm(): void {
    this.formTriedToSubmit = true;
    if (this.createOrEditPriceListCategoryForm.valid) {
      this.priceListCategoryForEdit
        ? this.businessProfilesHttpService.putPriceListCategory({...this.urlParams, categoryId: this.priceListCategoryForEdit?.id}, this.createOrEditPriceListCategoryForm.value).then(res => {
          this.toastrService.success('Uspešno ste izmenili kategoriju cenovnika.');
          this.confirmModal.emit();
        }).catch(({error}: {error:{ errors: { [key: string]: string } }}) => {
          Object.values(error.errors).forEach(error => this.toastrService.error(error));
        })
        : this.businessProfilesHttpService.postPriceListCategory(this.urlParams, this.createOrEditPriceListCategoryForm.value).then(res => {
          this.toastrService.success('Uspešno ste kreirali kategoriju cenovnika.');
          this.confirmModal.emit();
        }).catch(({error}: {error:{ errors: { [key: string]: string } }}) => {
          Object.values(error.errors).forEach(error => this.toastrService.error(error));
        });
    }
  }
}
