import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginRoutingModule} from './pages/login/login-routing.module';
import {PAGES_AND_PATHS} from './constants/pages-and-paths';
import {UserListRoutingModule} from './pages/user-list/user-list-routing.module';
import {ForgotPasswordRoutingModule} from './pages/forgot-password/forgot-password-routing.module';
import {ResetPasswordRoutingModule} from './pages/reset-password/reset-password-routing.module';
import {DashboardRoutingModule} from './pages/dashboard/dashboard-routing.module';
import {SettingsRoutingModule} from './pages/settings/settings-routing.module';
import {BusinessListRoutingModule} from './pages/business-list/business-list-routing.module';
import {ReservationsRoutingModule} from './pages/reservations/reservations-routing.module';
import {NotificationsRoutingModule} from './pages/notifications/notifications-routing.module';
import {BannersRoutingModule} from './pages/banners/banners-routing.module';
import {PagesRoutingModule} from './pages/pages/pages-routing.module';
import {FaqRoutingModule} from './pages/faq/faq-routing.module';
import {AdminListRoutingModule} from './pages/admin-list/admin-list-routing.module';
import {BusinessProfileRoutingModule} from './pages/business-profile/business-profile-routing.module';
import {PriceListCategoryRoutingModule} from './pages/business-profile/price-list-category/price-list-category-routing.module';
import {PriceListCategoryItemCreateOrEditRoutingModule} from './pages/business-profile/price-list-category-item-create-or-edit/price-list-category-item-create-or-edit-routing.module';
import {PresentsRoutingModule} from './pages/presents/presents-routing.module';
import {CreateOrEditPresentRoutingModule} from './pages/presents/create-or-edit-present/create-or-edit-present-routing.module';
import {SinglePresentRoutingModule} from './pages/presents/single-present/single-present-routing.module';
import {InvitationsRoutingModule} from './pages/invitations/invitations-routing.module';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: PAGES_AND_PATHS.login.pagePath
    },
];

@NgModule({
    imports: [
      RouterModule.forRoot(routes),
      LoginRoutingModule,
      ForgotPasswordRoutingModule,
      ResetPasswordRoutingModule,
      DashboardRoutingModule,
      SettingsRoutingModule,
      AdminListRoutingModule,
      UserListRoutingModule,
      BusinessListRoutingModule,
      ReservationsRoutingModule,
      NotificationsRoutingModule,
      BannersRoutingModule,
      PagesRoutingModule,
      FaqRoutingModule,
      BusinessProfileRoutingModule,
      PriceListCategoryRoutingModule,
      PriceListCategoryItemCreateOrEditRoutingModule,
      PresentsRoutingModule,
      CreateOrEditPresentRoutingModule,
      SinglePresentRoutingModule,
      InvitationsRoutingModule
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
