import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {NgClass, NgForOf, NgIf} from '@angular/common';
import {AddOrEditDeliveryServiceModalComponent} from '../add-or-edit-delivery-service-modal/add-or-edit-delivery-service-modal.component';
import {SettingsHttpService} from '../../../../services/http-services/settings/settings-http.service';
import {ConfirmModalModule} from '../../../../shared-components/modals/confirm-modal/confirm-modal.module';
import {ToastrService} from 'ngx-toastr';
import {FormControlErrorMessagesModule} from '../../../../pipes/form-control-error-messages/form-control-error-messages.module';

@Component({
  selector: 'gr-tab-administration-data',
  templateUrl: './tab-administration-data.component.html',
  imports: [
    FormsModule,
    NgIf,
    AddOrEditDeliveryServiceModalComponent,
    NgForOf,
    ConfirmModalModule,
    ReactiveFormsModule,
    FormControlErrorMessagesModule,
    NgClass
  ],
  standalone: true
})
export class TabAdministrationDataComponent implements OnInit {
  isCreateDeliveryServiceModal = false;
  deliveryServices: any[] = [];
  deliveryForDelete: any = null;
  deliveryForEdit: any = null;
  administrationData: any;
  administrationForm!: FormGroup;
  formTriedToSubmit = false;
  isAdministrationFormDisabled = true;

  constructor(private settingsHttpService: SettingsHttpService,
              private toastService: ToastrService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.getDeliveryServices();
    this.getAdministrationData();
  }

  buildForm(): void {
    this.administrationForm = this.formBuilder.group({
      all_secure_fee: ['', Validators.required],
      pay_spot_fee: ['', Validators.required],
      bank_fee: ['', Validators.required],
      app_fee: [0],
    });
    this.disabledAdministrationForm();
  }

  getDeliveryServices(): void {
    this.settingsHttpService.getDeliveries().then(res => {
      this.deliveryServices = res.data;
    });
  }

  getAdministrationData(): void {
    this.settingsHttpService.getAdministration().then(res => {
      this.administrationData = res.data;
      this.administrationForm.patchValue(this.administrationData);
    });
  }

  enableAdministrationForm(): void {
    this.isAdministrationFormDisabled = false;
    this.administrationForm.enable();
  }

  disabledAdministrationForm(): void {
    this.isAdministrationFormDisabled = true;
    this.administrationForm.disable();
  }

  onConfirmCreateDelivery(deliveryService: any): void {
    this.deliveryServices.push(deliveryService);
    this.isCreateDeliveryServiceModal = false;
  }

  onConfirmEditDelivery(deliveryService: any): void {
    const findDeliveryIndex = this.deliveryServices.findIndex(item => {
      return item.id = deliveryService.id;
    });
    if (findDeliveryIndex !== -1) {
      this.deliveryServices[findDeliveryIndex] = deliveryService;
    }
    this.deliveryForEdit = null;
  }

  onConfirmDeleteDelivery(): void {
    this.settingsHttpService.deleteDelivery({id: this.deliveryForDelete.id}).then(res => {
      this.deliveryServices = this.deliveryServices.filter(delivery => delivery.id !== this.deliveryForDelete.id);
      this.deliveryForDelete = null;
    });
  }

  onSubmitAdministrationForm(): void {
    this.formTriedToSubmit = true;
    if (this.administrationForm.valid) {
      this.settingsHttpService.putAdministration(this.administrationForm.value).then((res: any) => {
        this.isAdministrationFormDisabled = true;
        this.toastService.success('Uspešno ste izmenili administrativne podatke.');
        this.formTriedToSubmit = false;
      });
    }
  }
}
