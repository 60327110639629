<div class="grid">
  <div class="grid__m-12">
    <div action="" class="grid xs-pr-32 xs-pl-32 xs-pt-32">
      <div class="grid__m-12">
        <div class="wrapper-subtitle-page">
          <span class="subtitle-page">Dostava</span>
          <button type="button" class="zc-btn zc-btn--primary" (click)="isCreateDeliveryServiceModal = true"><span>Dodaj dostavljača</span></button>
        </div>
      </div>
      <div class="grid__m-12">
        <div class="zc-table">
          <div class="zc-header-table">
            <div class="zc-header-row">
              <div class="zc-header-column">
                <span>Ime dostavljača</span>
              </div>
              <div class="zc-header-column">
                <span>Grad</span>
              </div>
              <div class="zc-header-column">
                <span>Cena korisnik</span>
              </div>
              <div class="zc-header-column">
                <span>Cena kuriska služba</span>
              </div>
              <div class="zc-header-column column-buttons">
                <span>Alatke</span>
              </div>
            </div>
          </div>
          <div class="zc-content-table">
            <div class="zc-content-row" *ngFor="let deliveryService of deliveryServices">
              <div class="zc-content-column">
                <span>{{deliveryService.name}}</span>
              </div>
              <div class="zc-content-column">
                <span>{{deliveryService.city}}</span>
              </div>
              <div class="zc-content-column">
                <span>{{deliveryService.price_for_user}} RSD</span>
              </div>
              <div class="zc-content-column">
                <span>{{deliveryService.price_for_admin}} RSD</span>
              </div>
              <div class="zc-content-column column-buttons">
                <div class="buttons-group">
                  <div class="wrapper-button" (click)="deliveryForEdit = deliveryService">
                    <i class="fa-solid fa-pen-to-square"></i>
                  </div>
                  <div class="wrapper-button" (click)="deliveryForDelete = deliveryService">
                    <i class="fa-solid fa-trash"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="grid__m-12">
    <form [formGroup]="administrationForm" (ngSubmit)="onSubmitAdministrationForm()" class="grid xs-pr-32 xs-pl-32 xs-pt-32">
      <div class="grid__m-12">
        <span class="subtitle-page">Troškovi</span>
      </div>
      <div class="grid__m-6">
        <div class="wrapper-input">
          <span class="label-input">AllSecure (upisati vrednost u %)</span>
          <div class="zc-input">
            <input type="text" formControlName="all_secure_fee" [ngClass]="{'not-allowed': isAdministrationFormDisabled}">
          </div>
          <span class="error-message">{{administrationForm.controls.all_secure_fee | formControlErrorMessages: null : formTriedToSubmit}}</span>
        </div>
      </div>
      <div class="grid__m-6">
        <div class="wrapper-input">
          <span class="label-input">PaySpot (upisati vrednost u %)</span>
          <div class="zc-input">
            <input type="text" formControlName="pay_spot_fee" [ngClass]="{'not-allowed': isAdministrationFormDisabled}">
          </div>
          <span class="error-message">{{administrationForm.controls.pay_spot_fee | formControlErrorMessages: null : formTriedToSubmit}}</span>
        </div>
      </div>
      <div class="grid__m-6">
        <div class="wrapper-input">
          <span class="label-input">Banka (upisati vrednost u %)</span>
          <div class="zc-input">
            <input type="text" formControlName="bank_fee" [ngClass]="{'not-allowed': isAdministrationFormDisabled}">
          </div>
          <span class="error-message">{{administrationForm.controls.bank_fee | formControlErrorMessages: null : formTriedToSubmit}}</span>
        </div>
      </div>
      <div class="grid__m-6">
        <div class="wrapper-input">
          <span class="label-input">Naknada po porudžbini</span>
          <div class="zc-input">
            <input type="text" formControlName="app_fee" [ngClass]="{'not-allowed': isAdministrationFormDisabled}">
          </div>
          <span class="error-message">{{administrationForm.controls.app_fee | formControlErrorMessages: null : formTriedToSubmit}}</span>
        </div>
      </div>
      <div class="grid__m-12 button-settings-tab">
        <button *ngIf="isAdministrationFormDisabled" (click)="enableAdministrationForm()" type="button" class="zc-btn zc-btn--primary"><span>Izmeni podatke</span></button>
        <button *ngIf="!isAdministrationFormDisabled" type="submit" class="zc-btn zc-btn--primary"><span>Sačuvaj</span></button>
      </div>
    </form>
  </div>
</div>

<gr-add-or-edit-delivery-service-modal
  *ngIf="isCreateDeliveryServiceModal || deliveryForEdit !== null"
  [deliveryForEdit]="deliveryForEdit"
  (closeModal)="isCreateDeliveryServiceModal = false; deliveryForEdit = null"
  (confirmCreateDelivery)="onConfirmCreateDelivery($event)"
  (confirmEditDelivery)="onConfirmEditDelivery($event)">
</gr-add-or-edit-delivery-service-modal>

<gr-confirm-modal
  *ngIf="deliveryForDelete !== null"
  [modalTitle]="'Obriši dostavljača'"
  [modalDescription]="'Da li ste sigurni da želite da obriši dostavljača?'"
  (closeModal)="deliveryForDelete = null"
  (confirmModal)="onConfirmDeleteDelivery()"
></gr-confirm-modal>
