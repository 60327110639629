import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {GrCity} from '../../../../interfaces/general-interfaces';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DigitRegex, MbRegex, MobileRegex, PhoneRegex, PibRegex} from '../../../../constants/general-variables';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {GeneralHttpService} from '../../../../services/http-services/general/general-http.service';
import {BusinessProfilesHttpService} from '../../../../services/http-services/business-profiles/business-profiles-http.service';
import {PAGES_AND_PATHS} from '../../../../constants/pages-and-paths';

@Component({
  selector: 'gr-base-data',
  templateUrl: './base-data.component.html',
})
export class BaseDataComponent implements OnInit, OnChanges {
  listOfCities: GrCity[] = [];
  listOfPictograms: any[] = [];
  listOfCategories: any[] = [];
  listOfMunicipalities: any[] = [];
  isBusinessProfileFormDisabled = true;
  editBusinessProfileForm!: FormGroup;
  formTriedToSubmit = false;
  urlParams = { companyId: null };
  imageUrls: any[] = [];
  deletedImagesIds: number[] = [];
  isDisabledBusinessCategory = false;
  @Input() businessProfileData: any;
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
  constructor(private formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private toastService: ToastrService,
              private generalHttpService: GeneralHttpService,
              private businessProfilesHttpService: BusinessProfilesHttpService) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.activatedRoute.params.subscribe((res: any) => {
      this.urlParams.companyId = res.id;
    });
    this.getCities();
    this.getPictograms();
    this.getCategories();
    this.editBusinessProfileForm.controls.business_category_id.valueChanges.subscribe(value => {
      if (value === 1) {
        this.editBusinessProfileForm.addControl('age_range_from', this.formBuilder.control('', Validators.required));
        this.editBusinessProfileForm.addControl('age_range_to', this.formBuilder.control('', Validators.required));
        this.editBusinessProfileForm.addControl('children_capacity', this.formBuilder.control('', Validators.required));
        this.editBusinessProfileForm.addControl('adult_capacity', this.formBuilder.control('', Validators.required));
      } else {
        this.editBusinessProfileForm.removeControl('age_range_from');
        this.editBusinessProfileForm.removeControl('age_range_to');
        this.editBusinessProfileForm.removeControl('children_capacity');
        this.editBusinessProfileForm.removeControl('adult_capacity');
      }
    });
    this.editBusinessProfileForm.controls.business_location_id.valueChanges.subscribe(value => {
      (value)
        ? this.getMunicipalities(value)
        : this.editBusinessProfileForm.controls.municipality_id.setValue('');
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.businessProfileData && changes.businessProfileData.currentValue) {
      this.editBusinessProfileForm?.patchValue(changes.businessProfileData.currentValue);
      const initialSelectedPictograms = changes.businessProfileData.currentValue.pictograms.map((pictogram: any) => {
        return pictogram.id;
      });
      this.imageUrls = changes.businessProfileData.currentValue.images.map((item: any) => {
        return {fullPath: item.full_path, id: item.id, file: undefined};
      });
      this.editBusinessProfileForm?.controls.pictograms_ids.patchValue(initialSelectedPictograms);
      this.editBusinessProfileForm?.controls.time_start.patchValue(changes.businessProfileData.currentValue.time_start?.slice(0, -3));
      this.editBusinessProfileForm?.controls.time_end.patchValue(changes.businessProfileData.currentValue.time_end?.slice(0, -3));
      if (changes.businessProfileData.currentValue.business_category_id) {
        this.isDisabledBusinessCategory = true;
      }
    }
  }

  buildForm(): void {
    this.editBusinessProfileForm = this.formBuilder.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      phone_number: ['', [Validators.required, Validators.pattern(PhoneRegex)]],
      mobile_number: ['', [Validators.required, Validators.pattern(MobileRegex)]],
      identification_number: ['', [Validators.required, Validators.pattern(PibRegex)]],
      tax_identification_number: ['', [Validators.required, Validators.pattern(MbRegex)]],
      business_location_id: ['', Validators.required],
      municipality_id: ['', Validators.required],
      workingMunicipalities: [[], Validators.required],
      rebate: ['', Validators.required],
      max_order_quantity: ['', Validators.pattern(DigitRegex)],
      active: [false],
      age_range_from: [''],
      age_range_to: [''],
      highlighted: [false],
      recommended: [false],
      latitude: ['', Validators.required],
      longitude: ['', Validators.required],
      description: ['', Validators.required],
      time_start: ['', Validators.required],
      time_end: ['', Validators.required],
      business_category_id: ['', Validators.required],
      pictograms_ids: [[], Validators.required],
      images: [null, Validators.required],
      deleted_images_ids: [[]]
    });
    this.disabledBusinessProfileForm();
  }

  enableBusinessProfileForm(): void {
    this.isBusinessProfileFormDisabled = false;
    this.editBusinessProfileForm.enable();
  }

  disabledBusinessProfileForm(): void {
    this.isBusinessProfileFormDisabled = true;
    this.editBusinessProfileForm.disable();
  }

  getCities(): void {
    this.generalHttpService.getCities().then(res => {
      this.listOfCities = res.data;
    });
  }

  getPictograms(): void {
    this.generalHttpService.getPictograms().then(res => {
      this.listOfPictograms = res.data;
    });
  }

  getCategories(): void {
    this.generalHttpService.getBusinessCategories().then(res => {
      this.listOfCategories = res.data;
    });
  }

  getMunicipalities(cityId: number): void {
    this.generalHttpService.getMunicipalities({id: cityId}).then((res: any) => {
      this.listOfMunicipalities = res.data.municipalities;
    });
  }

  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const selectedFiles = Array.from(input.files);
      if (selectedFiles.length > 10 - this.imageUrls.length) {
        this.toastService.warning('Neke od slika nece biti uploadovane zato sto ste selektovali vise slika od broja slobodnih slotova, maksimalno mozete dodati 10 slika');
      }
      selectedFiles.forEach(file => {
        if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/svg') {
          this.toastService.warning('Pogrešan tip dokumenta. Samo JPG, JPEG, SVG i PNG formati su dozvoljeni.');
          return;
        } else if (file?.size > 2097152) {
          this.toastService.warning(`Slika ${file.name} je veća od 2MB.`);
          return;
        } else if (this.imageUrls.length < 10) {
          this.imageUrls.push({fullPath: URL.createObjectURL(file), id: undefined, file});
        }
      });
      this.editBusinessProfileForm.controls.images.patchValue(this.imageUrls);
      if (this.fileInput) {
        this.fileInput.nativeElement.value = '';
      }
    }
  }

  removeAllImages(): void {
    this.imageUrls.forEach((image: any) => {
      if (image.id) {
        this.deletedImagesIds.push(image.id);
      }
    });
    this.imageUrls = [];
    this.editBusinessProfileForm.controls.deleted_images_ids.patchValue(this.deletedImagesIds);
    this.editBusinessProfileForm.controls.images.patchValue(null);
  }

  removeSingleImage(imageURL: any): void {
    if (!this.isBusinessProfileFormDisabled) {
      const imageIndex = this.imageUrls.findIndex((imgUrl: any) => {
        return imgUrl.fullPath === imageURL.fullPath;
      });
      if (imageIndex !== -1) {
        if (imageURL.id) {
          this.deletedImagesIds.push(imageURL.id);
        }
        this.imageUrls.splice(imageIndex, 1);
        this.editBusinessProfileForm.controls.deleted_images_ids.patchValue(this.deletedImagesIds);
        this.editBusinessProfileForm.controls.images.patchValue(this.imageUrls.length ? this.imageUrls : null);
      }
    }
  }

  submitEditBusinessProfileForm(): void {
    this.formTriedToSubmit = true;
    if (this.editBusinessProfileForm.valid) {
      const formData: FormData = new FormData();
      for (const key in this.editBusinessProfileForm.value) {
        if (Array.isArray(this.editBusinessProfileForm.value[key]) ) {
          if (key === 'images') {
            this.editBusinessProfileForm.value[key].forEach((element: any, index: number) => {
              formData.append(`images[${index}][position]`, (index + 1).toString());
              (element.id)
                ? formData.append(`images[${index}][id]`, element.id.toString())
                : formData.append(`images[${index}][file]`, element.file);
            });
          } else {
            this.editBusinessProfileForm.value[key].forEach((element: any) => {
              formData.append(`${key}[]`, element);
            });
          }
        } else {
          formData.append(key, this.editBusinessProfileForm.value[key]);
        }
      }
      this.businessProfilesHttpService.postBusinessProfile(this.urlParams, formData).then(res => {
        this.isBusinessProfileFormDisabled = true;
        this.toastService.success(res.message);
      }).catch(({error}: {error: { errors: { [key: string]: string } }}) => {
        Object.values(error.errors).forEach(error => this.toastService.error(error));
      });
    }
  }
}
