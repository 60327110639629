<div class="zc-custom-pagination">
  <ul>
    <li *ngFor="let item of collection | paginate: { itemsPerPage: perPage, currentPage: currentPage , totalItems: totalItems, id: pagId}"> ... </li>
  </ul>

  <pagination-controls
    [previousLabel]="''"
    [nextLabel]="''"
    (pageChange)="onPageChanged($event)"
    id="{{pagId}}">
  </pagination-controls>
</div>

