import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BusinessProfilesHttpService} from '../../../../services/http-services/business-profiles/business-profiles-http.service';
import {GrCity, GrPagination, GrPriceListCategory} from '../../../../interfaces/general-interfaces';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BusinessProfileTabs} from '../../../../enums/business-enums';
import {DaysOfWeek} from '../../../../constants/general-variables';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {GeneralHttpService} from '../../../../services/http-services/general/general-http.service';

@Component({
  selector: 'gr-categories',
  templateUrl: './categories.component.html',
})
export class CategoriesComponent implements OnInit{
  formTriedToSubmit = false;
  urlParams = { companyId: null };
  isCreatePriceListCategoryModalOpen = false;
  priceListCategories: GrPriceListCategory[] = [];
  priceListCategoryForEdit: GrPriceListCategory | null = null;
  priceListCategoryForDelete: GrPriceListCategory | null = null;
  priceListCategoriesPagination: GrPagination | undefined;
  constructor(private formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private toastService: ToastrService,
              private generalHttpService: GeneralHttpService,
              private businessProfilesHttpService: BusinessProfilesHttpService) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((res: any) => {
      this.urlParams.companyId = res.id;
      this.getPriceListCategories();
    });
  }

  getPriceListCategories(): void {
    const page = (this.priceListCategoriesPagination?.currentPage) ? this.priceListCategoriesPagination.currentPage : 1;
    this.businessProfilesHttpService.getPriceListCategories({page, ...this.urlParams}).then(res => {
      this.priceListCategories = res.data;
      this.priceListCategoriesPagination = res.pagination;
    });
  }

  onPageChanged(page: any): void {
    if (this.priceListCategoriesPagination) {
      this.priceListCategoriesPagination.currentPage = page;
      this.getPriceListCategories();
    }
  }

  onConfirmCreateOrEditPriceListCategory(): void {
    this.priceListCategoryForEdit = null;
    this.isCreatePriceListCategoryModalOpen = false;
    this.getPriceListCategories();
  }

  onConfirmDeletePriceListCategory(): void {
    this.businessProfilesHttpService.deletePriceListCategory({...this.urlParams, categoryId: this.priceListCategoryForDelete?.id}).then((res: any) => {
      this.priceListCategoryForDelete = null;
      this.getPriceListCategories();
      this.toastService.success('Usepšeno ste obrisali kategoriju cenovnika.')
    }).catch(({error}: {error:{ errors: { [key: string]: string } }}) => {
      Object.values(error.errors).forEach(error => this.toastService.error(error));
    });
  }
}
