export enum LocalStorageProperties {
    token = 'gr-admin-token',
    user = 'gr-admin-user'
}

export enum UserStatus {
  active = 'active',
  inactive = 'inactive'
}

export enum UserRole {
  admin = 'admin',
  user = 'user',
  business = 'business',
}


export enum InputDebounceTime {
  time = 600
}
