import { Component } from '@angular/core';
import {GrPagination, GrUser} from '../../interfaces/general-interfaces';
import {UserRole} from '../../enums/general-enums';
import {UsersHttpService} from '../../services/http-services/users/users-http.service';
import {ToastrService} from 'ngx-toastr';
import {GetUsersApiDataFormatted} from '../../services/http-services/users/users-http-interfaces';

@Component({
  selector: 'gr-admin-list',
  templateUrl: './admin-list.component.html',
})
export class AdminListComponent {
  adminUsersList: GrUser[] = [];
  isCreateAdminUserModalOpen = false;
  adminUserForEdit: GrUser | null = null;
  adminUserForDelete: GrUser | null = null;
  adminUsersPagination: GrPagination | undefined;
  urlParams: any = {role: UserRole.admin, search: null};

  constructor(private usersHttpService: UsersHttpService,
              private toastService: ToastrService) {
  }
  ngOnInit(): void {
    this.getAdminUsers();
  }

  getAdminUsers(): void {
    const page = (this.adminUsersPagination?.currentPage) ? this.adminUsersPagination.currentPage : 1;
    this.usersHttpService.getUsers({page, ...this.urlParams}).then((res: GetUsersApiDataFormatted) => {
      this.adminUsersList = res.data;
      this.adminUsersPagination = res.pagination;
    });
  }

  onSearchChange(searchQuery: string): void {
    this.urlParams.search = searchQuery;
    if (this.adminUsersPagination?.currentPage) {
      this.adminUsersPagination.currentPage = 1;
    }
    this.getAdminUsers();
  }

  onPageChanged(page: any): void {
    if (this.adminUsersPagination) {
      this.adminUsersPagination.currentPage = page;
      this.getAdminUsers();
    }
  }

  onConfirmCreateOrEditAdminUser(): void {
    this.adminUserForEdit = null;
    this.isCreateAdminUserModalOpen = false;
    this.getAdminUsers();
  }

  removeAdminUser(userId: number | null): void {
    if (userId) {
      this.usersHttpService.deleteUser({userId}).then(res => {
        this.getAdminUsers();
        this.toastService.success('Uspešno ste obrisali admin korisnika.');
      }, error => {
        this.toastService.warning('Došlo je do greške, pokušajte ponovo.');
      });
    }
    this.adminUserForDelete = null;
  }
}
