import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PAGES_AND_PATHS} from '../../../../constants/pages-and-paths';
import {PresentsHttpService} from '../../../../services/http-services/presents/presents-http.service';
import {select} from '@ngrx/store';
import {GrPagination} from '../../../../interfaces/general-interfaces';
import {ToastrService} from 'ngx-toastr';
import {SearchModule} from '../../../../shared-components/search/search.module';
import {DropdownModule} from '../../../../shared-components/dropdowns/dropdown/dropdown.module';
import {NgClass, NgForOf, NgIf} from '@angular/common';
import {RouterLink} from '@angular/router';
import {PaginationModule} from '../../../../shared-components/pagination/pagination.module';
import {AddPresentsActionModalComponent} from '../add-presents-action-modal/add-presents-action-modal.component';
import {ConfirmModalModule} from '../../../../shared-components/modals/confirm-modal/confirm-modal.module';

@Component({
  selector: 'gr-tab-presents',
  templateUrl: './tab-presents.component.html',
  imports: [
    SearchModule,
    DropdownModule,
    NgClass,
    RouterLink,
    PaginationModule,
    AddPresentsActionModalComponent,
    ConfirmModalModule,
    NgIf,
    NgForOf
  ],
  standalone: true
})
export class TabPresentsComponent implements OnInit, OnChanges {
  @Input() stores: any[] = [];
  @Input() isPresentsTableChanged = false;
  @Output() checkedPresentsChange: EventEmitter<number[]> = new EventEmitter<number[]>();
  presents: any[] = [];
  presentsPagination: GrPagination | undefined;
  urlParams: any = {page: 1, search: '', supplier_id: null, sort: null, direction: null};
  presentForDelete: any = null;
  storeFilters: any[] = [];
  isAllPagePresentChecked = false;
  checkedPresentIds: number[] = [];
  currentPagePresentIds: any[] = [];
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
  protected readonly select = select;

  constructor(private presentsHttpService: PresentsHttpService,
              private toastService: ToastrService) {
  }

  ngOnInit(): void {
    this.getPresents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.stores && changes.stores.currentValue) {
      this.storeFilters = [{name: 'All', id: null}, ...changes.stores.currentValue];
    }

    if (changes.isPresentsTableChanged && changes.isPresentsTableChanged.currentValue) {
      this.checkedPresentIds = [];
      this.presentsPagination = undefined;
      this.getPresents();
    }
  }

  getPresents(): void {
    this.urlParams.page = (this.presentsPagination?.currentPage) ? this.presentsPagination.currentPage : 1;
    this.presentsHttpService.getPresents(this.urlParams).then(res => {
      this.presents = res.data;
      this.presentsPagination = res.pagination;
      this.currentPagePresentIds = res.data.map((present: any) => present.id);
      this.checkIsAllCurrentPagePresentChecked();
    });
  }

  onSearchChange(searchQuery: string): void {
    this.urlParams.search = searchQuery;
    this.presentsPagination = undefined;
    this.getPresents();
  }

  onPageChanged(page: any): void {
    if (this.presentsPagination) {
      this.presentsPagination.currentPage = page;
      this.getPresents();
    }
  }

  onSortChange(type: string): void {
    if (this.urlParams.sort === type) {
      this.urlParams.direction === 'asc' ? this.urlParams.direction = 'desc' : this.urlParams.direction = 'asc';
    } else {
      this.urlParams.sort = type;
      this.urlParams.direction = 'asc';
    }
    this.getPresents();
  }

  onConfirmDeletePresent(): void {
    this.presentsHttpService.deletePresent({presentId: this.presentForDelete.id}).then(res => {
      this.toastService.success('Uspešno ste obrisali poklon');
      this.getPresents();
    });
  }

  onFilterChange(filter: any): void {
    this.urlParams.supplier_id = filter.id;
    this.presentsPagination = undefined;
    this.getPresents();
  }

  onCheckAllPresentsChange(): void {
    this.isAllPagePresentChecked = !this.isAllPagePresentChecked;
    if (this.isAllPagePresentChecked) {
      this.checkedPresentIds = [...this.checkedPresentIds, ...this.currentPagePresentIds];
    } else {
      this.checkedPresentIds = this.checkedPresentIds.filter(presentId => !this.currentPagePresentIds.includes(presentId));
    }
    this.checkedPresentsChange.emit(this.checkedPresentIds);
  }

  onCheckPresent(presentId: number): void {
    const presentIndex = this.checkedPresentIds.findIndex(item => item === presentId);
    if (presentIndex === -1) {
      this.checkedPresentIds.push(presentId);
      this.checkIsAllCurrentPagePresentChecked();
    } else {
      this.checkedPresentIds.splice(presentIndex, 1);
      this.isAllPagePresentChecked = false;
    }
    this.checkedPresentsChange.emit(this.checkedPresentIds);
  }

  checkIsAllCurrentPagePresentChecked(): void {
    this.isAllPagePresentChecked = this.currentPagePresentIds.every(presentId => this.checkedPresentIds.includes(presentId));
  }

  isPresentChecked(presentId: number): boolean {
    return this.checkedPresentIds.includes(presentId);
  }
}
