import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {GeneralHttpHelperService} from '../../helpers/general-http-helper.service';
import {HttpClient} from '@angular/common/http';

const NOTIFICATION_API_ROUTES = {
  GET_SUPPORT: 'admin/change-requests',
  GET_CUSTOM_NOTIFICATION: 'admin/custom-notifications',
  CREATE_CUSTOM_NOTIFICATION: 'admin/custom-notifications',
  EDIT_CUSTOM_NOTIFICATION: 'admin/custom-notifications/:notificationId',
};
@Injectable({
  providedIn: 'root'
})
export class NotificationsHttpService {

  constructor(private generalHttpHelperService: GeneralHttpHelperService,
              private httpClient: HttpClient) { }
  private getSupportNotificationsReq(urlParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.addUrlParams(NOTIFICATION_API_ROUTES.GET_SUPPORT, urlParams);
    return this.httpClient.get(apiUrl) as any;
  }

  getSupportNotifications(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getSupportNotificationsReq(urlParams).subscribe((res: any) => {
        resolve(
          {
            data: res.data.data,
            pagination: this.generalHttpHelperService.returnPagination(res.data)
          }
        );
      }, error => {
        reject(error);
      });
    });
  }

  private getCustomNotificationsReq(urlParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.addUrlParams(NOTIFICATION_API_ROUTES.GET_CUSTOM_NOTIFICATION, urlParams);
    return this.httpClient.get(apiUrl) as any;
  }

  getCustomNotifications(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getCustomNotificationsReq(urlParams).subscribe((res: any) => {
        resolve(
          {
            data: res.data.data,
            pagination: this.generalHttpHelperService.returnPagination(res.data)
          }
        );
      }, error => {
        reject(error);
      });
    });
  }

  private createCustomNotificationReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(NOTIFICATION_API_ROUTES.CREATE_CUSTOM_NOTIFICATION, bodyParams) as any;
  }

  createCustomNotification(bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.createCustomNotificationReq(bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private editCustomNotificationReq(urlParams: any, bodyParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(NOTIFICATION_API_ROUTES.EDIT_CUSTOM_NOTIFICATION, urlParams);
    return this.httpClient.post(apiUrl, bodyParams) as any;
  }

  editCustomNotification(urlParams: any, bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.editCustomNotificationReq(urlParams, bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
}
