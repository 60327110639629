<gr-terms-first-step
  [urlParams]="urlParams"
  [termsData]="termsData"
  [sameSlots]="sameSlots"
  [sameWorkTime]="sameWorkTime"
  (submitForm)="getTermsData()">
</gr-terms-first-step>
<gr-terms-second-step
  *ngIf="termsData?.data?.calendars && termsData.data.calendars.length"
  [urlParams]="urlParams"
  [termsData]="termsData"
  [sameSlots]="sameSlots">
</gr-terms-second-step>
