import {Component} from '@angular/core';
import {BusinessProfilesHttpService} from '../../services/http-services/business-profiles/business-profiles-http.service';
import {ActivatedRoute} from '@angular/router';
import {BusinessProfileTabs} from '../../enums/business-enums';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';

@Component({
  selector: 'gr-business-profile',
  templateUrl: './business-profile.component.html',
})
export class BusinessProfileComponent {
  isBusinessApproved: null | boolean = null;
  urlParams = { companyId: null };
  businessProfileTabs = BusinessProfileTabs;
  activeTab = BusinessProfileTabs.baseData;
  isBusinessProfileRequestFinished = false;
  businessData: any;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(private businessProfilesHttpService: BusinessProfilesHttpService,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((res: any) => {
      this.urlParams.companyId = res.id;
      this.getBusinessProfile();
    });
  }

  getBusinessProfile(): void {
    this.isBusinessProfileRequestFinished = false;
    this.businessProfilesHttpService.getSingleBusiness(this.urlParams).then(res => {
      this.isBusinessApproved = res.data.approved;
      this.businessData = res.data;
      this.isBusinessProfileRequestFinished = true;
    });
  }

  approveOrRejectBusinessProfile(approve: number): void {
    this.isBusinessApproved = !!approve;
    this.businessProfilesHttpService.putApproveBusiness(this.urlParams, {approve}).then(res => {});
  }
}
