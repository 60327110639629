import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControlErrorMessagesPipe } from './form-control-error-messages.pipe';

@NgModule({
  declarations: [
    FormControlErrorMessagesPipe
  ],
  exports: [
    FormControlErrorMessagesPipe
  ],
  imports: [
    CommonModule
  ]
})
export class FormControlErrorMessagesModule { }
