import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UsersHttpService} from '../../../../services/http-services/users/users-http.service';
import {EmailRegex, PasswordRegex, PhoneRegex} from '../../../../constants/general-variables';
import {UserProfileApiData} from '../../../../services/http-services/users/users-http-interfaces';
import {confirmMatchControlsValidator} from '../../../../validators/form-validators.validator';
import {GrUser} from '../../../../interfaces/general-interfaces';

@Component({
  selector: 'gr-create-or-edit-admin-modal',
  templateUrl: './create-or-edit-admin-modal.component.html',
})
export class CreateOrEditAdminModalComponent implements OnInit {
  createEditAdminForm!: FormGroup;
  formTriedToSubmit = false;
  isPasswordShow = false;
  isConfirmPasswordShow = false;
  @Input() adminUserForEdit: GrUser | null = null;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmModal: EventEmitter<any> = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder,
              private usersHttpService: UsersHttpService) {}
  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.createEditAdminForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(EmailRegex)]],
      phone: ['', [Validators.required, Validators.pattern(PhoneRegex)]],
      password: [''],
      password_confirmation: [''],
      role: ['admin', !this.adminUserForEdit ? Validators.required : []]
    }, {
      validators: confirmMatchControlsValidator('password', 'password_confirmation')
    });
    if (this.adminUserForEdit) {
      this.createEditAdminForm.controls.password.clearValidators();
      this.createEditAdminForm.controls.password_confirmation.clearValidators();
      this.createEditAdminForm.patchValue(this.adminUserForEdit);
    } else {
      this.createEditAdminForm.controls.password.setValidators([Validators.required, Validators.pattern(PasswordRegex)]);
      this.createEditAdminForm.controls.password_confirmation.setValidators([Validators.required, Validators.pattern(PasswordRegex)]);
    }
    this.createEditAdminForm.controls.password.updateValueAndValidity();
    this.createEditAdminForm.controls.password_confirmation.updateValueAndValidity();
  }

  submitCreateOrEditAdminForm(): void {
    this.formTriedToSubmit = true;
    if (this.createEditAdminForm.valid) {
      this.adminUserForEdit
      ? this.usersHttpService.putUser({userId: this.adminUserForEdit.id}, this.createEditAdminForm.value).then((res: UserProfileApiData) => {
          this.confirmModal.emit(res);
        })
      : this.usersHttpService.postUser(this.createEditAdminForm.value).then((res: UserProfileApiData) => {
        this.confirmModal.emit(res);
      });
    }
  }
}
