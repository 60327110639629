import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class BaseUrl implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): any {
        const baseUrl = environment.API_ENDPOINT;
        const apiRequest = req.clone({url: baseUrl + req.url});
        return next.handle(apiRequest);
    }
}
