import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {PAGES_AND_PATHS} from '../constants/pages-and-paths';
import {AuthenticationHelperService} from '../services/helpers/authentication-helper.service';
import {ActivePageHelperService} from '../services/helpers/active-page-helper.service';


@Injectable()
export class AuthGuardService {

  constructor(public authenticationService: AuthenticationHelperService,
              private activePageHelper: ActivePageHelperService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean {
    if (route.data && route.data.data) {
      this.activePageHelper.activePage = route.data.data;
    }
    if (!this.authenticationService.isAuthenticated) {
      this.router.navigate([PAGES_AND_PATHS.login.pagePath]);
    }
    return this.authenticationService.isAuthenticated;
  }

}
