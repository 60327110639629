import { Component } from '@angular/core';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';
import {PresentsTabsEnums} from '../../enums/presents-enums';
import {PresentsHttpService} from '../../services/http-services/presents/presents-http.service';

@Component({
  selector: 'app-presents',
  templateUrl: './presents.component.html',
})
export class PresentsComponent {
  isCreateToyStoreModalOpen = false;
  isUploadPresentsTableModalOpen = false;
  isAddPresentsActionModalOpen = false;
  isPresentsTableChanged = false;
  activeTab = PresentsTabsEnums.presents;
  stores: any[] = [];
  checkedPresentIds: number[] = [];
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
  protected readonly PresentsTabsEnums = PresentsTabsEnums;

  constructor(private presentsHttpService: PresentsHttpService) {
  }
  ngOnInit(): void {
    this.getToyStores();
  }

  getToyStores(): void {
    this.presentsHttpService.getToyStores().then(res => {
      this.stores = res.data;
    });
  }

  onConfirmCreateToyStore(store: any): void {
    this.stores.push(store);
    this.isCreateToyStoreModalOpen = false;
  }

  onNewPresentsTableUploaded(): void {
    this.isUploadPresentsTableModalOpen = false;
    this.isPresentsTableChanged = true;
    setTimeout(() => {
      this.isPresentsTableChanged = false;
    }, 3000);
  }

  onConfirmAddPresentAction(): void {
    this.isAddPresentsActionModalOpen = false;
    this.isPresentsTableChanged = true;
    setTimeout(() => {
      this.isPresentsTableChanged = false;
    }, 3000);
  }
}
