<div class="wrapper-modal">
  <form [formGroup]="createEditAdminForm" (ngSubmit)="submitCreateOrEditAdminForm()" class="wrapper-content-modal">
    <div class="header-modal">
      <span class="title-modal">{{adminUserForEdit ? 'Izmeni' : 'Dodaj'}} admin korisnika</span>
    </div>
    <div class="content-modal">
      <div class="wrapper-input">
        <span class="label-input">Ime</span>
        <div class="zc-input">
          <input type="text" [formControl]="createEditAdminForm.controls.first_name | formControl" [placeholder]="'Ime'">
        </div>
        <span class="error-message">{{createEditAdminForm.controls.first_name | formControlErrorMessages: null : formTriedToSubmit}}</span>
      </div>
      <div class="wrapper-input">
        <span class="label-input">Prezime</span>
        <div class="zc-input">
          <input type="text" [formControl]="createEditAdminForm.controls.last_name | formControl" [placeholder]="'Prezime'">
        </div>
        <span class="error-message">{{createEditAdminForm.controls.last_name | formControlErrorMessages: null : formTriedToSubmit}}</span>
      </div>
      <div class="wrapper-input">
        <span class="label-input">E-pošta</span>
        <div class="zc-input">
          <input type="text" [formControl]="createEditAdminForm.controls.email | formControl" [placeholder]="'E-pošta'">
        </div>
        <span class="error-message">{{createEditAdminForm.controls.email | formControlErrorMessages: 'Unesite validnu E-poštu.' : formTriedToSubmit}}</span>
      </div>
      <div class="wrapper-input">
        <span class="label-input">Telefon</span>
        <div class="zc-input">
          <input type="text" [formControl]="createEditAdminForm.controls.phone | formControl" [placeholder]="'Telefon'">
        </div>
        <span class="error-message">{{createEditAdminForm.controls.phone | formControlErrorMessages: 'Unesite validan broj telefona.' : formTriedToSubmit}}</span>
      </div>
      <div class="wrapper-input" *ngIf="!adminUserForEdit">
        <span class="label-input">Lozinka</span>
        <div class="zc-input">
          <input [type]="isPasswordShow ? 'text' : 'password'" [formControl]="createEditAdminForm.controls.password | formControl" [placeholder]="'********'">
          <i *ngIf="!isPasswordShow" (click)="isPasswordShow = true" class="fa-solid fa-eye"></i>
          <i *ngIf="isPasswordShow" (click)="isPasswordShow = false" class="fa-solid fa-eye-slash"></i>
        </div>
        <span class="error-message">{{createEditAdminForm.controls['password'] | formControlErrorMessages: 'Minimum 8 karaktera, jedno veliko slovo, broj i jedan specijalni karakter.' : formTriedToSubmit}}</span>
      </div>
      <div class="wrapper-input" *ngIf="!adminUserForEdit">
        <span class="label-input">Potvrdite lozinku</span>
        <div class="zc-input">
          <input [type]="isConfirmPasswordShow ? 'text' : 'password'" [formControl]="createEditAdminForm.controls.password_confirmation | formControl" [placeholder]="'********'">
          <i *ngIf="!isConfirmPasswordShow" (click)="isConfirmPasswordShow = true" class="fa-solid fa-eye"></i>
          <i *ngIf="isConfirmPasswordShow" (click)="isConfirmPasswordShow = false" class="fa-solid fa-eye-slash"></i>
        </div>
        <span class="error-message">{{createEditAdminForm.controls['password_confirmation'] | formControlErrorMessages: 'Minimum 8 karaktera, jedno veliko slovo, broj i jedan specijalni karakter.' : formTriedToSubmit}}</span>
      </div>
    </div>
    <div class="footer-modal">
      <button type="button" (click)="closeModal.emit()" class="zc-btn zc-btn--gray"><span>Izađi</span></button>
      <button type="submit" class="zc-btn zc-btn--primary"><span>{{adminUserForEdit ? 'Izmeni' : 'Dodaj'}}</span></button>
    </div>
  </form>
</div>
