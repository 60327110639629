<div class="wrapper-page reservations-page">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div class="wrapper-content-page">
    <div class="wrapper-header-page-content">
      <span class="header-text">Rezervacije i kupljeni pokloni</span>
      <!--      <button class="zc-btn zc-btn&#45;&#45;primary"><span>Dodaj</span></button>-->
    </div>
    <div class="zc-wrapper-tabs">
      <div class="zc-tab">
        <span>Rezervacije</span>
      </div>
      <div class="zc-tab active">
        <span>Kupljeni pokloni</span>
      </div>
    </div>
    <div *ngIf="false" class="tab-reservations">
      Rezervacije
    </div>
    <div *ngIf="true" class="tab-gifts">
       - Bice lista kupljenih poklona <br>
       - Svaki poklon ce da ima od podatka (Naziv artikla, barcode, opis i ime dobavljaca) <br>
       - Artikli ce biti poredjani na denvnom nivou, recimo 22.11 kupljene tri igracke pa 23.11 kupljene 8 igracke <br>
       - Postojace dugme gde ce moci da se skine pdf sa poklonima, kako bi oni poslali dobavljacima sta hoce da naruce
      <br>
       - Imace filter po prodavnici da na osnovu toga mzoe da i odstampa pdf <br>
       - Ubaciti datepicker kako bi sortirali po datumu sta zele da stampaju <br>
       - prikazati inicajalno posljenjih 30 dana
    </div>
  </div>
</div>
