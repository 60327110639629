import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PriceListCategoryComponent} from './price-list-category.component';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {CommentsComponent} from '../../comments/comments.component';
import {AuthGuardService} from '../../../guards/auth-guard.service';
import {PriceListComponent} from '../../price-list/price-list.component';
import {BusinessProfileComponent} from '../business-profile.component';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.priceListCategory.pageInRouting,
    data: {data: PAGES_AND_PATHS.priceListCategory},
    component: PriceListCategoryComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./price-list-category.module').then(m => m.PriceListCategoryModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PriceListCategoryRoutingModule { }
