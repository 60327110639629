<div class="zc-wrapper-search">
  <i class="fa-solid fa-magnifying-glass"></i>
  <input
    [(ngModel)]="searchQuery"
    (ngModelChange)="searchSubject.next($event)"
    type="text"
    name="search"
    autocomplete="off"
    [ngClass]="inputClasses"
    [placeholder]="searchPlaceholder"
    class="{{ inputClass }}"
  />
</div>
