<div class="wrapper-notifications" (scroll)="onScroll($event)">
  <div class="wrapper-notification support" *ngFor="let support of supportNotifications">
    <div class="wrapper-info-support">
      <span class="title-notification">{{support.change_request_type.name}}</span>
      <span class="description-notification">{{support.description}}</span>
      <div *ngIf="support.images?.length" class="wrapper-images-support grid">
        <div *ngFor="let image of support.images" (click)="imageForPreview = image.full_path" class="grid__m-2">
          <img [src]="image.full_path" alt="">
        </div>
      </div>
      <span class="description-notification"><b>Korisnik: &nbsp;</b> {{support.user.full_name}}</span>
      <span class="description-notification"><b>Biznis korisnik: &nbsp;</b> {{support.user.company.name}}</span>
      <span class="description-notification"><b>Mob: &nbsp;</b> {{support.user.phone}}</span>
      <span class="description-notification"><b>Email:&nbsp; </b> <a href="mailto:{{ support.user.email }}">{{support.user.email}}</a></span>
      <span class="time-notification">{{support.created_at | timeAgo}}</span>
    </div>

  </div>
</div>
<div @fadeInFadeOut *ngIf="imageForPreview" class="preview-image-modal">
  <div (click)="imageForPreview = null" class="close-modal">
    <i class="fa-solid fa-xmark"></i>
  </div>
  <img [src]="imageForPreview" alt="">
</div>
