import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {GetCategoriesApiData, GetCitiesApiData} from './general-http-interfaces';
import {GeneralHttpHelperService} from '../../helpers/general-http-helper.service';

const GENERAL_API_ROUTES = {
  GET_ALL_CATEGORIES: 'business-profile',
  GET_CITIES: 'business-locations',
  GET_MUNICIPALITY: 'business-locations/:id',
  GET_PICTOGRAMS: 'admin/pictograms',
  GET_BUSINESS_CATEGORIES: 'business-categories',
};
@Injectable({
  providedIn: 'root'
})
export class GeneralHttpService {

  constructor(private httpClient: HttpClient,
              private generalHttpHelperService: GeneralHttpHelperService) { }
  private getAllCategoriesReq(): Observable<GetCategoriesApiData> {
    return this.httpClient.get(GENERAL_API_ROUTES.GET_ALL_CATEGORIES) as any;
  }

  getAllCategories(): Promise<GetCategoriesApiData> {
    return new Promise<GetCategoriesApiData>((resolve, reject) => {
      this.getAllCategoriesReq().subscribe((res: GetCategoriesApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private getCitiesReq(): Observable<GetCitiesApiData> {
    return this.httpClient.get(GENERAL_API_ROUTES.GET_CITIES) as any;
  }

  getCities(): Promise<GetCitiesApiData> {
    return new Promise<GetCitiesApiData>((resolve, reject) => {
      this.getCitiesReq().subscribe((res: GetCitiesApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private getMunicipalityReq(urlParams: any): Observable<any> {
    const url = this.generalHttpHelperService.replaceUrlParamsWithValues(GENERAL_API_ROUTES.GET_MUNICIPALITY, urlParams)
    return this.httpClient.get(url) as any;
  }

  getMunicipalities(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getMunicipalityReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private getPictogramsReq(): Observable<any> {
    return this.httpClient.get(GENERAL_API_ROUTES.GET_PICTOGRAMS) as any;
  }

  getPictograms(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getPictogramsReq().subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private getBusinessCategoriesReq(): Observable<any> {
    return this.httpClient.get(GENERAL_API_ROUTES.GET_BUSINESS_CATEGORIES) as any;
  }

  getBusinessCategories(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getBusinessCategoriesReq().subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
}
