import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DaysOfWeek} from '../../../../../constants/general-variables';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BusinessProfilesHttpService} from '../../../../../services/http-services/business-profiles/business-profiles-http.service';
import {ToastrService} from 'ngx-toastr';
import {GetTermsApiData} from '../../../../../services/http-services/business-profiles/business-profiles-http-interfaces';

@Component({
  selector: 'gr-terms-first-step',
  templateUrl: './terms-first-step.component.html',
})
export class TermsFirstStepComponent implements OnInit, OnChanges {
  termsFirstStepForm!: FormGroup;
  formTriedToSubmit = false;
  @Input() termsData!: GetTermsApiData;
  @Input() urlParams: any;
  @Input() sameWorkTime = false;
  @Input() sameSlots = false;
  @Output() submitForm: EventEmitter<void> = new EventEmitter<void>;
  initialCheckedDays: any = [];
  protected readonly daysOfWeek = DaysOfWeek;

  constructor(private formBuilder: FormBuilder,
              private toastrService: ToastrService,
              private businessProfilesHttpService: BusinessProfilesHttpService) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.termsData && changes.termsData.currentValue) {
      this.patchFormData();
    }
  }

  buildForm(): void {
    this.termsFirstStepForm = this.formBuilder.group({
      days: this.formBuilder.array([]),
      everyDaySameWorkingTime: [false],
      workingTimeStart: [''],
      workingTimeEnd: [''],
      everyDaySameSlots: [false],
      timeSlot: [''],
      breakTime: [''],
      individualWorkingTimes: this.formBuilder.array([]),
      individualWorkingSlotsAndBreaks: this.formBuilder.array([]),
    });

    this.termsFirstStepForm.controls.everyDaySameWorkingTime.valueChanges.subscribe(val => {
      const workingTimeStart = this.termsFirstStepForm.get('workingTimeStart');
      const workingTimeEnd = this.termsFirstStepForm.get('workingTimeEnd');
      const individualTimesArray = this.termsFirstStepForm.controls.individualWorkingTimes as FormArray;

      if (val) {
        workingTimeStart?.setValidators([Validators.required]);
        workingTimeEnd?.setValidators([Validators.required]);
        individualTimesArray.clear();
      } else {
        workingTimeStart?.clearValidators();
        workingTimeEnd?.clearValidators();
        const daysArray = this.termsFirstStepForm.controls.days as FormArray;
        individualTimesArray.clear();
        daysArray.controls.forEach(dayControl => {
          individualTimesArray.push(
            this.formBuilder.group({
              day: dayControl.value,
              workingTimeStart: ['', Validators.required],
              workingTimeEnd: ['', Validators.required]
            })
          );
        });
      }
      workingTimeStart?.updateValueAndValidity();
      workingTimeEnd?.updateValueAndValidity();
    });

    this.termsFirstStepForm.controls.everyDaySameSlots.valueChanges.subscribe(val => {
      const timeSlot = this.termsFirstStepForm.get('timeSlot');
      const breakTime = this.termsFirstStepForm.get('breakTime');
      const individualWorkingSlotsAndBreaks = this.termsFirstStepForm.controls.individualWorkingSlotsAndBreaks as FormArray;

      if (val) {
        timeSlot?.setValidators([Validators.required]);
        breakTime?.setValidators([Validators.required]);
        individualWorkingSlotsAndBreaks.clear();
      } else {
        timeSlot?.clearValidators();
        breakTime?.clearValidators();
        const daysArray = this.termsFirstStepForm.controls.days as FormArray;
        individualWorkingSlotsAndBreaks.clear();
        daysArray.controls.forEach(dayControl => {
          individualWorkingSlotsAndBreaks.push(
            this.formBuilder.group({
              day: dayControl.value,
              timeSlot: ['', Validators.required],
              breakTime: ['', Validators.required]
            })
          );
        });
      }
      timeSlot?.updateValueAndValidity();
      breakTime?.updateValueAndValidity();
    });
  }

  patchFormData(): void {
    console.log('termsData', this.termsData);
    this.termsFirstStepForm.controls.everyDaySameWorkingTime.patchValue(this.sameWorkTime);
    this.termsFirstStepForm.controls.everyDaySameSlots.patchValue(this.sameSlots);
    this.termsFirstStepForm.controls.workingTimeStart.patchValue(this.termsData.data.calendars[0].working_time_start.slice(0, -3));
    this.termsFirstStepForm.controls.workingTimeEnd.patchValue(this.termsData.data.calendars[0].working_time_end.slice(0, -3));
    this.termsData.data.calendars.forEach(calendarDate => {
      this.initialCheckedDays.push(calendarDate.day);
      const selectedDays = this.termsFirstStepForm.controls.days as FormArray;
      const individualTimesArray = this.termsFirstStepForm.controls.individualWorkingTimes as FormArray;
      const individualWorkingSlotsAndBreaks = this.termsFirstStepForm.controls.individualWorkingSlotsAndBreaks as FormArray;
      selectedDays.push(new FormControl(calendarDate.day));
      if (!this.termsFirstStepForm.value.everyDaySameWorkingTime) {
        individualTimesArray.push(
          this.formBuilder.group({
            day: calendarDate.day,
            workingTimeStart: [calendarDate.working_time_start.slice(0, -3), Validators.required],
            workingTimeEnd: [calendarDate.working_time_end.slice(0, -3), Validators.required]
          })
        );
      }
      if (this.termsFirstStepForm.controls.everyDaySameSlots.value) {
        this.termsFirstStepForm.controls.timeSlot.patchValue(this.termsData.data.calendars[0].time_slot);
        this.termsFirstStepForm.controls.breakTime.patchValue(this.termsData.data.calendars[0].break_time);
      } else {
        individualWorkingSlotsAndBreaks.push(
          this.formBuilder.group({
            day: calendarDate.day,
            timeSlot: [calendarDate.time_slot, Validators.required],
            breakTime: [calendarDate.break_time, Validators.required],
          })
        );
      }
    });
  }

  onDaysCheckboxChange(event: any): void {
    const selectedDays = this.termsFirstStepForm.controls.days as FormArray;
    const individualTimesArray = this.termsFirstStepForm.controls.individualWorkingTimes as FormArray;
    const individualWorkingSlotsAndBreaks = this.termsFirstStepForm.controls.individualWorkingSlotsAndBreaks as FormArray;

    if (event.target.checked) {
      selectedDays.push(new FormControl(event.target.value));
      if (!this.termsFirstStepForm.value.everyDaySameWorkingTime) {
        individualTimesArray.push(
          this.formBuilder.group({
            day: event.target.value,
            workingTimeStart: ['', Validators.required],
            workingTimeEnd: ['', Validators.required]
          })
        );
      }
      if (!this.termsFirstStepForm.value.everyDaySameSlots) {
        individualWorkingSlotsAndBreaks.push(
          this.formBuilder.group({
            day: event.target.value,
            timeSlot: ['', Validators.required],
            breakTime: ['', Validators.required],
          })
        );
      }
    } else {
      const index = selectedDays.controls.findIndex((x: any) => x.value === event.target.value);
      selectedDays.removeAt(index);
      if (!this.termsFirstStepForm.value.everyDaySameWorkingTime) {
        individualTimesArray.removeAt(index);
      }
      if (!this.termsFirstStepForm.value.everyDaySameSlots) {
        individualWorkingSlotsAndBreaks.removeAt(index);
      }
    }
  }

  getIndividualTimeGroup(index: number): FormGroup {
    const individualTimesArray = this.termsFirstStepForm.get('individualWorkingTimes') as FormArray;
    return individualTimesArray.at(index) as FormGroup;
  }

  getIndividualSlotsGroup(index: number): FormGroup {
    const individualWorkingSlotsAndBreaksArray = this.termsFirstStepForm.get('individualWorkingSlotsAndBreaks') as FormArray;
    return individualWorkingSlotsAndBreaksArray.at(index) as FormGroup;
  }

  onSubmitTermsFirstStepForm(): void {
    this.formTriedToSubmit = true;
    if (this.termsFirstStepForm.valid) {
      const bodyParams = {calendars: []};
      bodyParams.calendars = this.termsFirstStepForm.controls.days.value.map((day: string, index: number) => {
        const dayObj = {
          day,
          working_time_start: '',
          working_time_end: '',
          break_time: '',
          time_slot: '',
        };
        if (this.termsFirstStepForm.controls.everyDaySameWorkingTime.value) {
          dayObj.working_time_start = this.termsFirstStepForm.controls.workingTimeStart.value;
          dayObj.working_time_end = this.termsFirstStepForm.controls.workingTimeEnd.value;
        } else {
          dayObj.working_time_start = this.termsFirstStepForm.controls.individualTimesArray.value[index].workingTimeStart;
          dayObj.working_time_end = this.termsFirstStepForm.controls.individualTimesArray.value[index].workingTimeEnd;
        }

        if (this.termsFirstStepForm.controls.everyDaySameSlots.value) {
          dayObj.break_time = this.termsFirstStepForm.controls.breakTime.value;
          dayObj.time_slot = this.termsFirstStepForm.controls.timeSlot.value;
        } else {
          dayObj.break_time = this.termsFirstStepForm.controls.individualWorkingSlotsAndBreaks.value[index].breakTime;
          dayObj.time_slot = this.termsFirstStepForm.controls.individualWorkingSlotsAndBreaks.value[index].timeSlot;
        }
        return dayObj;
      });
      this.businessProfilesHttpService.postTermsFirstStep(this.urlParams, bodyParams).then(res => {
        this.submitForm.emit();
        this.toastrService.success(res.message);
      });
    }
  }
}
