import {Pipe, PipeTransform} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {minLengthArray} from '../../validators/form-validators.validator';

@Pipe({
  name: 'formControlErrorMessages',
  pure: false
})
export class FormControlErrorMessagesPipe implements PipeTransform {
  transform(formControl: AbstractControl, customPatternMsg: string | null, submittedForm: boolean | undefined, ...args: unknown[]): string {
    let isRequired: any;
    if (formControl.validator) {
      isRequired = formControl.validator({} as AbstractControl);
    }
    if (!formControl.value && submittedForm && isRequired) {
      return 'Ovo polje je obavezno.';
    } else if (((formControl.dirty && formControl.touched && formControl.invalid) || submittedForm) && !formControl.value && isRequired){
      return 'Ovo polje je obavezno.';
    } else if (((formControl.dirty && formControl.touched && formControl.invalid) || submittedForm) && formControl.errors?.minlength) {
      return 'Minimum ' + formControl.errors?.minlength.requiredLength + ' karaktera.';
    } else if (((formControl.dirty && formControl.touched && formControl.invalid) || submittedForm) && formControl.errors?.confirmedValidator) {
      return 'Lozinke se ne poklapaju.';
    } else if (customPatternMsg && ((formControl.dirty && formControl.touched && formControl.invalid) || submittedForm) && formControl.errors?.pattern) {
      return customPatternMsg;
    } else if (((formControl.dirty && formControl.touched && formControl.invalid) || submittedForm) && formControl.errors?.minLengthArray) {
      return 'Potrebno je dodati minimum jedan ukus.';
    } else {
      return '';
    }
  }
}

