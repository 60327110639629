import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {AuthGuardService} from '../../../guards/auth-guard.service';
import {SinglePresentComponent} from './single-present.component';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.singlePresent.pageInRouting,
    data: {data: PAGES_AND_PATHS.singlePresent},
    component: SinglePresentComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./single-present.module').then(m => m.SinglePresentModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SinglePresentRoutingModule { }
