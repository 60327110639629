import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';

@Component({
  selector: 'gr-date-picker',
  templateUrl: './date-picker.component.html',
})
export class DatePickerComponent implements OnChanges {
  @Input() datepickerLabel = '';
  @Input() defaultDate?: string;
  @Input() isOpen = false;
  @Output() dateSelected: EventEmitter<Date> = new EventEmitter<Date>();
  selectedDate!: Date;
  currentDate: Date = new Date();
  // minDate: Date = new Date(this.currentDate.getFullYear() - 10, this.currentDate.getMonth(), this.currentDate.getDate());

  protected readonly Date = Date;

  constructor() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultDate && changes.defaultDate.currentValue) {
      this.selectedDate = new Date(changes.defaultDate.currentValue);
    }
  }

  onDateChange(date: MatDatepickerInputEvent<any, any>): void {
    this.dateSelected.emit(date.value);
  }
}
