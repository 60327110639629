import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DropdownReactiveFormComponent} from './dropdown-reactive-form.component';
import {FormControlErrorMessagesModule} from '../../../pipes/form-control-error-messages/form-control-error-messages.module';



@NgModule({
  declarations: [DropdownReactiveFormComponent],
    imports: [
        CommonModule,
        FormControlErrorMessagesModule
    ],
  exports: [DropdownReactiveFormComponent]
})
export class DropdownReactiveFormModule { }
