<div class="wrapper-input">
  <span class="label-input">{{dropdownLabel}}</span>
  <div class="zc-wrapper-dropdown" [ngClass]="{'open': isDropdownOpen}">
    <div class="dropdown-label" #dropdownBtn (click)="toggleDropdown($event)">
      <span>{{selectedItem ? selectedItem[propForView] : placeholder}}</span>
      <i class="fa-solid fa-chevron-up"></i>
    </div>
    <div class="selected-pictogram-wrapper" *ngIf="selectedItems.length">
      <div class="item-pictogram" *ngFor="let item of selectedItems">
        <span class="label-pictogram">{{item.name}}</span>
        <button class="close-pictogram" [disabled]="disableSelect" (click)="pushOrRemoveItemFromArray(item)">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>
    </div>
    <div class="zc-dropdown-collapsed">
      <div
        *ngFor="let item of items"
        (click)="onSelectItem($event, item)"
        class="item-dropdown">
        <span>{{item[propForView]}}</span>
      </div>
    </div>
  </div>
  <span class="error-message">{{formControl | formControlErrorMessages: null: formTriedToSubmit}}</span>
</div>
