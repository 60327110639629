import {Component, OnInit} from '@angular/core';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';
import {InvitationsTabsEnums, PresentsTabsEnums} from '../../enums/presents-enums';
import {InvitationsHttpService} from '../../services/http-services/invitations/invitations-http.service';

@Component({
  selector: 'app-invitations',
  templateUrl: './invitations.component.html',
})
export class InvitationsComponent implements OnInit {
    activeTab = InvitationsTabsEnums.boys;
    boysInvitations: any[] = [];
    girlsInvitations: any[] = [];
    invitationForEdit: any = null;
    protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
    protected readonly InvitationsTabsEnums = InvitationsTabsEnums;

    constructor(private invitationsHttpService: InvitationsHttpService) {
    }

    ngOnInit(): void {
      this.getInvitations();
    }

  getInvitations(): void {
    this.invitationsHttpService.getInvitations().then(res => {
      this.boysInvitations = res.data.boys;
      this.girlsInvitations = res.data.girls;
    });
  }

  onConfirmEditInvitation(newDesign: any): void {
    if (this.invitationForEdit.gender === this.InvitationsTabsEnums.boys) {
      this.boysInvitations = this.boysInvitations.map(boyDesign => boyDesign.id === newDesign.id ? newDesign : boyDesign);
    } else {
      this.girlsInvitations = this.girlsInvitations.map(girlDesign => girlDesign.id === newDesign.id ? newDesign : girlDesign);
    }
    this.invitationForEdit = null;
  }
}
