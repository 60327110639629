<div class="wrapper-page gifts-page">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div class="wrapper-content-page">
    <div class="wrapper-header-page-content">
      <div class="wrapper-back-btn">
        <div class="back-btn" [routerLink]="[PAGES_AND_PATHS.presents.pagePath]">
          <i class="fa-solid fa-chevron-left"></i>
        </div>
        <span class="header-text">{{this.urlParams.presentId ? 'Izmena poklona' : 'Dodavanje poklona'}}</span>
      </div>
    </div>
    <form [formGroup]="createOrEditPresentForm" (ngSubmit)="onSubmitPresentForm()" class="grid xs-p-32">
      <div class="grid__m-6">
        <div class="wrapper-input">
          <span class="label-input">Naziv artikla</span>
          <div class="zc-input">
            <input type="text" formControlName="name">
          </div>
          <span class="error-message">{{createOrEditPresentForm.controls.name | formControlErrorMessages: null : formTriedToSubmit}}</span>
        </div>
      </div>
      <div class="grid__m-6">
        <gr-dropdown-reactive-form
          [items]="toyStores"
          [propIdentifier]="'id'"
          [dropdownLabel]="'Prodavnica igračaka'"
          [placeholder]="'Prodavnica igračaka'"
          [formControl]="createOrEditPresentForm.controls.supplier_id | formControl"
          [formTriedToSubmit]="formTriedToSubmit">
        </gr-dropdown-reactive-form>
      </div>
      <div class="grid__m-12">
        <div class="wrapper-custom-gallery">
          <div class="header-custom-gallery">
            <span class="title-gallery">Galerija</span>
            <div class="wrapper-btn-gallery">
              <div class="file-input">
                <input
                  #fileInput
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  name="file-input"
                  id="file-input"
                  class="file-input__input"
                  multiple
                  (change)="onFileChange($event)"
                />
                <label class="file-input__label" for="file-input"><span>Dodaj galeriju</span></label>
              </div>
              <button (click)="removeAllImages()" class="zc-btn zc-btn--delete" type="button"><span>Obriši sve slike</span></button>
            </div>
          </div>
          <div class="content-custom-gallery">
            <div class="wrapper-gallery-images">
              <div *ngFor="let imgUrl of imageUrls" class="wrapper-gallery-image">
                <img [src]="imgUrl.fullPath" alt="">
                <span class="icon-delete"
                      (click)="removeSingleImage(imgUrl)">
                    <i class="fa-regular fa-trash-can"></i>
                  </span>
              </div>
            </div>
          </div>
          <span class="error-message">{{createOrEditPresentForm.controls.images | formControlErrorMessages: null : formTriedToSubmit}}</span>
        </div>
      </div>
      <div class="grid__m-6">
        <div class="wrapper-input">
          <span class="label-input">Barcode</span>
          <div class="zc-input">
            <input type="text" formControlName="barcode">
          </div>
          <span class="error-message">{{createOrEditPresentForm.controls.barcode | formControlErrorMessages: null : formTriedToSubmit}}</span>
        </div>
      </div>
      <div class="grid__m-6">
        <div class="wrapper-input">
          <span class="label-input">Šifra</span>
          <div class="zc-input">
            <input type="text" formControlName="product_code">
          </div>
          <span class="error-message">{{createOrEditPresentForm.controls.product_code | formControlErrorMessages: null : formTriedToSubmit}}</span>
        </div>
      </div>
      <div class="grid__m-6">
        <div class="wrapper-input">
          <span class="label-input">MP cena</span>
          <div class="zc-input">
            <input type="text" formControlName="price_for_user">
          </div>
          <span class="error-message">{{createOrEditPresentForm.controls.price_for_user | formControlErrorMessages: null : formTriedToSubmit}}</span>
        </div>
      </div>
      <div class="grid__m-6">
        <div class="wrapper-input">
          <span class="label-input">VP cena</span>
          <div class="zc-input">
            <input type="text" formControlName="wholesale_price">
          </div>
          <span class="error-message">{{createOrEditPresentForm.controls.wholesale_price | formControlErrorMessages: null : formTriedToSubmit}}</span>
        </div>
      </div>
      <div class="grid__m-6">
        <div class="wrapper-input">
          <span class="label-input">Rabat</span>
          <div class="zc-input">
            <input type="text" formControlName="rebate">
          </div>
          <span class="error-message">{{createOrEditPresentForm.controls.rebate | formControlErrorMessages: null : formTriedToSubmit}}</span>
        </div>
      </div>
      <div class="grid__m-6">
        <div class="wrapper-input">
          <span class="label-input">Količina</span>
          <div class="zc-input">
            <input type="text" formControlName="quantity">
          </div>
          <span class="error-message">{{createOrEditPresentForm.controls.quantity | formControlErrorMessages: null : formTriedToSubmit}}</span>
        </div>
      </div>
      <div class="grid__m-12">
        <div class="title-checkbox">Izaberite pol</div>
        <div class="wrapper-custom-radiobutton">
          <label>
            <input type="radio" formControlName="gender" [value]="'male'">
            <div class="custom-radiobutton">
              <div class="circle-radio">
                <div class="circle-center"></div>
              </div>
              <span>Dečaci</span>
            </div>
          </label>
          <label>
            <input type="radio" formControlName="gender" [value]="'female'">
            <div class="custom-radiobutton">
              <div class="circle-radio">
                <div class="circle-center"></div>
              </div>
              <span>Devojčice</span>
            </div>
          </label>
          <label>
            <input type="radio" formControlName="gender" [value]="'universal'">
            <div class="custom-radiobutton">
              <div class="circle-radio">
                <div class="circle-center"></div>
              </div>
              <span>Univerzalno</span>
            </div>
          </label>
        </div>
        <span class="error-message">{{createOrEditPresentForm.controls.gender | formControlErrorMessages: null : formTriedToSubmit}}</span>
      </div>
      <div class="grid__m-12">
        <div class="title-checkbox">Izaberite opseg godina</div>
        <div class="wrapper-custom-radiobutton">
          <label>
            <input type="radio" formControlName="age_range" [value]="'0-2'">
            <div class="custom-radiobutton">
              <div class="circle-radio">
                <div class="circle-center"></div>
              </div>
              <span>0-2</span>
            </div>
          </label>
          <label>
            <input type="radio" formControlName="age_range" [value]="'2-4'">
            <div class="custom-radiobutton">
              <div class="circle-radio">
                <div class="circle-center"></div>
              </div>
              <span>2-4</span>
            </div>
          </label>
          <label>
            <input type="radio" formControlName="age_range" [value]="'4-6'">
            <div class="custom-radiobutton">
              <div class="circle-radio">
                <div class="circle-center"></div>
              </div>
              <span>4-6</span>
            </div>
          </label>
          <label>
            <input type="radio" formControlName="age_range" [value]="'6+'">
            <div class="custom-radiobutton">
              <div class="circle-radio">
                <div class="circle-center"></div>
              </div>
              <span>6+</span>
            </div>
          </label>
        </div>
        <span class="error-message">{{createOrEditPresentForm.controls.age_range | formControlErrorMessages: null : formTriedToSubmit}}</span>
      </div>
      <div class="grid__m-12">
        <div class="title-checkbox">Ukoliko želite da ovaj poklon bude gratis štiklirajte ispod</div>
        <div class="wrapper-checkbox-custom grid">
          <div class="grid__m-3">
            <input class="checkbox-custom" id="gratis-checkbox" type="checkbox" formControlName="is_free">
            <label class="checkbox-custom-label" for="gratis-checkbox">Gratis poklon</label>
          </div>
        </div>
      </div>
      <div class="grid__m-12">
        <div class="wrapper-quill-editor item-category xs-mr-0 xs-ml-0 xs-mt-0">
          <span class="title-editor">Opis artikla</span>
          <quill-editor placeholder="Unesite opis" formControlName="description"></quill-editor>
          <span class="error-message">{{createOrEditPresentForm.controls.description | formControlErrorMessages: null : formTriedToSubmit}}</span>
        </div>
      </div>
      <div class="grid__m-12">
        <button style="float: right;" class="zc-btn zc-btn--primary" type="submit"><span>{{this.urlParams.presentId ? 'Sačuvaj izmene' : 'Sačuvaj'}}</span></button>
      </div>
    </form>
  </div>
</div>
