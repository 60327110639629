<div class="wrapper-sidebar">
  <div class="header-sidebar">
    <div class="wrapper-logo">
      <img src="../assets/images/logo.svg" alt="">
    </div>
  </div>
  <div class="content-sidebar">
    <div class="wrapper-sidebar-item" [routerLink]="[PAGES_AND_PATHS.dashboard.pagePath]" routerLinkActive="active">
      <div class="sidebar-item">
        <div class="sidebar-item-info">
          <i class="fa-solid fa-house"></i>
          <span>Početna</span>
        </div>
      </div>
    </div>
    <div class="wrapper-sidebar-item" [routerLink]="[PAGES_AND_PATHS.reservations.pagePath]" routerLinkActive="active">
      <div class="sidebar-item">
        <div class="sidebar-item-info">
          <i class="fa-solid fa-calendar"></i>
          <span>Rezervacije</span>
        </div>
      </div>
    </div>
    <div class="wrapper-sidebar-item" [routerLink]="[PAGES_AND_PATHS.adminList.pagePath]" routerLinkActive="active">
      <div class="sidebar-item">
        <div class="sidebar-item-info">
          <i class="fa-solid fa-address-card"></i>
          <span>Admin korisnici</span>
        </div>
      </div>
    </div>
    <div class="wrapper-sidebar-item" [routerLink]="[PAGES_AND_PATHS.userList.pagePath]" routerLinkActive="active">
      <div class="sidebar-item">
        <div class="sidebar-item-info">
          <i class="fa-solid fa-user"></i>
          <span>Korisnici</span>
        </div>
      </div>
    </div>
    <div class="wrapper-sidebar-item" (click)="handleOpenBusinessUsersDropdown()" [ngClass]="{'collapsed': isBusinessUsersDropdownOpen}" routerLinkActive="active">
      <div class="sidebar-item">
        <div class="sidebar-item-info">
          <i class="fa-solid fa-address-card"></i>
          <span>Biznis korisnici</span>
        </div>
        <div class="wrapper-arrow">
          <i class="fa-solid fa-chevron-up"></i>
        </div>
      </div>
      <div (click)="$event.stopPropagation()" class="collapse-sidebar-item">
        <div *ngFor="let category of businessCategories" class="apiary-item" [routerLink]="[PAGES_AND_PATHS.businessList.pagePath, category.id]" routerLinkActive="active">
          <span>{{category.name}} ({{category.companies_count}})</span>
        </div>
      </div>
    </div>
    <div class="wrapper-sidebar-item" [routerLink]="[PAGES_AND_PATHS.presents.pagePath]" routerLinkActive="active">
      <div class="sidebar-item">
        <div class="sidebar-item-info">
          <i class="fa-solid fa-gift"></i>
          <span>Pokloni</span>
        </div>
      </div>
    </div>
    <div class="wrapper-sidebar-item" [routerLink]="[PAGES_AND_PATHS.notifications.pagePath]" routerLinkActive="active">
      <div class="sidebar-item">
        <div class="sidebar-item-info">
          <i class="fa-solid fa-bell"></i>
          <span>Obaveštenja</span>
        </div>
      </div>
    </div>
    <div class="wrapper-sidebar-item" [routerLink]="[PAGES_AND_PATHS.invitations.pagePath]" routerLinkActive="active">
      <div class="sidebar-item">
        <div class="sidebar-item-info">
          <i class="fa-solid fa-clipboard"></i>
          <span>Pozivnice</span>
        </div>
      </div>
    </div>
    <div class="wrapper-sidebar-item" [routerLink]="[PAGES_AND_PATHS.banners.pagePath]" routerLinkActive="active">
      <div class="sidebar-item">
        <div class="sidebar-item-info">
          <i class="fa-solid fa-puzzle-piece"></i>
          <span>Baneri</span>
        </div>
      </div>
    </div>
    <div (click)="handleOpenPagesDropdown()" class="wrapper-sidebar-item" [ngClass]="{'collapsed': isPagesDropdownOpen}" routerLinkActive="active">
      <div class="sidebar-item">
        <div class="sidebar-item-info">
          <i class="fa-solid fa-file"></i>
          <span>Stranice</span>
        </div>
        <div class="wrapper-arrow">
          <i class="fa-solid fa-chevron-up"></i>
        </div>
      </div>
      <div (click)="$event.stopPropagation();" class="collapse-sidebar-item">
        <div class="apiary-item" [routerLink]="[PAGES_AND_PATHS.pages.pagePath, 1]" routerLinkActive="active">
          <span>Uslovi korišćenja</span>
        </div>
        <div class="apiary-item" [routerLink]="[PAGES_AND_PATHS.pages.pagePath, 2]" routerLinkActive="active">
          <span>Politika Privatnosti</span>
        </div>
        <div class="apiary-item" [routerLink]="[PAGES_AND_PATHS.pages.pagePath, 3]" routerLinkActive="active">
          <span>O nama</span>
        </div>
      </div>
    </div>
    <div class="wrapper-sidebar-item" [routerLink]="[PAGES_AND_PATHS.faq.pagePath]" routerLinkActive="active">
      <div class="sidebar-item">
        <div class="sidebar-item-info">
          <i class="fa-solid fa-question"></i>
          <span>Česta pitanja</span>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-sidebar">
    <div class="wrapper-sidebar-item-settings" [routerLink]="[PAGES_AND_PATHS.settings.pagePath]"
         routerLinkActive="active">
      <div class="wrapper-sidebar-item">
        <div class="sidebar-item">
          <i class="fa-solid fa-gear"></i>
          <span>Podešavanja</span>
        </div>
      </div>
    </div>
    <hr>
    <div class="wrapper-profile">
      <div class="zc-d-flex zc-align-items">
        <div class="wrapper-profile-avatar">
          <img src="../assets/images/avatar.png" alt="">
        </div>
        <div class="wrapper-text">
          <span class="name">{{authHelper.getUserData.full_name}}</span>
          <span class="email">{{authHelper.getUserData.email}}</span>
        </div>
      </div>
      <div class="wrapper-logout">
        <i (click)="authHelper.clientLogout(PAGES_AND_PATHS.login.pagePath)" class="fa-solid fa-right-from-bracket"></i>
      </div>
    </div>
  </div>
</div>
