import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'gr-confirm-modal',
  templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent {
  @Input() modalTitle = '';
  @Input() modalDescription = '';
  @Input() confirmBtnText = 'POTVRDI';
  @Input() cancelBtnText = 'ZATVORI';
  @Input() hasConfirmBtn = true;
  @Input() hasCancelBtn = true;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmModal: EventEmitter<void> = new EventEmitter<void>();
}
