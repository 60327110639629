<div class="grid">
  <div class="grid__m-6">
    <form [formGroup]="settingsUserProfileForm" (ngSubmit)="onSubmitSettingsUserProfileForm()" class="grid xs-pr-32 xs-pl-32 xs-pt-32">
      <div class="grid__m-12">
        <div class="wrapper-input">
          <span class="label-input">Ime</span>
          <div class="zc-input">
            <input type="text" [formControl]="settingsUserProfileForm.controls.first_name | formControl" [ngClass]="{'not-allowed': isUserProfileFormDisabled}">
          </div>
          <span class="error-message">{{settingsUserProfileForm.controls.first_name | formControlErrorMessages: null : formUserProfileTriedToSubmit}}</span>
        </div>
      </div>
      <div class="grid__m-12">
        <div class="wrapper-input">
          <span class="label-input">Prezime</span>
          <div class="zc-input">
            <input type="text" [formControl]="settingsUserProfileForm.controls.last_name | formControl" [ngClass]="{'not-allowed': isUserProfileFormDisabled}">
          </div>
          <span class="error-message">{{settingsUserProfileForm.controls.last_name | formControlErrorMessages: null : formUserProfileTriedToSubmit}}</span>
        </div>
      </div>
      <div class="grid__m-12">
        <div class="wrapper-input">
          <span class="label-input">E-pošta</span>
          <div class="zc-input">
            <input type="email" [formControl]="settingsUserProfileForm.controls.email | formControl" [ngClass]="{'not-allowed': isUserProfileFormDisabled}">
          </div>
          <span class="error-message">{{settingsUserProfileForm.controls.email | formControlErrorMessages: 'Unesite validnu email adresu.' : formUserProfileTriedToSubmit}}</span>
        </div>
      </div>
      <div class="grid__m-12">
        <div class="wrapper-input">
          <span class="label-input">Telefon</span>
          <div class="zc-input">
            <input type="text" [formControl]="settingsUserProfileForm.controls.phone | formControl" [ngClass]="{'not-allowed': isUserProfileFormDisabled}">
          </div>
          <span class="error-message">{{settingsUserProfileForm.controls.phone | formControlErrorMessages: 'Unesite validan broj.' : formUserProfileTriedToSubmit}}</span>
        </div>
      </div>
      <div class="grid__m-12">
        <button *ngIf="isUserProfileFormDisabled" (click)="enableUserProfileForm()" type="button" class="zc-btn zc-btn--primary"><span>Izmeni podatke</span></button>
        <button *ngIf="!isUserProfileFormDisabled" type="submit" class="zc-btn zc-btn--primary"><span>Sačuvaj</span></button>
      </div>
    </form>
  </div>
</div>
