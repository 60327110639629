<div class="grid xs-pr-32 xs-pl-32">
  <div class="grid__m-12">
    <div class="wrapper-gifts-search">
      <gr-search [searchPlaceholder]="'Pretražite poklone'" (searchQueryChange)="onSearchChange($event)"></gr-search>
      <gr-dropdown
        [items]="storeFilters"
        [propIdentifier]="'id'"
        [defaultSelected]="{name: 'All', id: null}"
        (selectItem)="onFilterChange($event)"
      ></gr-dropdown>
    </div>
  </div>
  <div class="grid__m-12">
    <div class="zc-table">
      <div class="zc-header-table">
        <div class="zc-header-row">
          <div class="zc-header-column">
            <input type="checkbox" [checked]="isAllPagePresentChecked" (change)="onCheckAllPresentsChange()">
          </div>
          <div class="zc-header-column">
            <span>Akcija</span>
          </div>
          <div class="zc-header-column">
            <span>Barcode</span>
          </div>
          <div class="zc-header-column">
            <span>Šifra</span>
          </div>
          <div class="zc-header-column">
            <span>Opis</span>
          </div>
          <div class="zc-header-column">
            <span>Ime artikla</span>
            <div class="wrapper-sort" (click)="onSortChange('name')" [ngClass]="{'active': urlParams.sort === 'name'}">
              <i class="fa-solid" [ngClass]="(urlParams.sort === 'name' && urlParams.direction === 'desc') ? 'fa-arrow-up-a-z': 'fa-arrow-down-a-z'"></i>
            </div>
          </div>
          <div class="zc-header-column">
            <span>Količina</span>
            <div class="wrapper-sort" (click)="onSortChange('quantity')" [ngClass]="{'active': urlParams.sort === 'quantity'}">
              <i class="fa-solid" [ngClass]="(urlParams.sort === 'quantity' && urlParams.direction === 'desc') ? 'fa-arrow-up-a-z': 'fa-arrow-down-a-z'"></i>
            </div>
          </div>
          <div class="zc-header-column">
            <span>Nabavna cena</span>
          </div>
          <div class="zc-header-column column-buttons">
          </div>
        </div>
      </div>
      <div class="zc-content-table">
        <div class="zc-content-row" *ngFor="let present of presents">
          <div class="zc-content-column">
            <input type="checkbox" [value]="present.id" [checked]="isPresentChecked(present.id)" (change)="onCheckPresent(present.id)">
          </div>
          <div class="zc-content-column">
            <span>{{present.discount ? present.discount.discount_percentage + '%' : '/'}}</span>
          </div>
          <div class="zc-content-column">
            <span>{{present.barcode}}</span>
          </div>
          <div class="zc-content-column">
            <span>{{present.product_code}}</span>
          </div>
          <div class="zc-content-column">
            <span [innerHTML]="present.description"></span>
          </div>
          <div class="zc-content-column">
            <span>{{present.name}}</span>
          </div>
          <div class="zc-content-column">
            <span>{{present.quantity}}</span>
          </div>
          <div class="zc-content-column">
            <span>{{present.wholesale_price}} RSD</span>
          </div>
          <div class="zc-content-column column-buttons">
            <div class="buttons-group">
              <div class="wrapper-button" [routerLink]="[PAGES_AND_PATHS.singlePresent.pagePath, present.id]">
                <i class="fa-solid fa-eye"></i>
              </div>
              <div class="wrapper-button" [routerLink]="[PAGES_AND_PATHS.editPresent.pagePath, present.id]">
                <i class="fa-solid fa-pen-to-square"></i>
              </div>
              <div class="wrapper-button" (click)="presentForDelete = present">
                <i class="fa-solid fa-trash"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="grid">
  <div class="grid__m-12">
    <gr-pagination
      *ngIf="presents.length"
      [currentPage]="presentsPagination?.currentPage"
      [pagId]="'js-presents-table-pagination'"
      [perPage]="presentsPagination?.perPage"
      [totalItems]="presentsPagination?.totalItems"
      (pageChanged)="onPageChanged($event)">
    </gr-pagination>
  </div>
</div>

<gr-confirm-modal
  *ngIf="presentForDelete !== null"
  [modalTitle]="'Brisanje poklona'"
  [modalDescription]="'Da li ste sigurni da zelite da obrišete poklon?'"
  (closeModal)="presentForDelete = null"
  (confirmModal)="onConfirmDeletePresent()"
></gr-confirm-modal>


