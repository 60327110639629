export const PAGES_AND_PATHS = {
  login: {
    pageName: 'LOGIN',
    pageInRouting: 'login',
    pagePath: '/login',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: false,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: false,
    pagePathWithoutParams: ['/login']
  },
  forgotPassword: {
    pageName: 'FORGOT_PASSWORD',
    pageInRouting: 'forgot-password',
    pagePath: '/forgot-password',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: false,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: false,
    pagePathWithoutParams: ['/forgot-password']
  },
  resetPassword: {
    pageName: 'RESET_PASSWORD',
    pageInRouting: 'reset-password/admin/:token',
    pagePath: '/reset-password/admin',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: false,
    isChild: false,
    parent: null,
    pageHaveParams: true,
    needAuthentication: false,
    pagePathWithoutParams: ['/reset-password/admin']
  },
  settings: {
    pageName: 'SETTINGS',
    pageInRouting: 'settings',
    pagePath: '/settings',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/settings']
  },
  dashboard: {
    pageName: 'DASHBOARD',
    pageInRouting: 'dashboard',
    pagePath: '/dashboard',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/dashboard']
  },
  adminList: {
    pageName: 'ADMIN_LIST',
    pageInRouting: 'admin-list',
    pagePath: '/admin-list',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/admin-list']
  },
  userList: {
    pageName: 'USER_LIST',
    pageInRouting: 'user-list',
    pagePath: '/user-list',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/user-list']
  },
  businessList: {
    pageName: 'BUSINESS_LIST',
    pageInRouting: 'business-list/:categoryId',
    pagePath: '/business-list',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/business-list']
  },
  businessProfile: {
    pageName: 'BUSINESS_PROFILE',
    pageInRouting: 'business-profile/:id',
    pagePath: '/business-profile',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/business-profile']
  },
  priceListCategory: {
    pageName: 'PRICE_LIST_CATEGORY',
    pageInRouting: 'business-profile/:id/price-list-category/:categoryId',
    pagePath: '/business-profile/:id/price-list-category',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/business-profile/:id/price-list-category']
  },
  priceListCategoryItemCreate: {
    pageName: 'PRICE_LIST_CATEGORY_ITEM_CREATE',
    pageInRouting: 'business-profile/:id/price-list-category/:categoryId/item-create',
    pagePath: '/business-profile/:id/price-list-category/:categoryId/item-create',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/business-profile/:id/price-list-category/:categoryId/item-create']
  },
  priceListCategoryItemEdit: {
    pageName: 'PRICE_LIST_CATEGORY_ITEM_EDIT',
    pageInRouting: 'business-profile/:id/price-list-category/:categoryId/item/:itemId',
    pagePath: '/business-profile/:id/price-list-category/:categoryId/item',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/business-profile/:id/price-list-category/:categoryId/item']
  },
  priceList: {
    pageName: 'PRICE_LIST',
    pageInRouting: 'price-list',
    pagePath: '/price-list',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/price-list']
  },
  presents: {
    pageName: 'PRESENTS',
    pageInRouting: 'presents',
    pagePath: '/presents',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/presents']
  },
  singlePresent: {
    pageName: 'SINGLE_PRESENT',
    pageInRouting: 'presents/:id',
    pagePath: '/presents',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/presents']
  },
  createPresent: {
    pageName: 'CREATE_PRESENT',
    pageInRouting: 'presents/create',
    pagePath: '/presents/create',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/presents/create']
  },
  editPresent: {
    pageName: 'EDIT_PRESENT',
    pageInRouting: 'presents/edit/:id',
    pagePath: '/presents/edit',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/presents/edit']
  },
  reservations: {
    pageName: 'RESERVATIONS',
    pageInRouting: 'reservations',
    pagePath: '/reservations',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/reservations']
  },
  invitations: {
    pageName: 'INVITATIONS',
    pageInRouting: 'invitations',
    pagePath: '/invitations',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/invitations']
  },
  notifications: {
    pageName: 'NOTIFICATIONS',
    pageInRouting: 'notifications',
    pagePath: '/notifications',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/notifications']
  },
  banners: {
    pageName: 'BANNERS',
    pageInRouting: 'banners',
    pagePath: '/banners',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/banners']
  },
  bannerCreate: {
    pageName: 'BANNERS',
    pageInRouting: 'banners/create',
    pagePath: '/banners/create',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/banners/create']
  },
  bannerEdit: {
    pageName: 'BANNERS',
    pageInRouting: 'banners/:id',
    pagePath: '/banners',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/banners']
  },
  pages: {
    pageName: 'PAGES',
    pageInRouting: 'pages/:id',
    pagePath: '/pages',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/pages']
  },
  faq: {
    pageName: 'FAQ',
    pageInRouting: 'faq',
    pagePath: '/faq',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/faq']
  },
  userProfile: {
    pageName: 'USER_PROFILE',
    pageInRouting: 'user-profile',
    pagePath: '/user-profile',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showSideMenu: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/user-profile']
  },
};

export const DEFAULT_AUTHENTICATED_PAGE = {
  page: PAGES_AND_PATHS.dashboard
};
