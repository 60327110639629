import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PagesHttpService} from '../../services/http-services/pages/pages-http.service';
import {GetPageApiData, PutPageApiData} from '../../services/http-services/pages/pages-http-interfaces';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'gr-pages',
  templateUrl: './pages.component.html',
})
export class PagesComponent implements OnInit {
  pageId = '';
  updatePageForm!: FormGroup;
  formTriedToSubmit = false;
  isFormDisabled = true;
  isPageCreated = false;

  constructor(private formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private pagesHttpService: PagesHttpService,
              private toastrService: ToastrService) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.activatedRoute.params.subscribe((res: any) => {
      this.pageId = res.id;
      this.getSinglePage()
    });
  }

  getSinglePage(): void {
    this.pagesHttpService.getSinglePage({pageId: this.pageId}).then((res: GetPageApiData) => {
      this.isPageCreated = res.data.created_at !== res.data.updated_at;
      this.disabledForm();
      this.updatePageForm.patchValue({...res.data});
    });
  }

  buildForm(): void {
    this.updatePageForm = this.formBuilder.group({
      name: ['', Validators.required],
      content: ['', Validators.required]
    });
  }

  enableForm(): void {
    this.isFormDisabled = false
    this.updatePageForm.enable()
  }
  disabledForm(): void {
    this.isFormDisabled = true;
    this.updatePageForm.disable()
  }

  submitUpdatePageForm(): void {
    this.formTriedToSubmit = true;
    if (this.updatePageForm.valid) {
      this.pagesHttpService.putPage({pageId: this.pageId}, this.updatePageForm.value).then((res: PutPageApiData) => {
        this.disabledForm();
        this.toastrService.success(this.isPageCreated ? 'Uspešno ste izmenili stanicu.' : 'Uspešno ste kreirali stanicu.');
        this.isPageCreated = true;
        this.formTriedToSubmit = false;
      });
    }
  }
}
