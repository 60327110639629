import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {GeneralHttpHelperService} from '../../helpers/general-http-helper.service';
import {HttpClient} from '@angular/common/http';

const INVITATIONS_API_ROUTES = {
  GET_INVITATIONS: 'admin/invitations',
  PUT_INVITATION: 'admin/invitations/:invitationId',
};
@Injectable({
  providedIn: 'root'
})
export class InvitationsHttpService {

  constructor(private generalHttpHelperService: GeneralHttpHelperService,
              private httpClient: HttpClient) { }

  private getInvitationsReq(): Observable<any> {
    return this.httpClient.get(INVITATIONS_API_ROUTES.GET_INVITATIONS) as any;
  }

  getInvitations(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getInvitationsReq().subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private putInvitationReq(urlParams: any, bodyParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(INVITATIONS_API_ROUTES.PUT_INVITATION, urlParams);
    return this.httpClient.post(apiUrl, bodyParams) as any;
  }

  putInvitation(urlParams: any, bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.putInvitationReq(urlParams, bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
}
