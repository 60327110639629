import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';
import {OuterPagesGuardService} from '../../guards/outer-pages-guard.service';
import {ResetPasswordComponent} from './reset-password.component';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.resetPassword.pageInRouting,
    data: {data: PAGES_AND_PATHS.resetPassword},
    component: ResetPasswordComponent,
    canActivate: [OuterPagesGuardService],
    loadChildren: () => import('./reset-password.module').then(m => m.ResetPasswordModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ResetPasswordRoutingModule { }
