<div class="wrapper-modal">
  <form [formGroup]="addPresentsActionForm" (ngSubmit)="onSubmitPresentsActionForm()" class="wrapper-content-modal">
    <div class="header-modal">
      <span class="title-modal">Dodajte akciju</span>
    </div>
    <div class="content-modal">
      <div class="xs-mb-20">
        <div class="label-banner">
          <span>Početak akcije</span>
        </div>
        <gr-date-picker [defaultDate]="addPresentsActionForm.controls.date_from.value" (dateSelected)="onSelectedDate($event, true)"></gr-date-picker>
        <span class="error-message">{{addPresentsActionForm.controls.date_from | formControlErrorMessages: null : formTriedToSubmit}}</span>
      </div>
      <div class="xs-mb-20">
        <div class="label-banner">
          <span>Kraj akcije</span>
        </div>
        <gr-date-picker [defaultDate]="addPresentsActionForm.controls.date_to.value" (dateSelected)="onSelectedDate($event, false)"></gr-date-picker>
        <span class="error-message">{{addPresentsActionForm.controls.date_to | formControlErrorMessages: null : formTriedToSubmit}}</span>
      </div>
      <div class="wrapper-input">
        <span class="label-input">Procenat sniženja</span>
        <div class="zc-input">
          <input type="text" formControlName="discount_percentage">
        </div>
        <span class="error-message">{{addPresentsActionForm.controls.discount_percentage | formControlErrorMessages: null : formTriedToSubmit}}</span>
      </div>
    </div>
    <div class="footer-modal">
      <button type="button" class="zc-btn zc-btn--gray" (click)="closeModal.emit()"><span>Izađi</span></button>
      <button type="submit" class="zc-btn zc-btn--primary"><span>Dodaj</span></button>
    </div>
  </form>
</div>
